import React, { useRef, useState } from "react";

import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";

import { MakoActionsButtonsColumn } from "@/components/MakoActionsButtonsColumn";
import { MakoActionsButtons } from "@/components/MakoActionsButtons";
import { BotaoDelete } from "@/components/BotaoDelete";
import MakoListagem from "@/components/MakoListagem";

import { ModalNovoServico } from "./modalNovoServico";
import { DadosCabecalho } from "./dadosCabecalho";

import { MAKO_ICONS } from "@/assets/constants/constants_styles";

import useContratoServico from "@/hooks/useContratoServico";
import useClearRefs from "@/hooks/useClearRefs";
import { ModalServicoCabecalho } from "./modalServicoCabecalho";

const FormServicos = () => {
    const initialModal = {
        visible: false,
        title: "Novo serviço",
        content: null,
    };

    const [modal, setModal] = useState(initialModal);
    const { contrato } = useContratoServico();

    const menuAcoesRef = useRef(null);
    const listagemRef = useRef(null);

    useClearRefs(menuAcoesRef, listagemRef);

    const fecharModal = () => {
        setModal((prev) => ({ ...prev, visible: false }));
    };

    const actionBodyTemplate = (rowData) => {
        return (
            <MakoActionsButtonsColumn>
                <Button
                    icon={MAKO_ICONS.EDITAR}
                    className="p-button-rounded p-button-warning"
                    disabled={contrato?.status === "F" || !!rowData.servico_municipio?.servico?.apontamento}
                    onClick={() => {
                        setModal({
                            visible: true,
                            title: "Editar serviço",
                            content: (
                                <ModalServicoCabecalho
                                    onClose={fecharModal}
                                    novoServico
                                    onFinish={() => onFinish()}
                                    servico={rowData}
                                />
                            ),
                        });
                    }}
                />
                <BotaoDelete
                    url="/servicos/servicos-contrato/"
                    objetoId={rowData.id}
                    exigeConfirmacao
                    msgConfirmacao={<span>Deseja realmente este vinculo de serviço?</span>}
                    msgToastErroExclusao="O vinculo não pode ser exclúido"
                    onDelete={onDelete}
                    disabled={contrato?.status === "F" || !!rowData.servico_municipio?.servico?.apontamento}
                />
            </MakoActionsButtonsColumn>
        );
    };

    const colunas = [
        { field: "servico.codigo", header: "Código", style: { width: "12%" } },
        { field: "servico.descricao", header: "Descrição do serviço" },
        {
            field: "executor.nome",
            header: "Executor",
        },
        {
            field: "data_encerramento",
            header: "Data final",
            dateFormat: "dd/MM/yyy",
            style: { width: "10%" },
        },
        {
            field: "preco",
            header: "Preço",
            money: true,
        },
        {
            field: "action",
            header: "Ações",
            style: { width: "10%" },
            align: "center",
            action: (e) => actionBodyTemplate(e),
        },
    ];

    const onFinish = () => {
        fecharModal();
        listagemRef.current?.buscarDados();
    };

    const onDelete = () => {
        listagemRef.current?.buscarDados();
    };

    return (
        <div>
            <DadosCabecalho />
            <MakoActionsButtons className="p-mt-4">
                <Button
                    icon={MAKO_ICONS.NOVO}
                    type="button"
                    className="p-button-success"
                    label="Novo serviço"
                    onClick={() => {
                        setModal({
                            visible: true,
                            title: "Novo Serviço",
                            content: <ModalNovoServico onClose={fecharModal} onFinish={() => onFinish()} />,
                        });
                    }}
                />
                <Button
                    type="button"
                    className="p-button-success"
                    label="Adicionar serviço"
                    onClick={() => {
                        setModal({
                            visible: true,
                            title: "Incluindo/Editando Serviço do Contrato",
                            content: (
                                <ModalServicoCabecalho onClose={fecharModal} novoServico onFinish={() => onFinish()} />
                            ),
                        });
                    }}
                />
            </MakoActionsButtons>
            <div className="p-my-4">
                <MakoListagem
                    ref={listagemRef}
                    colunas={colunas}
                    urlPesquisa={`/servicos/servicos-contrato/?contrato=${contrato?.id}`}
                    configTabela={{
                        paginator: true,
                        lazy: true,
                    }}
                />
            </div>
            <Dialog
                visible={modal.visible}
                header={modal.title}
                breakpoints={{ "960px": "90vw" }}
                style={{ width: "75vw", display: "block" }}
                onHide={() => setModal((prev) => ({ ...prev, visible: false }))}
            >
                {modal.content}
            </Dialog>
        </div>
    );
};

export default React.memo(FormServicos);
