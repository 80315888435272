import { useRef } from "react";

import { MakoButton as Button } from "@/components/MakoButton";
import { Menu } from "primereact/menu";

export const ButtonMenuPopup = ({ menu, onClick: onClickButton, ...props }) => {
    const menuRef = useRef(null);

    return (
        <>
            <Menu {...menu} ref={menuRef} popup id={props["aria-controls"]} />
            <Button
                {...props}
                aria-haspopup
                onClick={(e) => {
                    if (onClickButton) onClickButton(e);
                    menuRef.current?.toggle(e);
                }}
            />
        </>
    );
};
