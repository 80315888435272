import React, { forwardRef, useCallback, useImperativeHandle, useState } from "react";

import { Dialog } from "primereact/dialog";

import { Forms } from "../../../features/Pendencias/forms";

const { ReceberCartaoModo: FormReceberCartaoModo } = Forms;

const Componente = ({ onHide = () => {} }, ref) => {
    const [visible, setVisible] = useState(false);
    const [pendencia, setPendencia] = useState(null);

    const show = useCallback(({ pendencia }) => {
        setPendencia(pendencia);
        setVisible(true);
    }, []);

    const hide = useCallback(
        (handleHide = true) => {
            setVisible(false);
            if (handleHide) onHide();
        },
        [onHide]
    );

    const onCancel = useCallback(() => {
        hide(false);
    }, [hide]);

    useImperativeHandle(ref, () => ({ show, hide }), [show, hide]);

    return (
        <Dialog
            header="Recebendo cartão"
            visible={visible}
            onHide={hide}
            style={{ width: "30vw" }}
            breakpoints={{ "960px": "70vw" }}
        >
            <FormReceberCartaoModo pendencia={pendencia} successCallback={hide} cancelCallback={onCancel} />
        </Dialog>
    );
};

export const ReceberCartao = forwardRef(Componente);
