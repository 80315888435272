import { Tag } from "primereact/tag";
import { parseNumber } from "../helpers/number";
import { dataToStr } from "./datas";
import { Editor } from "primereact/editor";

export function gerarId() {
    let id = "";
    let chars = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";

    for (let i = 0; i < 5; i++) {
        id += chars.charAt(Math.floor(Math.random() * chars.length));
    }

    return id;
}

export const parseMoeda = (number, exibeSimbolo = true) => {
    let value = parseNumber(number);
    const style = { currency: "BRL", minimumFractionDigits: 2, maximumFractionDigits: 2 };
    if (exibeSimbolo) {
        return new Intl.NumberFormat("pt-BR", { style: "currency", ...style }).format(value);
    }
    return new Intl.NumberFormat("pt-BR", style).format(value);
};

export const parseNumberToMoneyHTML = (number, exibeSimbolo = true) => {
    const value = parseMoeda(number, exibeSimbolo);
    if (value >= 0) return <span>{value}</span>;
    return <span className="mako-negative-value">{value}</span>;
};

export const fmtCasasDecimais = (value, casasDecimais = 2) => {
    const num = parseNumber(value);
    return num.toFixed(casasDecimais).replace(".", ",");
};

export const formatarCasasDecimais = (value, casasDecimais = 2, utilizarVirgula = false) => {
    const num = parseNumber(value);

    let localStringProps = {};
    if (typeof utilizarVirgula === "object") localStringProps = utilizarVirgula;

    return !utilizarVirgula
        ? parseNumber(num.toFixed(casasDecimais))
        : parseNumber(num.toFixed(casasDecimais)).toLocaleString("pt-BR", localStringProps);
};

export const formatarPercentual = (value) => {
    // const numero = formatarCasasDecimais(value);

    return `${value} %`;
};

/**
 * @deprecated Use criarListaProvisoriaParaPersistencia() em vez disso
 */
export const gerenciarOperacoesCrud = (lista, obj, op) => {
    switch (op) {
        case "novo":
            const id = gerarId();
            lista.push({ ...obj, localId: id, valueId: id, statusObj: "novo" });

            break;

        case "editar":
            if (lista.length > 0) {
                const keyId = obj.id ? "id" : "localId";
                const index = lista?.findIndex((e) => e[keyId] === obj[keyId]);

                lista[index] = {
                    ...obj,
                    statusObj: obj.id ? "editar" : "novo",
                };
            }

            break;

        case "deletar":
            if (obj.id) {
                if (lista.length > 0) {
                    const index = lista?.findIndex((e) => e.id === obj.id);

                    // if (obj.statusObj === "deletar") {
                    //     obj.statusObj = "editar";
                    //     lista[index] = obj;
                    // } else {
                    // }
                    obj.statusObj = "deletar";
                    lista[index] = obj;
                }
            } else if (obj.localId) {
                if (lista.length > 0) {
                    const index = lista?.findIndex((e) => e.localId === obj.localId);
                    lista.splice(index, 1);
                }
            }

            break;

        case "desativar":
            if (obj.id) {
                if (lista.length > 0) {
                    const index = lista?.findIndex((e) => e.id === obj.id);

                    obj.ativo = !obj.ativo;
                    obj.status = "editar";
                    lista[index] = obj;
                }
            }

            break;

        default:
            break;
    }

    return lista;
};

/**
 * @deprecated Use montarObjetoParaPersistenciaDjango() em vez disso
 */
export const montarCrudParaPersitencia = (itens) => {
    let crud = {};

    itens.forEach((item) => {
        const { statusObj = "editar" } = item;

        if (statusObj === "novo") {
            if (crud.create) {
                crud.create.push(item);
            } else {
                crud = {
                    ...crud,
                    create: [item],
                };
            }
        } else if (statusObj === "editar") {
            if (crud.update) {
                crud = {
                    ...crud,
                    update: {
                        ...crud.update,
                        [item.id]: item,
                    },
                };
            } else {
                crud = {
                    ...crud,
                    update: {
                        [item.id]: item,
                    },
                };
            }
        } else if (statusObj === "deletar") {
            if (crud.remove) {
                crud.remove.push(item.id);
            } else {
                crud = {
                    ...crud,
                    remove: [item.id],
                };
            }
        }
    });

    return crud;
};

export const maxDiasEmUmMes = (mes, ano) => {
    try {
        mes = parseInt(mes);
        ano = parseInt(ano);

        if (mes === 1 || mes === 3 || mes === 5 || mes === 7 || mes === 8 || mes === 10 || mes === 12) {
            return 31;
        } else if (mes === 2) {
            if ((ano % 4 === 0 && ano % 100 !== 0) || ano % 400 === 0) {
                return 29;
            }

            return 28;
        }

        return 30;
    } catch (error) {
        return 0;
    }
};

export const objetoEstaVazio = (obj) => {
    if (!obj) return true;
    return Object.keys(obj).length === 0;
};

export const validarIntervaloParcelas = (intervaloParcelas, numeroParcelas) => {
    if (intervaloParcelas.split(",").length === numeroParcelas) {
        return true;
    }

    return false;
};

export const validarProgressaoIntervaloParcelas = (intervaloParcelas) => {
    if (!intervaloParcelas) return false;

    const intervalo = intervaloParcelas.split(",");

    for (let x = 0; x < intervalo.length; x++) {
        if (x < intervalo.length) {
            if (parseInt(intervalo[x]) > parseInt(intervalo[x + 1])) {
                return false;
            }
        }
    }

    return true;
};

export const gerarStringAleatoria = (length, apenasNumero = true) => {
    const chars = !apenasNumero ? "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789" : "0123456789";
    const charLength = chars.length;
    let result = "";
    for (let i = 0; i < length; i++) {
        result += chars.charAt(Math.floor(Math.random() * charLength));
    }
    return result;
};

export const limparDocumento = (documento) => {
    if (!documento) return "";
    return documento.replace(/[^\d]+/g, "");
};

export const formatDocumento = (documento) => {
    documento = limparDocumento(documento);
    if (documento.length && documento.length === 11) {
        return documento.replace(/^(\d{3})(\d{3})(\d{3})(\d{2})/, "$1.$2.$3-$4");
    }
    if (documento.length && documento.length === 14) {
        return documento.replace(/^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, "$1.$2.$3/$4-$5");
    }
    return documento;
};

export const getObjetoValue = (data = {}, path, separator = ".") => {
    const [nextProp, ...rest] = path.split(separator);
    if (rest.length) return getObjetoValue(data[nextProp], rest.join(separator), separator);
    return data[nextProp];
};

export const toCapitalize = (value = "", spliter = " ") => {
    let arr = value.split(spliter);
    arr = arr
        .map((palavra) => {
            palavra = `${palavra[0].toUpperCase()}${palavra
                .slice(palavra.length >= 1 ? 1 : 0, palavra.length)
                .toLowerCase()}`;
            return palavra;
        })
        .join(spliter);
    return arr;
};

export const totalizadorFiltrosAplicados = (defaultValues, values) => {
    let aplicados = 0;
    Object.keys(values).forEach((key) => {
        if (defaultValues[key] !== values[key]) aplicados++;
    });
    return aplicados;
};

export const gerarFileName = (name = "") => {
    return `sysmako_${name.replace(/\s/g, "_")}_${dataToStr(new Date(), "dd-MM-yyyyHH:mm:ss")
        .replace(/-/gi, "")
        .replace(/:/gi, "")}`;
};

export const gerarFiltrosUrl = (filtros = {}) => {
    if (typeof filtros !== "object") return "";
    let _filtros = [];
    for (const [k, v] of Object.entries(filtros)) {
        if (v !== null || undefined) _filtros.push(`${k}=${v}`);
    }
    return _filtros.join("&");
};

export const gerarStatusBooleanTemplate = (status, labelTrue = "SIM", labelFalse = "NÂO") => {
    if (status) return <Tag severity="success" value={labelTrue} />;
    return <Tag severity="danger" value={labelFalse} />;
};

export const formatarEndereco = (option, simplificado = false) => {
    if (simplificado) {
        const { bairro, logradouro, numero, cidade, estado } = option;
        return `${logradouro}, ${numero} - ${bairro} - ${cidade.nome} - ${estado.uf}`;
    }
    return `${option.logradouro}, ${option.numero}, ${option.complemento} - ${option.bairro}, ${option.cep}`;
};

export const gerarClausulaTemplate = ({ texto_clausula, nome, styleEditor = {}, showHeader = true }) => {
    return (
        <>
            {showHeader && <b>{nome}</b>}
            <Editor value={texto_clausula} style={{ height: "50px", ...styleEditor }} showHeader={false} readOnly />
        </>
    );
};
