import React, { useRef, useState } from "react";
import { Button } from "primereact/button";
import { MakoListagem } from "@/components/MakoListagem";
import { MakoControleAcesso } from "@/components/MakoControleAcesso";
import permissoes from "@/assets/constants/permissoes";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import useToast from "@/hooks/useToast";
import useEmpresa from "@/hooks/useEmpresa";
import useHttp from "@/hooks/useHttp";
import { Dialog } from "primereact/dialog";
import useContratoServico from "@/hooks/useContratoServico";

export const FormFiscal = () => {
    const [servicosSelecionados, setServicosSelecionados] = useState([]);
    const [exibirServicos, setExibirServicos] = useState(false);
    const { contrato } = useContratoServico();
    const { showSuccess, showError } = useToast();
    const { empresaSelecionadaId } = useEmpresa();
    const { httpPost } = useHttp();
    const listagemRef = useRef();
    const history = useHistory();

    async function handleEnviar() {
        try {
            const handlers = {
                200: () => {
                    listagemRef.current?.buscarDados();
                    showSuccess({
                        summary: "Sucesso!",
                        detail: "Nota fiscal incluida com sucesso! Não se esqueça de verificar as informações da nota gerada.",
                        life: 3000,
                    });
                },
                400: ({ err }) => {
                    const { erro } = err;
                    const key = Object.keys(erro);
                    const msg = Object.values(erro).map((e, index) => `${e} (${key[index]})`);
                    showError({
                        summary: "Falha ao inserir",
                        detail: `Não foi possível inserir a nota fiscal de serviços. Motivos: ${
                            typeof erro === "object" ? msg.join("; ") : erro
                        }`,
                        life: 9000,
                    });
                },
                409: ({ err }) => {
                    const { erro } = err;
                    const key = Object.keys(erro);
                    const msg = Object.values(erro).map((e, index) => `${e} (${key[index]})`);
                    showError({
                        summary: "Falha ao inserir",
                        detail: `Não foi possível inserir a nota fiscal de serviços. Motivos: ${
                            typeof erro === "object" ? msg.join("; ") : erro
                        }`,
                        life: 9000,
                    });
                },
            };

            await httpPost(
                {
                    url: `/servicos/gerar-documento-fiscal/${empresaSelecionadaId}/${contrato.id}/`,
                    body: {
                        servicos: servicosSelecionados.map(({ id }) => id),
                    },
                },
                handlers
            );
        } catch (erro) {
            showError();
        }
    }

    const actionBodyTemplate = (rowData) => {
        return (
            <div className="actions">
                <MakoControleAcesso
                    permissao={[permissoes.FISCAL_NOTASERVICO_EDITAR]}
                    componente={Button}
                    icon="pi pi-pencil"
                    className="p-button-rounded p-button-warning p-mr-2 p-mb-1"
                    tooltip="Alterar cadastro de nota de serviço"
                    tooltipOptions={{ position: "left" }}
                    onClick={() =>
                        history.push({
                            pathname: "/fiscal/nota-servico/incluir-nota",
                            notaServico: rowData.id,
                        })
                    }
                />
            </div>
        );
    };

    const verificaEmissao = (chave) => {
        if (chave) return chave;
        else return "NÃO TRANSMITIDO";
    };

    const verificaProtocolo = (protocolo) => {
        if (protocolo) return protocolo;
        else return "-";
    };

    const colunas = [
        {
            field: "numero",
            header: "Número",
            filter: true,
            style: { minWidth: "50px" },
        },
        {
            field: "serie",
            header: "Série",
            filter: true,
            style: { minWidth: "50px" },
        },
        {
            field: "modelo",
            header: "Modelo",
            filter: true,
            style: { minWidth: "50px" },
        },
        {
            field: "chave_nf",
            header: "Chave",
            action: (e) => verificaEmissao(e.chave_nf),
            filter: true,
            align: "left",
            style: { minWidth: "400px" },
        },
        {
            field: "protocolo",
            header: "Protocolo",
            action: (e) => verificaProtocolo(e.protocolo),
            filter: true,
            style: { minWidth: "200px" },
        },
        {
            field: "valor_total_nf",
            header: "Total Nf",
            filter: true,
            align: "right",
            style: { minWidth: "120px" },
        },
        {
            field: "action",
            header: "Ações",
            action: (e) => actionBodyTemplate(e),
            style: { width: "15%" },
        },
    ];

    const painelEsquerdo = (
        <MakoControleAcesso
            permissao={[permissoes.FISCAL_NOTASERVICO_INCLUIR]}
            componente={Button}
            type="button"
            label="Gerar Documento Fiscal"
            onClick={() => setExibirServicos(true)}
            className="p-mr-2"
        />
    );

    const rowClassNF = (rowData) => {
        return {
            "mako-table-status-inativo": rowData.status === "C",
            "table-recebimentos-effective": rowData.status === "T",
        };
    };

    const fecharDialog = () => {
        setExibirServicos(false);
    };

    const colunasServicos = [
        { selectionMode: "multiple", headerStyle: { width: "3em" } },
        { field: "servico_municipio.codigo", header: "Código", style: { minWidth: "80px" } },
        { field: "servico_municipio.descricao", header: "Descrição", style: { minWidth: "250px" } },
        {
            field: "operacao_fiscal.descricao",
            header: "Operação Fiscal",
            style: { minWidth: "300px" },
        },
    ];

    return (
        <>
            <MakoListagem
                ref={listagemRef}
                painelEsquerdo={painelEsquerdo}
                titulo="Documentos fiscais de serviço"
                urlPesquisa={`/fiscal/notas-fiscais?evento_id=${contrato.id}&evento_content_type__model=contratoservico`}
                colunas={colunas}
                configTabela={{
                    lazy: true,
                    paginator: true,
                    rowClassName: rowClassNF,
                }}
            />
            <Dialog
                header={"Selecione os serviços para emissão do documento fiscal:"}
                visible={exibirServicos}
                breakpoints={{ "960px": "75vw" }}
                style={{ width: "70vw", display: "block" }}
                onHide={() => fecharDialog(false)}
            >
                <MakoListagem
                    colunas={colunasServicos}
                    urlPesquisa={`/servicos/servicos-contrato/?query={id,executor,servico_municipio,operacao_fiscal}&contrato=${contrato?.id}`}
                    configTabela={{
                        editMode: "cell",
                        selectionMode: "multiple",
                        selection: servicosSelecionados,
                        onSelectionChange: (e) => setServicosSelecionados(e.value),
                    }}
                />
                <Button
                    type="button"
                    icon="pi pi-plus"
                    label="Gerar nota"
                    className="p-mt-4"
                    disabled={!servicosSelecionados}
                    onClick={() => handleEnviar()}
                />
            </Dialog>
        </>
    );
};
