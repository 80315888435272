import { MAKO_ICONS } from "./constants_styles";

export const BUTTON_LISTAGEM = {
    type: "button",
    rounded: true,
};

export const BUTTON_LISTAGEM_EDITAR = {
    ...BUTTON_LISTAGEM,
    icon: MAKO_ICONS.EDITAR,
    severity: "warning",
    tooltip: "Editar",
    tooltipOptions: { position: "top" },
};

export const BUTTON_LISTAGEM_OPCOES = {
    ...BUTTON_LISTAGEM,
    icon: MAKO_ICONS.OPCOES,
    severity: "info",
    tooltip: "Opções",
    tooltipOptions: { position: "top" },
};
