import React, { useCallback, useRef } from "react";

import { MakoControleAcesso } from "@/components/MakoControleAcesso";
import { MakoButton as Button } from "@/components/MakoButton";

import { MakoActionsButtonsColumn } from "@/components/MakoActionsButtonsColumn";
import { BotaoDelete } from "@/components/BotaoDelete";
import { MAKO_ICONS } from "@/assets/constants/constants_styles";
import { PageBase } from "@/components/PageBase";

import { Listagem } from "./features";

import useClearRefs from "@/hooks/useClearRefs";
import useToast from "@/hooks/useToast";

import permissoes from "@/assets/constants/permissoes";

const BASE_URL = "/financeiro/administradora-cartao/";

export const AdministradoraCartaoPage = () => {
    const { showSuccess } = useToast();
    const listagemRef = useRef(null);

    useClearRefs(listagemRef);

    const actions = useCallback(
        (rowData) => {
            return (
                <MakoActionsButtonsColumn>
                    <MakoControleAcesso
                        permissao={[permissoes.FINANCEIRO_CADASTRO_ADMINISTRADORACARTAO_EDITAR]}
                        componente={Button}
                        icon={MAKO_ICONS.EDITAR}
                        className="p-button-rounded p-button-warning"
                        tooltip="Editar"
                        tooltipOptions={{ position: "left" }}
                        to={{
                            pathname: "/financeiro/cadastros/administradora-cartao/form",
                            state: { administradora: rowData },
                        }}
                    />
                    <MakoControleAcesso
                        permissao={[permissoes.FINANCEIRO_CADASTRO_ADMINISTRADORACARTAO_EXCLUIR]}
                        componente={BotaoDelete}
                        url={BASE_URL}
                        objetoId={rowData.id}
                        msgToastErroExclusao="A administradora não pode ser excluída."
                        tooltip="Deletar administradora"
                        exigeConfirmacao
                        msgConfirmacao={`Deseja realmente excluir a administradora ${rowData.nome}?`}
                        tooltipOptions={{ position: "left" }}
                        onDelete={() => {
                            showSuccess({
                                summary: "Sucesso",
                                detail: "Administradora deletada com sucesso!",
                                life: 3000,
                            });
                            listagemRef.current?.buscarDados();
                        }}
                    />
                </MakoActionsButtonsColumn>
            );
        },
        [showSuccess]
    );

    const painelEsquerdo = (
        <>
            <MakoControleAcesso
                permissao={[permissoes.FINANCEIRO_CADASTRO_ADMINISTRADORACARTAO_INCLUIR]}
                componente={Button}
                label="Novo"
                icon={MAKO_ICONS.NOVO}
                className="p-button-success"
                to={"/financeiro/cadastros/administradora-cartao/form"}
            />
        </>
    );

    return (
        <PageBase>
            <Listagem
                listagemRef={listagemRef}
                actions={actions}
                listagemProps={{
                    painelEsquerdo,
                }}
            />
        </PageBase>
    );
};
