import React, { useCallback, useRef, useState } from "react";

import * as Yup from "yup";

import { COMPONENTES_ESPECIAIS, MakoFormGerador } from "@/components/MakoFormGerador";
import { MakoDropdownEstoques, MakoInputPeriodo } from "@/components/MakoInputs";
import { MakoBaseRelatorio as R } from "@/components/MakoBaseRelatorio";
import { MakoDropdownEmpresas } from "@/components/MakoDropdownEmpresas";
import MakoListagem from "@/components/MakoListagem";

import useRelatorio from "@/hooks/useRelatorio";
import useClearRefs from "@/hooks/useClearRefs";

import { TIPOS_FILTROS_TEMPLATE, gerarFiltroTemplate, montarFiltroTemplate } from "@/assets/util/relatorios";
import { RELATORIOS_PRODUTOS_LOTES } from "@/assets/constants/relatorios";
import { TIPO_CHOICE_LOTE_SERIAL } from "@/assets/constants/constants";
import { gerarFileName } from "@/assets/util/util";
import { dataToStr } from "@/assets/util/datas";
import { Dropdown } from "@/components/Dropdown";

const { ExportCSV } = R.Buttons;

const BASE_URL = "/relatorios/lotes/";

const FILE_NAME = gerarFileName("Lotes");

const FILTROS_VIEWSET = {
    saldolotesku__centro_estocagem__estoque_empresa__empresa:
        "saldolotesku__centro_estocagem__estoque_empresa__empresa",
    saldolotesku__centro_estocagem: "saldolotesku__centro_estocagem",
    bloqueado_vender: "bloqueado_vender",
    sku__lote_serial: "sku__lote_serial",
    fabricacao__lte: "fabricacao__lte",
    fabricacao__gte: "fabricacao__gte",
    vencimento__lte: "vencimento__lte",
    vencimento__gte: "vencimento__gte",
};

const OPTIONS_BLOQUEADO_VENDA = [
    { label: "Sim", value: true },
    { label: "Não", value: false },
    { label: "Qualquer", value: -1 },
];

const OPTIONS_TIPO_LOTE = TIPO_CHOICE_LOTE_SERIAL.map((v) => {
    if (v.value === "-") return { ...v, label: "Qualquer" };
    return v;
});

export const RelatorioLotes = () => {
    const [empresa, setEmpresa] = useState(null);
    const [buscarListagem, setBuscarListagem] = useState(false);
    const [url, setUrl] = useState(null);

    const { solicitarRelatorio } = useRelatorio();

    const empresaSelecionada = useRef();
    const listagemRef = useRef();
    const botaoCsv = useRef();
    const formRef = useRef();

    useClearRefs(empresaSelecionada, formRef, botaoCsv, listagemRef);

    const submit = (e, limpar = true) => {
        if (limpar) limparVisualizacao();
        formRef.current?.handleSubmit(e);
    };

    const handleVisualizar = (e) => {
        setBuscarListagem(true);
        submit(e, false);
    };

    const setFieldValue = (field, value, shouldValidate) => {
        formRef.current?.setFieldValue(field, value, shouldValidate);
    };

    const setErrors = (values) => {
        formRef.current?.setErrors(values);
    };

    const limparFormulario = () => {
        formRef.current?.resetForm();
    };

    const limparVisualizacao = () => {
        setUrl(null);
        setBuscarListagem(false);
    };

    const aplicarFiltrosCsv = (values) => {
        return botaoCsv?.current?.filtrosCSV(values);
    };

    const gerarFiltrosDadosValidados = (dados) => {
        let filtros = {};
        if (dados) {
            Object.keys(dados).forEach((key) => {
                if (dados[key] !== undefined && dados[key] !== null && dados[key] !== "") {
                    filtros[key] = dados[key];
                }
            });
        }
        return filtros;
    };

    const filtersOnClick = async () => {
        const { values = {} } = formRef.current?.getFormikInstance();
        let { background, emails, corpo_email, ...dadosValidados } = values;
        if (dadosValidados.estoque) dadosValidados.estoque = dadosValidados.estoque.id;
        const filtros = gerarFiltrosDadosValidados(dadosValidados);
        return aplicarFiltrosCsv(filtros);
    };

    const filtrosTemplate = [
        {
            key: "saldolotesku__centro_estocagem__estoque_empresa__empresa",
            label: "Empresa",
            type: TIPOS_FILTROS_TEMPLATE.PESSOA,
        },
        {
            key: "saldolotesku__centro_estocagem",
            label: "Centro estocagem",
            path: "descricao",
        },
        {
            key: "bloqueado_vender",
            label: "Com venda bloqueada",
            optionKey: "value",
            optionLabel: "label",
            options: OPTIONS_BLOQUEADO_VENDA,
            type: TIPOS_FILTROS_TEMPLATE.CHOICE,
        },
        {
            key: "sku__lote_serial",
            label: "Tipo de lote",
            optionKey: "value",
            optionLabel: "label",
            options: OPTIONS_TIPO_LOTE,
            type: TIPOS_FILTROS_TEMPLATE.CHOICE,
        },
        {
            key: ["fabricacao__lte", "fabricacao__gte"],
            label: "Período de validade",
            type: TIPOS_FILTROS_TEMPLATE.DATEPERIOD,
        },
        {
            key: ["vencimento__lte", "vencimento__gte"],
            label: "Período de vencimento",
            type: TIPOS_FILTROS_TEMPLATE.DATEPERIOD,
        },
    ];

    async function handleSubmit({ background, emails, corpo_email, versao = "1", ...values } = null) {
        try {
            const formSchema = Yup.object().shape({
                saldolotesku__centro_estocagem__estoque_empresa__empresa: Yup.number()
                    .required("O campo 'empresa' é obrigatório")
                    .typeError("Informe uma 'empresa' válida"),
                saldolotesku__centro_estocagem: Yup.object()
                    .required("O campo 'centro de estocagem' é obrigatório")
                    .typeError("Informe um 'centro estocagem' válido"),
                fabricacao__lte: Yup.date().nullable().default(null).typeError("Informe uma 'validade inicial' válida"),
                fabricacao__gte: Yup.date().nullable().default(null).typeError("Informe uma 'validade final' válida"),
                vencimento__lte: Yup.date()
                    .nullable()
                    .default(null)
                    .typeError("Informe uma 'vencimento inicial' válida"),
                vencimento__gte: Yup.date().nullable().default(null).typeError("Informe uma 'vencimento final' válida"),
            });

            let dadosValidados = await formSchema.validate(values, {
                abortEarly: false,
            });

            let filtrosTemplateAplicados = montarFiltroTemplate(filtrosTemplate, {
                ...dadosValidados,
                saldolotesku__centro_estocagem__estoque_empresa__empresa:
                    dadosValidados?.saldolotesku__centro_estocagem__estoque_empresa__empresa
                        ? empresaSelecionada.current
                        : null,
            });
            filtrosTemplateAplicados = gerarFiltroTemplate(filtrosTemplateAplicados);
            if (dadosValidados.fabricacao__lte instanceof Date) {
                dadosValidados.fabricacao__lte = dataToStr(dadosValidados.fabricacao__lte, "yyyy-MM-dd");
            }
            if (dadosValidados.fabricacao__gte instanceof Date) {
                dadosValidados.fabricacao__gte = dataToStr(dadosValidados.fabricacao__gte, "yyyy-MM-dd");
            }
            if (dadosValidados.vencimento__lte instanceof Date) {
                dadosValidados.vencimento__lte = dataToStr(dadosValidados.vencimento__lte, "yyyy-MM-dd");
            }
            if (dadosValidados.vencimento__gte instanceof Date) {
                dadosValidados.vencimento__gte = dataToStr(dadosValidados.vencimento__gte, "yyyy-MM-dd");
            }
            if (dadosValidados.saldolotesku__centro_estocagem instanceof Object) {
                dadosValidados.saldolotesku__centro_estocagem = dadosValidados.saldolotesku__centro_estocagem.id;
            }
            if (dadosValidados.bloqueado_vender === -1) delete dadosValidados.bloqueado_vender;
            if (dadosValidados.sku__lote_serial === "-") delete dadosValidados.sku__lote_serial;

            if (!buscarListagem) {
                const filtros = gerarFiltrosDadosValidados(dadosValidados);
                solicitarRelatorio({
                    chave: RELATORIOS_PRODUTOS_LOTES,
                    emails,
                    filtros,
                    corpo_email,
                    filtros_template: filtrosTemplateAplicados,
                    versao,
                    enviar_fila: background,
                });
            } else {
                let filtros = [];
                for (const [k, v] of Object.entries(dadosValidados)) {
                    if (v !== null && v !== "") filtros.push(`${FILTROS_VIEWSET[k]}=${v}`);
                }
                const _url = `${BASE_URL}?${filtros.join("&")}`;
                setUrl(_url);
                listagemRef.current?.buscarDados();
            }
        } catch (error) {
            if (error instanceof Yup.ValidationError) {
                let errorMessages = {};
                error.inner.forEach((err) => {
                    errorMessages[err.path] = err.message;
                });
                setErrors(errorMessages);
            }
        }
    }

    const colunas = [
        { field: "tipo", header: "Tipo" },
        { field: "lote", header: "Lote/Serial", style: { width: "10%" }, align: "center" },
        {
            field: "fabricacao",
            header: "Fabricação",
            style: { width: "8%" },
            dateFormat: "dd/MM/yyyy",
            align: "center",
        },
        {
            field: "vencimento",
            header: "Vencimento",
            style: { width: "8%" },
            dateFormat: "dd/MM/yyyy",
            align: "center",
        },
        { field: "enderecamento", header: "Endereçamento" },
        { field: "complemento", header: "Complemento" },
        { field: "saldo", header: "Saldo", decimal: true, style: { width: "8%" }, align: "right" },
    ];

    const BotaoExportar = (props) => {
        return (
            <ExportCSV
                {...props}
                ref={botaoCsv}
                chave_relatorio={RELATORIOS_PRODUTOS_LOTES}
                fileName={FILE_NAME}
                filtersOnClick={filtersOnClick}
            />
        );
    };

    const onChangeEmpresa = (e) => {
        empresaSelecionada.current = e?.empresa;
    };

    const watchValues = useCallback((data = {}) => {
        const { saldolotesku__centro_estocagem__estoque_empresa__empresa = null } = data;
        setEmpresa(saldolotesku__centro_estocagem__estoque_empresa__empresa);
    }, []);

    return (
        <R.Wrapper titulo={"de lotes / seriais de produtos"}>
            <MakoFormGerador
                ref={formRef}
                watchValues={watchValues}
                formikProps={{
                    initialValues: {
                        saldolotesku__centro_estocagem__estoque_empresa__empresa: null,
                        saldolotesku__centro_estocagem: null,
                        bloqueado_vender: -1,
                        sku__lote_serial: "",
                        fabricacao__lte: null,
                        fabricacao__gte: null,
                        validado__lte: null,
                        validado__gte: null,
                    },
                    onSubmit: handleSubmit,
                }}
                camposFormularios={[
                    {
                        label: "Empresa",
                        inputId: "saldolotesku__centro_estocagem__estoque_empresa__empresa",
                        inputName: "saldolotesku__centro_estocagem__estoque_empresa__empresa",
                        component: MakoDropdownEmpresas,
                        required: true,
                        componentEspecial: COMPONENTES_ESPECIAIS.EMPRESA,
                        componentProps: {
                            getOnChange: onChangeEmpresa,
                        },
                        fieldSize: 4,
                    },
                    {
                        label: "Centro estocagem",
                        inputId: "saldolotesku__centro_estocagem",
                        inputName: "saldolotesku__centro_estocagem",
                        required: true,
                        component: MakoDropdownEstoques,
                        componentProps: {
                            buscar: !!empresa,
                            empresaId: empresa,
                        },
                        fieldSize: 4,
                    },
                    {
                        label: "Com venda bloqueada",
                        inputId: "bloqueado_vender",
                        inputName: "bloqueado_vender",
                        component: Dropdown,
                        componentProps: {
                            placeholder: "Selecione",
                            options: OPTIONS_BLOQUEADO_VENDA,
                            showClear: false,
                            optionValue: "value",
                            optionLabel: "label",
                        },
                        fieldSize: 2,
                    },
                    {
                        label: "Tipo de lote",
                        inputId: "sku__lote_serial",
                        inputName: "sku__lote_serial",
                        component: Dropdown,
                        componentProps: {
                            placeholder: "Selecione",
                            options: OPTIONS_TIPO_LOTE,
                            showClear: false,
                            optionValue: "value",
                            optionLabel: "label",
                        },
                        fieldSize: 2,
                    },
                    {
                        inputId: "fabricacao",
                        component: MakoInputPeriodo,
                        componentEspecial: COMPONENTES_ESPECIAIS.DATEPERIOD,
                        componentProps: {
                            label: "Período de fabricação entre",
                            nameInicio: "fabricacao__lte",
                            nameFinal: "fabricacao__gte",
                            valueInicio: "fabricacao__lte",
                            valueFinal: "fabricacao__gte",
                            errorInicio: "fabricacao__lte",
                            errorFinal: "fabricacao__gte",
                        },
                        fieldSize: 5,
                    },
                    {
                        inputId: "vencimento",
                        component: MakoInputPeriodo,
                        componentEspecial: COMPONENTES_ESPECIAIS.DATEPERIOD,
                        componentProps: {
                            label: "Período de vencimento entre",
                            nameInicio: "vencimento__lte",
                            nameFinal: "vencimento__gte",
                            valueInicio: "vencimento__lte",
                            valueFinal: "vencimento__gte",
                            errorInicio: "vencimento__lte",
                            errorFinal: "vencimento__gte",
                        },
                        fieldSize: 5,
                    },
                ]}
            >
                <R.Buttons.Visualizar onClick={handleVisualizar} />
                <R.Buttons.GerarPdf
                    chave={RELATORIOS_PRODUTOS_LOTES}
                    setFieldValue={setFieldValue}
                    handleSubmit={submit}
                />
                <R.Buttons.EnviarEmail handleSubmit={submit} setFieldValue={setFieldValue} />
                <R.Buttons.Limpar onClick={limparFormulario} />
            </MakoFormGerador>
            <div className="p-mt-2">
                <MakoListagem
                    ref={listagemRef}
                    colunas={colunas}
                    urlPesquisa={url}
                    msgTabelaVazia={typeof url !== "string" && "Busca não efetuada"}
                    botaoExportar={BotaoExportar}
                    configTabela={{
                        paginator: true,
                        lazy: true,
                        exportFilename: FILE_NAME,
                    }}
                />
            </div>
        </R.Wrapper>
    );
};
