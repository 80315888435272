import React, { useMemo } from "react";

import { Dropdown } from "@/components/Dropdown";

import useCaixaMovimento from "@/hooks/useCaixaMovimento";
import { gerarFiltrosUrl } from "@/assets/util/util";

const BASE_URL = "/financeiro/contas-financeiras";

export const DropdownContasFinanceiras = ({ apenasCaixa, filtros = {}, ...props }) => {
    const { caixaMov } = useCaixaMovimento();
    const { caixa } = caixaMov || {};

    const url = useMemo(() => {
        let query = { limit: 200 };
        if (apenasCaixa && caixa) query = { ...query, caixa: caixa.id };
        if (filtros) query = { ...query, ...filtros };
        return `${BASE_URL}?${gerarFiltrosUrl(query)}`;
    }, [apenasCaixa, caixa, filtros]);

    return <Dropdown url={url} optionValue="id" optionLabel="descricao" {...props} />;
};
