import React, { useCallback, useMemo } from "react";
import classNames from "classnames";
import { useFormik } from "formik";
import * as Yup from "yup";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import useLoadingLocal from "@/hooks/useLoadingLocal";
import useToast from "@/hooks/useToast";
import useHttp from "@/hooks/useHttp";
import { MAKO_ICONS } from "@/assets/constants/constants_styles";
import { MakoActionsButtons } from "@/components/MakoActionsButtons";
import { InputTextarea } from "primereact/inputtextarea";
import { MakoInputPercent } from "@/components/MakoInputs";
import { MakoInputMoeda } from "@/components/MakoInputMoeda";
import { Dropdown } from "@/components/Dropdown";
import { SIM_NAO_BOOLEAN, TIPOS_CHOICE_SERVICOS_SERVICO } from "@/assets/constants/constants";
import { gerarStringAleatoria } from "@/assets/util/util";
import useEmpresa from "@/hooks/useEmpresa";
import { SelectButton } from "primereact/selectbutton";

export const ModalNovoServico = ({ onFinish, onClose }) => {
    const [loading, showLoading, hideLoading] = useLoadingLocal();
    const { showSuccess, showError } = useToast();
    const { httpPost, httpGet } = useHttp();
    const { empresaSelecionadaId } = useEmpresa();

    const _INITIAL_VALUES = {
        empresa: empresaSelecionadaId,
        codigo: "",
        descricao: "",
        custo: 0,
        valor_venda: 0,
        tipo: null,
        terceirizado: false,
        ativo: true,
        comissao_interveniente: 0,
        requer_apontamento: false,
        custo_hora: false,
        detalhes: "",
    };

    const { setValues, setFieldValue, ...formik } = useFormik({
        initialValues: _INITIAL_VALUES,
        onSubmit: handleSubmit,
    });

    async function handleSubmit(values) {
        try {
            const formSchema = Yup.object().shape({
                codigo: Yup.string()
                    .max(10, "Máximo de caracteres atingido: 10")
                    .test("Validar código unico", "O código informado ja existe", (val) => {
                        return verificarExistenciaCodigo(val);
                    })
                    .required("O campo 'codigo' é obrigatório")
                    .typeError("Informe um 'código' válido"),
                descricao: Yup.string()
                    .max(100, "Máximo de caracteres atingido: 100")
                    .required("O campo 'descrição' é obrigatório")
                    .typeError("Informe um 'descrição' válido"),
                custo: Yup.number().required("O campo 'custo' é obrigatório.").typeError("Informe um 'custo' válido"),
                valor_venda: Yup.number()
                    .required("O campo 'valor venda' é obrigatório.")
                    .typeError("Informe um 'valor venda' válido"),
                tipo: Yup.string()
                    .max(1, "Máximo de caracteres atingido: 1")
                    .required("O campo 'tipo' é obrigatório")
                    .typeError("Informe um 'tipo' válido"),
                detalhes: Yup.string()
                    .max(255, "Máximo de caracteres atingido: 255")
                    .required("O campo 'detalhes' é obrigatório")
                    .typeError("Informe um 'detalhes' válido"),
                comissao_interveniente: Yup.number()
                    .required("O campo 'valor de comissão' é obrigatório.")
                    .typeError("Informe um 'valor de comissão' válido"),
            });
            await formSchema.validate(values, { abortEarly: false });

            const handlers = {
                201: () => {
                    showSuccess({
                        summary: "Sucesso",
                        detail: "Serviço cadastrado com sucesso!",
                        life: 1500,
                    });
                    if (typeof onFinish === "function") onFinish();
                },
            };

            showLoading();
            await httpPost(
                {
                    url: "/servicos/servicos/",
                    body: values,
                },
                handlers
            );
            hideLoading();
        } catch (error) {
            console.log(error);
            hideLoading();
            if (error instanceof Yup.ValidationError) {
                let errorMessages = {};
                error.inner.forEach((err) => {
                    errorMessages[err.path] = err.message;
                });
                formik.setErrors(errorMessages);
            } else showError();
        }
    }

    function gerarCodigo() {
        setFieldValue("codigo", gerarStringAleatoria(6));
    }

    const verificarExistenciaCodigo = useCallback(
        async (codigo) => {
            let existe = false;
            const handlers = {
                200: ({ data }) => {
                    existe = !data.results.length > 0;
                },
            };
            await httpGet(
                {
                    url: `/servicos/servicos/?empresa=${empresaSelecionadaId}&ativo=true&codigo=${codigo}&limit=1`,
                },
                handlers
            );
            return existe;
        },
        [empresaSelecionadaId, httpGet]
    );

    const onChangeTercerizado = (e) => {
        const { checked } = e.target;
        if (!checked) setFieldValue("comissao_interveniente", 0);
        formik.handleChange(e);
    };

    const tipoValido = useMemo(() => {
        if (formik.values.tipo === "R") {
            setFieldValue("comissao_interveniente", 0);
            setFieldValue("terceirizado", false);
            return false;
        }
        return true;
    }, [formik.values.tipo, setFieldValue]);

    return (
        <form onSubmit={formik.handleSubmit}>
            <div className="p-fluid p-formgrid p-grid">
                <div className="p-field p-col-12 p-md-3">
                    <label htmlFor="codigo">Código *</label>
                    <div className="p-inputgroup">
                        <InputText
                            id="codigo"
                            name="codigo"
                            onChange={formik.handleChange}
                            value={formik.values.codigo}
                            autoComplete="off"
                            maxLength={12}
                            className={classNames({ "p-invalid": formik.errors.codigo })}
                        />
                        <Button
                            type="button"
                            icon="pi pi-refresh"
                            className="p-button-info"
                            tooltip="Gerar código aleatório"
                            onClick={gerarCodigo}
                        />
                    </div>
                    {formik.errors.codigo && <small className="p-error">{formik.errors.codigo}</small>}
                </div>
                <div className="p-field p-col-12 p-md-9">
                    <label htmlFor="descricao">Descrição *</label>
                    <InputText
                        id="descricao"
                        name="descricao"
                        onChange={formik.handleChange}
                        value={formik.values.descricao}
                        autoComplete="off"
                        maxLength={100}
                        className={classNames({ "p-invalid": formik.errors.descricao })}
                    />
                    {formik.errors.descricao && <small className="p-error">{formik.errors.descricao}</small>}
                </div>
            </div>
            <div className="p-fluid p-formgrid p-grid">
                <div className="p-field p-col-12 p-md-3">
                    <label htmlFor="tipo">Tipo *</label>
                    <Dropdown
                        id="tipo"
                        name="tipo"
                        options={TIPOS_CHOICE_SERVICOS_SERVICO}
                        optionValue="value"
                        optionLabel="label"
                        onChange={formik.handleChange}
                        showClear={false}
                        value={formik.values.tipo}
                        className={classNames({ "p-invalid": formik.errors.tipo })}
                    />
                    {formik.errors.tipo && <small className="p-error">{formik.errors.tipo}</small>}
                </div>
                <div className="p-field p-col-12 p-md-3">
                    <label htmlFor="custo">Custo *</label>
                    <MakoInputMoeda
                        id="custo"
                        name="custo"
                        onChangeMoeda={formik.handleChange}
                        valueMoeda={formik.values.custo}
                        className={classNames({ "p-invalid": formik.errors.custo })}
                    />
                    {formik.errors.custo && <small className="p-error">{formik.errors.custo}</small>}
                </div>
                <div className="p-field p-col-12 p-md-3">
                    <label htmlFor="valor_venda">Preço</label>
                    <MakoInputMoeda
                        id="valor_venda"
                        name="valor_venda"
                        onChangeMoeda={formik.handleChange}
                        valueMoeda={formik.values.valor_venda}
                        className={classNames({ "p-invalid": formik.errors.valor_venda })}
                    />
                    {formik.errors.valor_venda && <small className="p-error">{formik.errors.valor_venda}</small>}
                </div>
                <div className="p-field p-col-12 p-md-3">
                    <label htmlFor="comissao_interveniente">Comissão interveniente</label>
                    <MakoInputPercent
                        id="comissao_interveniente"
                        name="comissao_interveniente"
                        onValueChange={formik.handleChange}
                        value={formik.values.comissao_interveniente}
                        disabled={!formik.values.terceirizado || !tipoValido}
                        className={classNames({ "p-invalid": formik.errors.comissao_interveniente })}
                    />
                    {formik.errors.comissao_interveniente && (
                        <small className="p-error">{formik.errors.comissao_interveniente}</small>
                    )}
                </div>
            </div>
            <div className="p-fluid p-formgrid p-grid">
                <div className="p-field p-col-12 p-md-3">
                    <label htmlFor="terceirizado">Terceirizado?</label>
                    <SelectButton
                        id="terceirizado"
                        name="terceirizado"
                        value={formik.values.terceirizado}
                        onChange={onChangeTercerizado}
                        optionValue="id"
                        optionLabel="label"
                        options={SIM_NAO_BOOLEAN}
                    />
                </div>
                <div className="p-field p-col-12 p-md-3">
                    <label htmlFor="custo_hora">Custo por hora?</label>
                    <SelectButton
                        id="custo_hora"
                        name="custo_hora"
                        value={formik.values.custo_hora}
                        onChange={formik.handleChange}
                        optionValue="id"
                        optionLabel="label"
                        options={SIM_NAO_BOOLEAN}
                    />
                </div>
                <div className="p-field p-col-12 p-md-3">
                    <label htmlFor="requer_apontamento">Requer apontamento?</label>
                    <SelectButton
                        id="requer_apontamento"
                        name="requer_apontamento"
                        value={formik.values.requer_apontamento}
                        onChange={formik.handleChange}
                        optionValue="id"
                        optionLabel="label"
                        options={SIM_NAO_BOOLEAN}
                    />
                </div>
            </div>
            <div className="p-fluid p-formgrid p-grid">
                <div className="p-field p-col-12 p-md-12">
                    <label htmlFor="detalhes">Detalhes do serviço *</label>
                    <InputTextarea
                        id="detalhes"
                        name="detalhes"
                        value={formik.values.detalhes}
                        onChange={formik.handleChange}
                        rows={2}
                        autoResize
                        maxLength={4096}
                        className={classNames({ "p-invalid": formik.errors.detalhes })}
                    />
                    {formik.errors.detalhes && <small className="p-error">{formik.errors.detalhes}</small>}
                </div>
            </div>
            <MakoActionsButtons>
                <Button
                    icon={MAKO_ICONS.NOVO}
                    label="Inserir"
                    type="submit"
                    className="p-button-info"
                    loading={loading}
                />
                <Button
                    label="Cancelar"
                    type="button"
                    icon={MAKO_ICONS.CANCEL}
                    className="p-button-danger"
                    onClick={onClose}
                    loading={loading}
                />
            </MakoActionsButtons>
        </form>
    );
};
