import React from "react";

import { useFormik } from "formik";
import * as Yup from "yup";

import { classNames } from "primereact/utils";

import { MakoInputPerfil, MakoInputPeriodo } from "@/components/MakoInputs";
import { MakoButton as Button } from "@/components/MakoButton";
import { MakoActionsButtons } from "@/components/MakoActionsButtons";
import { MakoAutoComplete } from "@/components/MakoAutoComplete";
import { Dropdown } from "@/components/Dropdown";
import { Label } from "@/components/Label";

import { MAKO_ICONS } from "@/assets/constants/constants_styles";
import { dataToStr } from "@/assets/util/datas";

const INITIAL_VALUES = {
    grupo: null,
    data_criacao__date__lte: null,
    data_criacao__date__gte: null,
    data_alteracao__date__lte: null,
    data_alteracao__date__gte: null,
    usuario_alterou: null,
    usuario_validou: null,
    clausulasmodelocontrato__clausula: null,
};

export const FiltroAvancadoModeloContrato = ({ onConfirm, onCancel, initialValues = {} }) => {
    const { setValues, setFieldValue, ...formik } = useFormik({
        initialValues: {
            ...INITIAL_VALUES,
            ...(initialValues instanceof Object && initialValues),
        },
        onSubmit: handleSubmit,
    });

    async function handleSubmit(values) {
        try {
            const formSchema = Yup.object().shape({
                grupo: Yup.number().nullable().typeError("Informe um 'grupo' válido"),
                data_criacao__date__lte: Yup.date().nullable().typeError("Informe uma 'data criação inicial' válida"),
                data_criacao__date__gte: Yup.date().nullable().typeError("Informe uma 'data criação final' válida"),
                data_alteracao__date__lte: Yup.date()
                    .nullable()
                    .typeError("Informe uma 'data alteração inicial' válida"),
                data_alteracao__date__gte: Yup.date().nullable().typeError("Informe uma 'data alteração final' válida"),
                usuario_alterou: Yup.object().nullable().typeError("Informe um 'usuário alteração' válido"),
                usuario_validou: Yup.object().nullable().typeError("Informe um 'usuário validação' válido"),
                clausulasmodelocontrato__clausula: Yup.object()
                    .nullable()
                    .typeError("Informe um 'usuário validação' válido"),
            });

            await formSchema.validate(values, {
                abortEarly: false,
            });
            let filtros = {};
            let dataFiltros = {};
            const DATE_FILTERS_KEY = [
                "data_criacao__date__lte",
                "data_criacao__date__gte",
                "data_alteracao__date__lte",
                "data_alteracao__date__gte",
            ];
            const OBJECT_FILTERS_KEY = ["usuario_alterou", "usuario_validou", "clausulasmodelocontrato__clausula"];
            Object.keys(values).forEach((key) => {
                if (values[key] !== null && values[key] !== "") {
                    dataFiltros[key] = values[key];
                    if (DATE_FILTERS_KEY.includes(key)) {
                        return (filtros[key] = dataToStr(values[key], "yyyy-MM-dd"));
                    }
                    if (OBJECT_FILTERS_KEY.includes(key)) {
                        return (filtros[key] = values[key]?.id);
                    }
                    return (filtros[key] = values[key]);
                }
            });
            onConfirm({ filtros, values: dataFiltros });
        } catch (error) {
            if (error instanceof Yup.ValidationError) {
                let errorMessages = {};
                error.inner.forEach((err) => {
                    errorMessages[err.path] = err.message;
                });
                formik.setErrors(errorMessages);
            }
        }
    }

    const resetForm = () => {
        formik.resetForm({ values: INITIAL_VALUES });
    };

    return (
        <form onSubmit={formik.handleSubmit}>
            <div className="p-fluid p-formgrid p-grid">
                <div className="p-field p-col-12 p-md-4">
                    <Label htmlFor="grupo" label="Grupo" />
                    <Dropdown
                        id="grupo"
                        name="grupo"
                        value={formik.values.grupo}
                        onChange={formik.handleChange}
                        url="/servicos/grupos-modelos-contratos/"
                        optionValue="id"
                        optionLabel="grupo"
                        placeholder="Digite ou selecione um grupo"
                        className={classNames({ "p-invalid": formik.errors.grupo })}
                    />
                    {formik.errors.grupo && <small className="p-error">{formik.errors.grupo}</small>}
                </div>
                <MakoInputPeriodo
                    label="Criado entre"
                    nameInicio="data_criacao__date__gte"
                    nameFinal="data_criacao__date__lte"
                    valueInicio={formik.values.data_criacao__date__gte}
                    valueFinal={formik.values.data_criacao__date__lte}
                    errorInicio={formik.errors.data_criacao__date__gte}
                    errorFinal={formik.errors.data_criacao__date__lte}
                    onChangeInicio={formik.handleChange}
                    onChangeFinal={formik.handleChange}
                />
                <MakoInputPeriodo
                    label="Alterado entre"
                    nameInicio="data_alteracao__date__gte"
                    nameFinal="data_alteracao__date__lte"
                    valueInicio={formik.values.data_alteracao__date__gte}
                    valueFinal={formik.values.data_alteracao__date__lte}
                    errorInicio={formik.errors.data_alteracao__date__gte}
                    errorFinal={formik.errors.data_alteracao__date__lte}
                    onChangeInicio={formik.handleChange}
                    onChangeFinal={formik.handleChange}
                />
            </div>
            <div className="p-fluid p-formgrid p-grid">
                <div className="p-field p-col-12 p-md-12">
                    <Label htmlFor="clausulasmodelocontrato__clausula" label="Cláusula" />
                    <MakoAutoComplete
                        inputId="clausulasmodelocontrato__clausula"
                        name="clausulasmodelocontrato__clausula"
                        field="nome"
                        urlSearch="/servicos/clausulas-contrato?search="
                        minCaracteresBusca={2}
                        placeholder="Digite para selecionar..."
                        value={formik.values.clausulasmodelocontrato__clausula}
                        onChange={formik.handleChange}
                        className={classNames({ "p-invalid": formik.errors.clausulasmodelocontrato__clausula })}
                    />
                </div>
            </div>
            <div className="p-fluid p-formgrid p-grid">
                <div className="p-field p-col-12 p-md-6">
                    <Label htmlFor="usuario_alterou" label="Alterado por" />
                    <MakoInputPerfil
                        id="usuario_alterou"
                        name="usuario_alterou"
                        value={formik.values.usuario_alterou}
                        onChange={formik.handleChange}
                        className={classNames({ "p-invalid": formik.errors.usuario_alterou })}
                    />
                    {formik.errors.usuario_alterou && (
                        <small className="p-error">{formik.errors.usuario_alterou}</small>
                    )}
                </div>
                <div className="p-field p-col-12 p-md-6">
                    <Label htmlFor="usuario_validou" label="Validado por" />
                    <MakoInputPerfil
                        id="usuario_validou"
                        name="usuario_validou"
                        value={formik.values.usuario_validou}
                        onChange={formik.handleChange}
                        className={classNames({ "p-invalid": formik.errors.usuario_validou })}
                    />
                    {formik.errors.usuario_validou && (
                        <small className="p-error">{formik.errors.usuario_validou}</small>
                    )}
                </div>
            </div>
            <MakoActionsButtons className="p-jc-end">
                <Button type="submit" icon={MAKO_ICONS.FILTRAR} label="Filtrar" />
                <Button
                    type="button"
                    icon={MAKO_ICONS.LIMPAR_FILTROS}
                    label="Limpar"
                    onClick={resetForm}
                    className="p-button-warning"
                />
                <Button
                    type="reset"
                    label="Cancelar"
                    icon={MAKO_ICONS.CANCEL}
                    onClick={onCancel}
                    className="p-button-danger"
                />
            </MakoActionsButtons>
        </form>
    );
};
