import React from "react";
import classNames from "classnames";
import { useHistory } from "react-router-dom";
import { InputText } from "primereact/inputtext";
import { Checkbox } from "primereact/checkbox";
import { Button } from "primereact/button";
import { useFormik } from "formik";
import * as Yup from "yup";
import useLoading from "@/hooks/useLoading";
import { PageBase } from "@/components/PageBase";
import { MakoAutoComplete } from "@/components/MakoAutoComplete";
import { InputNumber } from "primereact/inputnumber";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
import useHttp from "@/hooks/useHttp";
import useToast from "@/hooks/useToast";

export const ServicoMunicipioForm = () => {
    const history = useHistory();
    const { showLoading, hideLoading } = useLoading();
    const { state } = useLocation();
    const { httpPost, httpPatch } = useHttp();
    const { showSuccess, showError } = useToast();

    const { setValues, setFieldValue, ...formik } = useFormik({
        initialValues: state?.servico
            ? state.servico
            : {
                  servico: null,
                  descricao: "",
                  cnae: "",
                  municipio: null,
                  aliquota: 0,
                  ativo: true,
              },
        onSubmit: handleSubmit,
    });

    async function handleSubmit(values) {
        try {
            const formSchema = Yup.object().shape({
                municipio: Yup.object().required("O campo 'município' é obrigatório."),
                servico: Yup.object()
                    .required("O campo 'serviço' é obrigatório.")
                    .typeError("Informe um serviço válido."),
                descricao: Yup.string().max(100).required("O campo 'descrição' é obrigatório."),
                cnae: Yup.string().max(10).nullable().default(""),
                aliquota: Yup.number().required("O campo 'alíquota' é obrigatório."),
            });

            await formSchema.validate(values, { abortEarly: false });

            if (!values.id) {
                const handlers = {
                    201: () => {
                        showSuccess({
                            summary: "Sucesso",
                            detail: "Serviço do Município cadastrado com sucesso!",
                            life: 1500,
                        });
                        history.push("/servicos/servicos/servico-municipio");
                    },
                };

                showLoading();
                await httpPost(
                    {
                        url: "/servicos/servicos-municipio/",
                        body: {
                            ...values,
                            municipio: values.municipio.id,
                            servico: values.servico.id,
                        },
                    },
                    handlers
                );
                hideLoading();
            } else {
                const handlers = {
                    200: () => {
                        showSuccess({
                            summary: "Sucesso",
                            detail: "Serviço do Município alterado com sucesso!",
                            life: 1500,
                        });
                        history.push("/servicos/servicos/servico-municipio");
                    },
                };

                showLoading();
                await httpPatch(
                    {
                        url: `/servicos/servicos-municipio/${values.id}/`,
                        body: {
                            ...values,
                            municipio: values.municipio.id,
                            servico: values.servico.id,
                        },
                    },
                    handlers
                );
                hideLoading();
            }
        } catch (error) {
            hideLoading();
            if (error instanceof Yup.ValidationError) {
                let errorMessages = {};
                error.inner.forEach((err) => {
                    errorMessages[err.path] = err.message;
                });
                formik.setErrors(errorMessages);
            } else showError();
        }
    }

    const autoCompleteCidadeTemplate = (municipio) => {
        return `${municipio.codigo} - ${municipio.nome}`;
    };

    const autoCompleteServicoTemplate = (serv) => {
        return `${serv.codigo} - ${serv.descricao}`;
    };

    return (
        <PageBase>
            <h3>{!formik.values.id ? "Cadastro de código de serviço" : "Manutenção de código de serviço"}</h3>
            <form onSubmit={formik.handleSubmit}>
                <div className="p-fluid p-formgrid p-grid">
                    <div className="p-field p-col-12 p-md-12">
                        <label htmlFor="servico">Serviço *</label>
                        <MakoAutoComplete
                            id="servico"
                            name="servico"
                            placeholder="Busque pelo descrição ou código do município ... (min 4 caracteres)"
                            minCaracteresBusca={3}
                            value={formik.values.servico}
                            onChange={(e) => {
                                setFieldValue("codigo", e.value.codigo);
                                setFieldValue("descricao", e.value.descricao);
                                formik.handleChange(e);
                            }}
                            itemTemplate={autoCompleteServicoTemplate}
                            field="descricao"
                            urlSearch={"/servicos/servicos?query={id, codigo, descricao}&search="}
                            className={classNames({ "p-invalid": formik.errors.servico })}
                        />
                        {formik.errors.servico && <small className="p-error">{formik.errors.servico}</small>}
                    </div>
                </div>
                <div className="p-fluid p-formgrid p-grid">
                    <div className="p-field p-col-12 p-md-3">
                        <label htmlFor="codigo">Código município *</label>
                        <InputText
                            id="codigo"
                            name="codigo"
                            value={formik.values.codigo}
                            tooltip="Código de tributação municipal do ISSQN."
                            tooltipOptions={{ position: "bottom" }}
                            onChange={formik.handleChange}
                            className={classNames({ "p-invalid": formik.errors.codigo })}
                        />
                        {formik.errors.codigo && <small className="p-error">{formik.errors.codigo}</small>}
                    </div>
                    <div className="p-field p-col-12 p-md-9">
                        <label htmlFor="descricao">Descrição município *</label>
                        <InputText
                            id="descricao"
                            name="descricao"
                            value={formik.values.descricao}
                            tooltip="Descrição do serviço de acordo com o município."
                            tooltipOptions={{ position: "bottom" }}
                            onChange={formik.handleChange}
                            className={classNames({ "p-invalid": formik.errors.descricao })}
                        />
                        {formik.errors.descricao && <small className="p-error">{formik.errors.descricao}</small>}
                    </div>
                </div>
                <div className="p-fluid p-formgrid p-grid">
                    <div className="p-field p-col-12 p-md-6">
                        <label htmlFor="municipio">Município *</label>
                        <MakoAutoComplete
                            id="municipio"
                            name="municipio"
                            placeholder="Busque pelo nome ou código do município ... (min 4 caracteres)"
                            minCaracteresBusca={3}
                            value={formik.values.municipio}
                            onChange={formik.handleChange}
                            itemTemplate={autoCompleteCidadeTemplate}
                            field="nome"
                            urlSearch={"/pessoas/cidades?query={id, codigo, nome}&search="}
                            className={classNames({ "p-invalid": formik.errors.municipio })}
                        />
                        {formik.errors.municipio && <small className="p-error">{formik.errors.municipio}</small>}
                    </div>
                    <div className="p-field p-col-12 p-md-3">
                        <label htmlFor="cnae">CNAE</label>
                        <InputText
                            id="cnae"
                            name="cnae"
                            keyfilter={/^[0-9.]/}
                            value={formik.values.cnae}
                            onChange={formik.handleChange}
                            className={classNames({ "p-invalid": formik.errors.cnae })}
                        />
                        {formik.errors.cnae && <small className="p-error">{formik.errors.cnae}</small>}
                    </div>
                    <div className="p-field p-col-12 p-md-3">
                        <label htmlFor="aliquota">Alíquota *</label>
                        <InputNumber
                            id="aliquota"
                            name="aliquota"
                            prefix="%"
                            min={0}
                            max={100}
                            mode="decimal"
                            minFractionDigits={2}
                            maxFractionDigits={2}
                            value={formik.values.aliquota}
                            onValueChange={formik.handleChange}
                            className={classNames({ "p-invalid": formik.errors.aliquota })}
                        />
                        {formik.errors.aliquota && <small className="p-error">{formik.errors.aliquota}</small>}
                    </div>
                </div>
                <div className="p-fluid p-formgrid p-grid">
                    <div className="p-field-checkbox p-col-12 p-md-3">
                        <Checkbox
                            id="ativo"
                            name="ativo"
                            checked={formik.values.ativo}
                            onChange={formik.handleChange}
                        />
                        <label htmlFor="ativo">Ativo?</label>
                    </div>
                </div>
                <p>* Campos obrigatórios</p>
                <div className="p-grid p-col-12 p-md-6">
                    <Button label="Gravar" icon="pi pi-save" type="submit" className="p-button-info p-mr-2" />
                    <Button
                        label="Cancelar"
                        icon="pi pi-times"
                        type="button"
                        className="p-button-danger p-mr-2"
                        onClick={() => history.push("/servicos/servicos/servico-municipio")}
                    />
                </div>
            </form>
        </PageBase>
    );
};
