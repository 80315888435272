import React, { forwardRef, useCallback, useEffect, useImperativeHandle, useRef, useState } from "react";
import { Dialog } from "primereact/dialog";
import { Divider } from "primereact/divider";

import useHttp from "@/hooks/useHttp";

import { FormEmpresa } from "./form-empresa";
import { FormCertificado } from "./form-certificado";

const Modal = ({ perfil }, ref) => {
    const [visible, setVisible] = useState(false);
    const [apenasLeitura, setApenasLeitura] = useState(false);
    const [infoEmpresa, setInfoEmpresa] = useState(null);
    const formEmpresaRef = useRef(null);
    const { httpGet } = useHttp();

    const getInfoEmpresa = useCallback(
        async (signal) => {
            if (!visible || !perfil) return;
            const handlers = {
                200: ({ data }) => {
                    const {
                        results: [info],
                    } = data;
                    if (info) {
                        setInfoEmpresa(info);
                        formEmpresaRef.current?.setValues(info);
                    }
                },
            };
            const url = `/pessoas/info-empresa-certificado-digital/?perfil=${perfil.id}`;
            await httpGet({ url, signal }, handlers);
        },
        [visible, perfil, httpGet]
    );

    useEffect(() => {
        const controller = new AbortController();
        getInfoEmpresa(controller.signal);
        return () => controller.abort();
    }, [getInfoEmpresa]);

    const setInfoFormEmpresa = () => {
        if (perfil && !infoEmpresa) formEmpresaRef.current?.setValues(perfil);
    };

    const abrirModal = (apenasLeitura) => {
        setApenasLeitura(!!apenasLeitura);
        setVisible(true);
    };

    const fecharModal = () => {
        formEmpresaRef.current?.resetForm();
        setInfoEmpresa(null);
        setApenasLeitura(false);
        setVisible(false);
    };

    useImperativeHandle(ref, () => ({ abrirModal }));

    return (
        <Dialog
            header="Gerenciamento de certificado digital"
            visible={visible}
            onHide={fecharModal}
            onShow={setInfoFormEmpresa}
            style={{ width: "50vw" }}
        >
            <Divider align="left">
                <b>Dados da pessoa / empresa</b>
            </Divider>
            <FormEmpresa
                ref={formEmpresaRef}
                perfilId={perfil?.id}
                apenasLeitura={apenasLeitura}
                infoEmpresa={infoEmpresa}
                onFinish={(e) => setInfoEmpresa(e)}
                onDelete={fecharModal}
            />
            <Divider align="left">
                <b>Certificado</b>
            </Divider>
            <FormCertificado
                perfilId={perfil?.id}
                infoEmpresa={infoEmpresa}
                apenasLeitura={apenasLeitura}
                onUpload={(e) => setInfoEmpresa(e)}
                onDelete={getInfoEmpresa}
            />
        </Dialog>
    );
};

export const ModalGerenciamentoCertificadoDigital = forwardRef(Modal);
