import React, { useRef } from "react";

import * as Yup from "yup";
import { useFormik } from "formik";

import { classNames } from "primereact/utils";

import { MakoButton as Button } from "@/components/MakoButton";
import { MakoActionsButtons } from "@/components/MakoActionsButtons";
import { Label } from "@/components/Label";

import { DropdownFormasRecebimentos } from "../../components";

export const ReceberCartaoModo = ({ pendencia, successCallback = () => {}, cancelCallback = () => {} }) => {
    const dropFormasRef = useRef(null);

    const { setValues, setFieldValue, ...formik } = useFormik({
        initialValues: {
            forma_recebimento: null,
        },
        onSubmit: handleSubmit,
    });

    async function handleSubmit(values, formikHelpers) {
        try {
            const formSchema = Yup.object().shape({
                forma_recebimento: Yup.number()
                    .required("O campo 'forma recebimento' é obrigatório")
                    .typeError("Informe uma 'forma recebimento' válida"),
            });

            await formSchema.validate(values, { abortEarly: false });

            const { forma_recebimento } = values;
            const { para_receber, tipo_chave } = pendencia;

            const data = { forma_recebimento, tipo_chave, id: pendencia?.id, valor_receber: para_receber };

            dropFormasRef.current?.show(data);
        } catch (error) {
            if (error instanceof Yup.ValidationError) {
                let errorMessages = {};
                error.inner.forEach((err) => {
                    errorMessages[err.path] = err.message;
                });
                formikHelpers.setErrors(errorMessages);
            }
        }
    }

    return (
        <form onSubmit={formik.handleSubmit}>
            <div className="p-fluid p-formgrid p-grid">
                <div className="p-field p-col-12 p-md-12">
                    <Label htmlFor="forma_recebimento" label="Forma recebimento" obrigatorio />
                    <DropdownFormasRecebimentos
                        id="forma_recebimento"
                        name="forma_recebimento"
                        ref={dropFormasRef}
                        showClear={false}
                        filtros={{ tipo__in: "1,2" }}
                        value={formik.values.forma_recebimento}
                        onChange={formik.handleChange}
                        successCallback={successCallback}
                        className={classNames({ "p-invalid": formik.errors.forma_recebimento })}
                    />
                    {formik.errors.forma_recebimento && (
                        <small className="p-error">{formik.errors.forma_recebimento}</small>
                    )}
                </div>
            </div>
            <MakoActionsButtons className="p-jc-end">
                <Button label="Cancelar" type="button" onClick={cancelCallback} className="p-button p-button-danger" />
                <Button
                    label="Confirmar"
                    type="button"
                    onClick={formik.handleSubmit}
                    className="p-button p-button-success"
                />
            </MakoActionsButtons>
        </form>
    );
};
