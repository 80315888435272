import React, { useMemo, useRef } from "react";

import { MakoActionsButtonsColumn } from "@/components/MakoActionsButtonsColumn";
import { MakoListagemHierarquica } from "@/components/MakoListagemHierarquica";
import { MakoControleAcesso } from "@/components/MakoControleAcesso";
import { MakoButton as Button } from "@/components/MakoButton";
import { ButtonListagemOpcoes } from "@/components/ButtonListagemOpcoes";
import { PageBase } from "@/components/PageBase";

import { gerarFiltrosUrl } from "@/assets/util/util";
import { MAKO_ICONS } from "@/assets/constants/constants_styles";
import { BUTTON_LISTAGEM_EDITAR } from "@/assets/constants/presets";
import permissoes from "@/assets/constants/permissoes";

import useEmpresa from "@/hooks/useEmpresa";

const BASE_URL = "/pessoas/hierarquia-funcional/";
const BASE_URL_MENU = "/pessoas/hierarquia-funcional-menu/";

export const HierarquiaFuncionalPage = () => {
    const { empresaSelecionadaId } = useEmpresa();

    const listagemRef = useRef();

    const actionBodyTemplate = ({ data: rowData }) => {
        return (
            <MakoActionsButtonsColumn>
                <MakoControleAcesso
                    permissao={[permissoes.GESTAO_CADASTRO_HIERARQUIAFUNCIONAL_EDITAR]}
                    componente={Button}
                    {...BUTTON_LISTAGEM_EDITAR}
                    to={{
                        pathname: "/gestao/cadastros/hierarquia-funcional/form",
                        state: { hierarquia: { ...rowData, cargo: rowData.cargo.id } },
                    }}
                />
                <ButtonListagemOpcoes
                    url={BASE_URL}
                    objetoId={rowData.id}
                    objetoLabel={rowData.pessoa.nome}
                    prefixo="a"
                    entidade="hierarquia"
                    onAction={() => listagemRef.current?.buscarDados()}
                    deleteOptions={{
                        exigeConfirmacao: true,
                        msgConfirmacao: (
                            <span>
                                Deseja realmente excluir <b>{rowData.pessoa.nome}</b> da hierarquia?
                            </span>
                        ),
                        msgToastErroExclusao: "A hierarquia não pode ser excluída.",
                    }}
                />
            </MakoActionsButtonsColumn>
        );
    };

    const colunas = [
        {
            field: "id",
            header: "Código",
            style: { width: "6%" },
            expander: true,
        },
        {
            field: "pessoa.nome",
            header: "Pessoa",
        },
        {
            field: "cargo.nome",
            header: "Cargo",
        },
        {
            field: "action",
            header: "Ações",
            style: { width: "8%" },
            action: (e) => actionBodyTemplate(e),
        },
    ];

    const cabecalhoTabela = (
        <>
            <MakoControleAcesso
                permissao={[permissoes.GESTAO_CADASTRO_HIERARQUIAFUNCIONAL_INCLUIR]}
                componente={Button}
                label="Novo"
                icon={MAKO_ICONS.NOVO}
                className="p-button-success"
                to={"/gestao/cadastros/hierarquia-funcional/form"}
            />
        </>
    );

    const header = useMemo(() => {
        return (
            <>
                {!!empresaSelecionadaId ? (
                    <span>Hierarquia Funcional</span>
                ) : (
                    <span className="p-error">Hierarquia Funcional (selecione uma empresa)</span>
                )}
            </>
        );
    }, [empresaSelecionadaId]);

    const url = useMemo(() => {
        return `${BASE_URL_MENU}?${gerarFiltrosUrl({ empresa: empresaSelecionadaId })}`;
    }, [empresaSelecionadaId]);

    return (
        <PageBase>
            <MakoListagemHierarquica
                ref={listagemRef}
                titulo={header}
                colunas={colunas}
                cabecalho={cabecalhoTabela}
                urlPesquisa={url}
                tabelaConfig={{
                    scrollable: true,
                }}
                fazerBusca={!!empresaSelecionadaId}
            />
        </PageBase>
    );
};
