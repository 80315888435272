import React, { useEffect, useMemo } from "react";

import classNames from "classnames";
import { useFormik } from "formik";
import * as Yup from "yup";
import { InputTextarea } from "primereact/inputtextarea";
import { Button } from "primereact/button";
import { MakoInputPercent } from "@/components/MakoInputs/MakoInputPercent";
import { MakoAutoComplete } from "@/components/MakoAutoComplete";
import { MakoInputPerfil } from "@/components/MakoInputs/MakoInputPerfil";
import { MakoInputMoeda } from "@/components/MakoInputMoeda";
import { Dropdown } from "@/components/Dropdown";
import { Label } from "@/components/Label";
import { SIM_NAO_BOOLEAN, TIPOS_CHOICE_SERVICOS_SERVICO } from "@/assets/constants/constants";
import useContratoServico from "@/hooks/useContratoServico";
import useLoadingLocal from "@/hooks/useLoadingLocal";
import useEmpresa from "@/hooks/useEmpresa";
import useToast from "@/hooks/useToast";
import useHttp from "@/hooks/useHttp";
import { MAKO_ICONS } from "@/assets/constants/constants_styles";
import { MakoActionsButtons } from "@/components/MakoActionsButtons";
import { InputText } from "primereact/inputtext";
import { SelectButton } from "primereact/selectbutton";
import { MakoCalendar } from "@/components/MakoCalendar";
import { dataToStr } from "@/assets/util/datas";

export const ModalServicoCabecalho = ({ onFinish, onClose, servico }) => {
    const { empresaSelecionadaId, empresaSelecionada } = useEmpresa();
    const { contrato, atualizarValorContrato } = useContratoServico();
    const [loading, showLoading, hideLoading] = useLoadingLocal();
    const { showSuccess, showError } = useToast();
    const { httpPatch, httpPost } = useHttp();

    const initialForm = {
        empresa: empresaSelecionadaId,
        codigo: "",
        descricao: "",
        custo: 0,
        preco: 0,
        tipo: null,
        terceirizado: false,
        ativo: true,
        comissao_interveniente: 0,
        requer_apontamento: false,
        template: false,
        custo_hora: false,
        detalhes: "",
        executor: empresaSelecionada,
        servico_municipio: null,
        uf: null,
        municipio: null,
        percentual_comissao: 0,
        operacao_fiscal: null,
    };

    const { setValues, setFieldValue, ...formik } = useFormik({
        initialValues: initialForm,
        onSubmit: handleSubmit,
    });

    async function handleSubmit(values) {
        try {
            const formSchema = Yup.object().shape({
                custo: Yup.number().required("O campo 'custo' é obrigatório.").typeError("Informe um 'custo' válido"),
                data_encerramento: Yup.date()
                    .required("O campo 'data encerramento' é obrigatório.")
                    .typeError("Informe uma data válida"),
                servico_municipio: Yup.object()
                    .required("O campo 'serviço do município' é obrigatório.")
                    .typeError("Informe um serviço válido"),
                servico: Yup.object()
                    .required("O campo 'serviço' é obrigatório.")
                    .typeError("Informe um serviço válido"),
                preco: Yup.number().required("O campo 'preço' é obrigatório.").typeError("Informe um 'preço' válido"),
                percentual_comissao: Yup.number()
                    .required("O campo 'percentual' é obrigatório.")
                    .typeError("Informe um 'percentual' válido"),
                tipo: Yup.string()
                    .max(1, "Máximo de caracteres atingido: 1")
                    .required("O campo 'tipo' é obrigatório")
                    .typeError("Informe um 'tipo' válido"),
                detalhes: Yup.string()
                    .max(255, "Máximo de caracteres atingido: 255")
                    .required("O campo 'detalhes' é obrigatório")
                    .typeError("Informe um 'detalhes' válido"),
                comissao_interveniente: Yup.number()
                    .required("O campo 'valor de comissão' é obrigatório.")
                    .typeError("Informe um 'valor de comissão' válido"),
                operacao_fiscal: Yup.number()
                    .required("O campo 'operacao fiscal' é obrigatório.")
                    .typeError("Informe um 'operacao fiscal' válido"),
            });

            await formSchema.validate(values, { abortEarly: false });

            if (!values.id) {
                const handler = {
                    201: () => {
                        showSuccess({
                            summary: "Sucesso",
                            detail: "Serviço cadastrato com sucesso!",
                            life: 1500,
                        });
                        atualizarValorContrato();
                        onFinish();
                    },
                };

                showLoading();
                await httpPost(
                    {
                        url: "/servicos/servicos-contrato/",
                        body: {
                            ...values,
                            contrato: contrato.id,
                            executor: values.executor.id,
                            servico_municipio: values.servico_municipio.id,
                            servico: values.servico.id,
                            data_encerramento: dataToStr(values.data_encerramento, "yyyy-MM-dd"),
                        },
                    },
                    handler
                );
                hideLoading();
            } else {
                const handlers = {
                    200: () => {
                        showSuccess({
                            summary: "Sucesso",
                            detail: "Serviço alterado com sucesso!",
                            life: 1500,
                        });
                        atualizarValorContrato();
                        onFinish();
                    },
                };

                showLoading();
                await httpPatch(
                    {
                        url: `/servicos/servicos-contrato/${values.id}/`,
                        body: {
                            ...values,
                            contrato: values.contrato.id,
                            executor: values.executor.id,
                            servico_municipio: values.servico_municipio.id,
                        },
                    },
                    handlers
                );
                hideLoading();
            }
        } catch (error) {
            if (error instanceof Yup.ValidationError) {
                let errorMessages = {};
                error.inner.forEach((err) => {
                    errorMessages[err.path] = err.message;
                });
                formik.setErrors(errorMessages);
            } else {
                showError({
                    summary: "Erro :(",
                    detail: "Desculpe, não foi possível processar a sua requisição.",
                    life: 3000,
                });
            }
        }
    }

    const onChangeTercerizado = (e) => {
        const { checked } = e.target;
        if (!checked) setFieldValue("comissao_interveniente", 0);
        formik.handleChange(e);
    };

    const onChangeParticipacao = (e) => {
        const { value } = e;
        setFieldValue("terceirizado", value > 0);
        formik.handleChange(e);
    };

    const onChangeUf = (e) => {
        if (!e.value) setFieldValue("municipio", null);
        formik.handleChange(e);
    };

    const onChangeServico = (e) => {
        if (e.value instanceof Object) {
            const { percentual_comissao, ...servico } = e.value;
            setFieldValue("percentual_comissao", percentual_comissao || 0);
            setFieldValue("custo", servico.custo);
            setFieldValue("custo_hora", servico.custo_hora);
            setFieldValue("preco", servico.valor_venda);
            setFieldValue("tipo", servico.tipo);
            setFieldValue("terceirizado", servico.terceirizado);
            setFieldValue("detalhes", servico.detalhes);
            setFieldValue("comissao_interveniente", servico.comissao_interveniente);
        }
        formik.handleChange(e);
    };

    const tipoValido = useMemo(() => {
        if (formik.values.tipo === "R") {
            setFieldValue("comissao_interveniente", 0);
            setFieldValue("terceirizado", false);
            return false;
        }
        return true;
    }, [formik.values.tipo, setFieldValue]);

    const urlServico = useMemo(() => {
        return `/servicos/servicos-municipio/?municipio=${formik.values.municipio}&search=`;
    }, [formik.values.municipio]);

    useEffect(() => {
        if (servico) {
            setValues({
                ...servico,
                executor: servico.executor,
                servico_municipio: servico.servico_municipio,
                uf: servico.servico_municipio.municipio.estado.id,
                municipio: servico.servico_municipio.municipio.id,
                operacao_fiscal: servico.operacao_fiscal.id,
            });
        }
    }, [servico, setValues]);

    return (
        <>
            <form onSubmit={formik.handleSubmit}>
                <div className="p-fluid p-formgrid p-grid">
                    <div className="p-field p-col-12 p-md-2">
                        <label htmlFor="codigo">Código</label>
                        <InputText id="codigo" name="codigo" value={formik.values.servico?.codigo} disabled readOnly />
                    </div>
                    <div className="p-field p-col-12 p-md-10">
                        <Label htmlFor="servico" label="Serviço" obrigatorio />
                        <MakoAutoComplete
                            id="servico"
                            name="servico"
                            minCaracteresBusca={3}
                            field="descricao"
                            placeholder="Comece a digitar para buscar... (min 3 caracteres)"
                            urlSearch={`/servicos/servicos?search=`}
                            value={formik.values.servico}
                            onChange={onChangeServico}
                            className={classNames({ "p-invalid": formik.errors.servico })}
                        />
                        {formik.errors.servico && <small className="p-error">{formik.errors.servico}</small>}
                    </div>
                </div>
                <div className="p-fluid p-formgrid p-grid">
                    <div className="p-field p-col-12 p-md-2">
                        <Label htmlFor="tipo" label="Tipo" obrigatorio />
                        <Dropdown
                            id="tipo"
                            name="tipo"
                            options={TIPOS_CHOICE_SERVICOS_SERVICO}
                            optionValue="value"
                            optionLabel="label"
                            showClear={false}
                            onChange={formik.handleChange}
                            value={formik.values.tipo}
                            className={classNames({ "p-invalid": formik.errors.tipo })}
                        />
                        {formik.errors.tipo && <small className="p-error">{formik.errors.tipo}</small>}
                    </div>
                    <div className="p-field p-col-12 p-md-2">
                        <Label htmlFor="custo" label="Custo" obrigatorio />
                        <MakoInputMoeda
                            id="custo"
                            name="custo"
                            onChangeMoeda={formik.handleChange}
                            valueMoeda={formik.values.custo}
                            className={classNames({ "p-invalid": formik.errors.custo })}
                        />
                        {formik.errors.custo && <small className="p-error">{formik.errors.custo}</small>}
                    </div>
                    <div className="p-field p-col-12 p-md-2">
                        <Label htmlFor="preco" label="Preço" obrigatorio />
                        <MakoInputMoeda
                            id="preco"
                            name="preco"
                            onChangeMoeda={formik.handleChange}
                            valueMoeda={formik.values.preco}
                            className={classNames({ "p-invalid": formik.errors.preco })}
                        />
                        {formik.errors.preco && <small className="p-error">{formik.errors.preco}</small>}
                    </div>
                    <div className="p-field p-col-12 p-md-2">
                        <Label htmlFor="comissao_interveniente" label="Com. interveniente" />
                        <MakoInputPercent
                            id="comissao_interveniente"
                            name="comissao_interveniente"
                            onValueChange={onChangeParticipacao}
                            value={formik.values.comissao_interveniente}
                            disabled={!tipoValido || !formik.values.terceirizado}
                            className={classNames({ "p-invalid": formik.errors.comissao_interveniente })}
                        />
                        {formik.errors.comissao_interveniente && (
                            <small className="p-error">{formik.errors.comissao_interveniente}</small>
                        )}
                    </div>
                    <div className="p-field p-col-12 p-md-2">
                        <Label htmlFor="terceirizado" label="Terceirizado?" />
                        <SelectButton
                            id="terceirizado"
                            name="terceirizado"
                            value={formik.values.terceirizado}
                            onChange={onChangeTercerizado}
                            optionValue="id"
                            optionLabel="label"
                            options={SIM_NAO_BOOLEAN}
                        />
                    </div>
                    <div className="p-field p-col-12 p-md-2">
                        <Label htmlFor="custo_hora" label="Custo por hora?" />
                        <SelectButton
                            id="custo_hora"
                            name="custo_hora"
                            value={formik.values.custo_hora}
                            onChange={formik.handleChange}
                            optionValue="id"
                            optionLabel="label"
                            options={SIM_NAO_BOOLEAN}
                        />
                    </div>
                </div>
                <div className="p-fluid p-formgrid p-grid">
                    <div className="p-field p-col-12 p-md-2">
                        <Label htmlFor="requer_apontamento" label="Apontar?" />
                        <SelectButton
                            id="requer_apontamento"
                            name="requer_apontamento"
                            value={formik.values.requer_apontamento}
                            onChange={formik.handleChange}
                            optionValue="id"
                            optionLabel="label"
                            options={SIM_NAO_BOOLEAN}
                        />
                    </div>
                    <div className="p-field p-col-12 p-md-8">
                        <Label htmlFor="executor" label="Executor (Terceiro)" obrigatorio />
                        <MakoInputPerfil
                            id="executor"
                            name="executor"
                            value={formik.values.executor}
                            onChange={formik.handleChange}
                            className={classNames({ "p-invalid": formik.errors.executor })}
                        />
                        {formik.errors.executor && <small className="p-error">{formik.errors.executor}</small>}
                    </div>
                    <div className="p-field p-col-12 p-md-2">
                        <Label htmlFor="data_encerramento" label="Data encerramento" obrigatorio />
                        <MakoCalendar
                            id="data_encerramento"
                            name="data_encerramento"
                            valueCalendar={formik.values.data_encerramento}
                            onChange={formik.handleChange}
                            className={classNames({ "p-invalid": formik.errors.data_encerramento })}
                        />
                        {formik.errors.data_encerramento && (
                            <small className="p-error">{formik.errors.data_encerramento}</small>
                        )}
                    </div>
                </div>
                <div className="p-fluid p-formgrid p-grid">
                    <div className="p-field p-col-12 p-md-12">
                        <Label htmlFor="detalhes" label="Detalhes do serviço *" />
                        <InputTextarea
                            id="detalhes"
                            name="detalhes"
                            value={formik.values.detalhes}
                            onChange={formik.handleChange}
                            rows={4}
                            autoResize
                            maxLength={4096}
                            className={classNames({ "p-invalid": formik.errors.detalhes })}
                        />
                        {formik.errors.detalhes && <small className="p-error">{formik.errors.detalhes}</small>}
                    </div>
                </div>
                <div className="p-fluid p-formgrid p-grid">
                    <div className="p-field p-col-12 p-md-3">
                        <Label htmlFor="uf" label="UF" obrigatorio />
                        <Dropdown
                            id="uf"
                            name="uf"
                            url="/pessoas/estados?query={id,nome,uf,codigo_uf}&&limit=100"
                            optionValue="id"
                            optionLabel="nome"
                            filter
                            showClear={false}
                            filterBy="nome,sigla"
                            value={formik.values.uf}
                            onChange={onChangeUf}
                            className={classNames({
                                "p-invalid": formik.errors.uf,
                            })}
                        />
                        {formik.errors.uf && <small className="p-error">{formik.errors.uf}</small>}
                    </div>
                    <div className="p-field p-col-12 p-md-3">
                        <Label htmlFor="municipio" label="Município" obrigatorio />
                        <Dropdown
                            id="municipio"
                            name="municipio"
                            url={`/pessoas/cidades/?estado=${formik.values.uf}&query={id,nome}&limit=1000&ordering=nome`}
                            buscar={!!formik.values.uf}
                            optionValue="id"
                            optionLabel="nome"
                            filter
                            showClear={false}
                            filterBy="nome"
                            value={formik.values.municipio}
                            onChange={formik.handleChange}
                            className={classNames({
                                "p-invalid": formik.errors.municipio,
                            })}
                        />
                        {formik.errors.municipio && <small className="p-error">{formik.errors.municipio}</small>}
                    </div>
                    <div className="p-field p-col-12 p-md-6">
                        <Label htmlFor="servico_municipio" label="Serviço do cadastro municipal" obrigatorio />
                        <MakoAutoComplete
                            id="servico_municipio"
                            name="servico_municipio"
                            minCaracteresBusca={3}
                            field="descricao"
                            placeholder="Comece a digitar para buscar... (min 3 caracteres)"
                            urlSearch={urlServico}
                            disabled={!!!formik.values.municipio}
                            value={formik.values.servico_municipio}
                            onChange={formik.handleChange}
                            className={classNames({ "p-invalid": formik.errors.servico_municipio })}
                        />
                        {formik.errors.servico_municipio && (
                            <small className="p-error">{formik.errors.servico_municipio}</small>
                        )}
                    </div>
                </div>

                <div className="p-fluid p-formgrid p-grid p-mb-5">
                    <div className="p-field p-col-12 p-md-2">
                        <Label htmlFor="percentual_comissao" label="% comissao" />
                        <MakoInputPercent
                            id="percentual_comissao"
                            name="percentual_comissao"
                            onValueChange={formik.handleChange}
                            value={formik.values.percentual_comissao}
                            className={classNames({ "p-invalid": formik.errors.percentual_comissao })}
                        />
                        {formik.errors.percentual_comissao && (
                            <small className="p-error">{formik.errors.percentual_comissao}</small>
                        )}
                    </div>
                    <div className="p-field p-col-12 p-md-5">
                        <Label htmlFor="operacao_fiscal" label="Operação fiscal do serviço" obrigatorio />
                        <Dropdown
                            id="operacao_fiscal"
                            name="operacao_fiscal"
                            url={`/servicos/operacoes-fiscais-servicos/?empresa=${empresaSelecionadaId}`}
                            buscar={!!empresaSelecionadaId}
                            optionLabel="descricao"
                            optionValue="id"
                            filter
                            showClear={false}
                            filterBy="descricao"
                            value={formik.values.operacao_fiscal}
                            onChange={formik.handleChange}
                            className={classNames({
                                "p-invalid": formik.errors.operacao_fiscal,
                            })}
                        />
                        {formik.errors.operacao_fiscal && (
                            <small className="p-error">{formik.errors.operacao_fiscal}</small>
                        )}
                    </div>
                </div>
                <MakoActionsButtons>
                    <Button
                        icon={!!!formik.values.id ? MAKO_ICONS.NOVO : MAKO_ICONS.GRAVAR}
                        label={!!!formik.values.id ? "Incluir" : "Salvar"}
                        type="submit"
                        className="p-button-info"
                        loading={loading}
                    />
                    <Button
                        icon={MAKO_ICONS.LIMPAR_FORM}
                        label="Limpar"
                        type="button"
                        className="p-button-warning"
                        onClick={formik.resetForm}
                        loading={loading}
                    />
                    <Button
                        label="Cancelar"
                        type="button"
                        icon={MAKO_ICONS.CANCEL}
                        className="p-button-danger"
                        onClick={onClose}
                        loading={loading}
                    />
                </MakoActionsButtons>
            </form>
        </>
    );
};
