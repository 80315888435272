import React, { useMemo } from "react";

import { useHistory, useLocation } from "react-router-dom";

import { Form } from "../features";
import { PageBase } from "@/components/PageBase";

export const AdministradoraCartaoForm = () => {
    const { state } = useLocation();
    const history = useHistory();

    const { administradora } = state || {};

    const onSuccess = () => history.push("/financeiro/cadastros/administradora-cartao/");
    const onCancel = () => history.push("/financeiro/cadastros/administradora-cartao/");

    const header = useMemo(() => {
        if (!administradora) return <h5>Cadastro administradora de cartão</h5>;
        return <h5>Manutenção administradora de cartão</h5>;
    }, [administradora]);

    return (
        <PageBase>
            {header}
            <Form administradora={administradora} onSuccessCallback={onSuccess} onCancelCallback={onCancel} />
        </PageBase>
    );
};
