import { ReceberEntrada } from "./receber-entrada";
import { ReceberPix } from "./receber-pix";
import { ReceberCartaoCredito } from "./receber-cartao-credito";
import { ReceberCartaoDebito } from "./receber-cartao-debito";
import { ReceberDinheiro } from "./receber-dinheiro";
import { ReceberCrediario } from "./receber-crediario";
import { ReceberCartaoModo } from "./receber-cartao-modo";

export const Forms = {
    ReceberEntrada,
    ReceberPix,
    ReceberCartaoCredito,
    ReceberCartaoDebito,
    ReceberDinheiro,
    ReceberCrediario,
    ReceberCartaoModo,
};
