import React, { useEffect } from "react";

import { useFormik } from "formik";
import classNames from "classnames";
import * as Yup from "yup";

import { MakoButton as Button } from "@/components/MakoButton";
import { CamposObrigatorios } from "@/components/CamposObrigatorios";
import { MakoActionsButtons } from "@/components/MakoActionsButtons";
import { MakoInputPercent } from "@/components/MakoInputs";
import { MakoCalendar } from "@/components/MakoCalendar";
import { Dropdown } from "@/components/Dropdown";
import { Label } from "@/components/Label";

import { SelectButton } from "primereact/selectbutton";
import { InputNumber } from "primereact/inputnumber";

import { TIPO_CARTAO_CHOICE } from "@/assets/constants/financeiro";
import { SIM_NAO_BOOLEAN } from "@/assets/constants/constants";
import { MAKO_ICONS } from "@/assets/constants/constants_styles";
import { dataToStr } from "@/assets/util/datas";

import useLoadingLocal from "@/hooks/useLoadingLocal";
import useToast from "@/hooks/useToast";
import useHttp from "@/hooks/useHttp";

const BASE_URL = "/financeiro/taxas-administracao-cartao/";
const INITIAL_VALUES = {
    id: null,
    tipo_cartao: null,
    minimo_parcela: 1,
    maximo_parcela: 1,
    taxa: 0,
    inicio_vigencia: new Date(),
    final_vigencia: null,
    ativo: true,
    conta_bancaria: null,
    bandeira: null,
    administradora: null,
};

export const FormTaxaBancaria = ({ conta, taxa, onSuccess = () => {} }) => {
    const [loading, showLoading, hideLoading] = useLoadingLocal();
    const { showSuccess } = useToast();
    const { httpPost, httpPut } = useHttp();

    const { setValues, resetForm, ...formik } = useFormik({
        initialValues: { ...INITIAL_VALUES, conta_bancaria: conta?.id },
        onSubmit: handleSubmit,
    });

    async function handleSubmit(values) {
        try {
            const formSchema = Yup.object().shape({
                bandeira: Yup.number().required("O campo 'bandeira' é obrigatório."),
                administradora: Yup.number().required("O campo 'administradora' é obrigatório."),
                minimo_parcela: Yup.number().required("O campo 'minimo parcela' é obrigatório."),
                maximo_parcela: Yup.number().required("O campo 'maximo parcela' é obrigatório."),
                tipo_cartao: Yup.string()
                    .required("O campo 'tipo de cartão' é obrigatório.")
                    .typeError("Informe um tipo válido."),
                taxa: Yup.string().required("O campo 'taxa' é obrigatório."),
                inicio_vigencia: Yup.date().required("O campo 'inicio vigência' é obrigatório."),
            });
            await formSchema.validate(values, { abortEarly: false });
            if (!values.id) {
                const handlers = {
                    201: () => {
                        resetForm();
                        onSuccess();
                        showSuccess({
                            summary: "Sucesso",
                            detail: "Conta bancária cadastrada com sucesso!",
                            life: 1500,
                        });
                    },
                };
                showLoading();
                await httpPost(
                    {
                        url: BASE_URL,
                        body: {
                            ...values,
                            inicio_vigencia: dataToStr(values.inicio_vigencia, "yyyy-MM-dd"),
                            final_vigencia: dataToStr(values.final_vigencia, "yyyy-MM-dd"),
                        },
                    },
                    handlers
                );
                hideLoading();
            } else {
                const handlers = {
                    200: () => {
                        resetForm();
                        onSuccess();
                        showSuccess({
                            summary: "Sucesso",
                            detail: "Conta bancária alterada com sucesso!",
                            life: 1500,
                        });
                    },
                };
                showLoading();
                await httpPut({ url: `${BASE_URL}${values.id}/`, body: { ...values } }, handlers);
                hideLoading();
            }
        } catch (error) {
            if (error instanceof Yup.ValidationError) {
                let errorMessages = {};
                error.inner.forEach((err) => {
                    errorMessages[err.path] = err.message;
                });
                formik.setErrors(errorMessages);
            }
        }
    }

    useEffect(() => {
        if (taxa) setValues(taxa);
    }, [setValues, taxa]);

    return (
        <form onSubmit={formik.handleSubmit}>
            <div className="p-fluid p-formgrid p-grid">
                <div className="p-field p-col-12 p-md-4">
                    <Label htmlFor="tipo_cartao" label="Tipo de cartão" obrigatorio />
                    <Dropdown
                        id="tipo_cartao"
                        name="tipo_cartao"
                        optionValue="value"
                        optionLabel="label"
                        options={TIPO_CARTAO_CHOICE}
                        showClear={false}
                        value={formik.values.tipo_cartao}
                        onChange={formik.handleChange}
                        className={classNames({ "p-invalid": formik.errors.tipo_cartao })}
                    />
                    {formik.errors.tipo_cartao && <small className="p-error">{formik.errors.tipo_cartao}</small>}
                </div>
                <div className="p-field p-col-12 p-md-4">
                    <Label htmlFor="administradora" label="Administradora" obrigatorio />
                    <Dropdown
                        id="administradora"
                        name="administradora"
                        url="/financeiro/administradora-cartao?limit=300"
                        optionValue="id"
                        optionLabel="nome"
                        value={formik.values.administradora}
                        showClear={false}
                        onChange={formik.handleChange}
                        className={classNames({ "p-invalid": formik.errors.administradora })}
                    />
                    {formik.errors.administradora && <small className="p-error">{formik.errors.bandeira}</small>}
                </div>
                <div className="p-field p-col-12 p-md-4">
                    <Label htmlFor="bandeira" label="Bandeira" obrigatorio />
                    <Dropdown
                        id="bandeira"
                        name="bandeira"
                        url="/financeiro/bandeiras-cartao?limit=300"
                        optionValue="id"
                        optionLabel="nome"
                        value={formik.values.bandeira}
                        showClear={false}
                        onChange={formik.handleChange}
                        className={classNames({ "p-invalid": formik.errors.bandeira })}
                    />
                    {formik.errors.bandeira && <small className="p-error">{formik.errors.bandeira}</small>}
                </div>
            </div>
            <div className="p-fluid p-formgrid p-grid">
                <div className="p-field p-col-12 p-md-2">
                    <Label htmlFor="minimo_parcela" label="Min. parcela" obrigatorio />
                    <InputNumber
                        id="minimo_parcela"
                        name="minimo_parcela"
                        value={formik.values.minimo_parcela}
                        min={0}
                        onValueChange={formik.handleChange}
                        showButtons
                        className={classNames({ "p-invalid": formik.errors.minimo_parcela })}
                    />
                    {formik.errors.minimo_parcela && <small className="p-error">{formik.errors.minimo_parcela}</small>}
                </div>
                <div className="p-field p-col-12 p-md-2">
                    <Label htmlFor="maximo_parcela" label="Máx. parcela" obrigatorio />
                    <InputNumber
                        id="maximo_parcela"
                        name="maximo_parcela"
                        value={formik.values.maximo_parcela}
                        onValueChange={formik.handleChange}
                        showButtons
                        min={0}
                        className={classNames({ "p-invalid": formik.errors.maximo_parcela })}
                    />
                    {formik.errors.maximo_parcela && <small className="p-error">{formik.errors.maximo_parcela}</small>}
                </div>
                <div className="p-field p-col-12 p-md-2">
                    <Label htmlFor="taxa" label="% Taxa" obrigatorio />
                    <MakoInputPercent
                        id="taxa"
                        name="taxa"
                        onValueChange={formik.handleChange}
                        value={formik.values.taxa}
                        className={classNames({ "p-invalid": formik.errors.taxa })}
                    />
                    {formik.errors.taxa && <small className="p-error">{formik.errors.taxa}</small>}
                </div>
                <div className="p-field p-col-12 p-md-2">
                    <Label htmlFor="inicio_vigencia" label="Início da vigência" obrigatorio />
                    <MakoCalendar
                        id="inicio_vigencia"
                        name="inicio_vigencia"
                        valueCalendar={formik.values.inicio_vigencia}
                        onChange={formik.handleChange}
                        className={classNames({ "p-invalid": formik.errors.inicio_vigencia })}
                    />
                    {formik.errors.inicio_vigencia && (
                        <small className="p-error">{formik.errors.inicio_vigencia}</small>
                    )}
                </div>
                <div className="p-field p-col-12 p-md-2">
                    <Label htmlFor="final_vigencia" label="Final da vigência" />
                    <MakoCalendar
                        id="final_vigencia"
                        name="final_vigencia"
                        valueCalendar={formik.values.final_vigencia}
                        onChange={formik.handleChange}
                        className={classNames({ "p-invalid": formik.errors.final_vigencia })}
                    />
                    {formik.errors.final_vigencia && <small className="p-error">{formik.errors.final_vigencia}</small>}
                </div>
                <div className="p-field p-col-12 p-md-2">
                    <Label htmlFor="ativo" label="Ativo" />
                    <SelectButton
                        id="ativo"
                        name="ativo"
                        value={formik.values.ativo}
                        onChange={formik.handleChange}
                        options={SIM_NAO_BOOLEAN}
                        optionLabel="label"
                        optionValue="id"
                    />
                </div>
            </div>
            <CamposObrigatorios />
            <MakoActionsButtons>
                <Button
                    label="Gravar"
                    icon={MAKO_ICONS.GRAVAR}
                    type="submit"
                    loading={loading}
                    className="p-button-info"
                />
            </MakoActionsButtons>
        </form>
    );
};
