import React, { useCallback, useEffect, useRef, useState } from "react";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { Dropdown } from "primereact/dropdown";
import { InputText } from "primereact/inputtext";
import MakoListagem from "@/components/MakoListagem";
import { MAKO_ICONS } from "@/assets/constants/constants_styles";
import useHttp from "@/hooks/useHttp";

export const MakoInputNcm = ({
    fieldValue,
    fieldName = "ncm",
    valorNcm,
    onChange,
    botaoDesativado,
    showClear = false,
}) => {
    const [busca, setBusca] = useState(1);
    const [pesquisa, setPesquisa] = useState("");
    const [ncm, setNcm] = useState(null);
    const [descricaoNcm, setDescricaoNcm] = useState("");
    const [ordena, setOrdena] = useState("gte");
    const [dialog, setDialog] = useState(false);
    const [exibirItens, setExibirItens] = useState(false);
    const [dados, setDados] = useState([]);
    const [itensPesquisa, setItensPesquisa] = useState([]);
    const listagemRef = useRef(null);
    const { httpGet } = useHttp();

    const pesquisarNcm = async () => {
        const handlers = {
            200: ({ data }) => {
                setDados(data.results);
            },
        };

        await httpGet(
            {
                url:
                    busca === 1
                        ? `/fiscal/ncm?codigo${
                              ordena !== "exact" ? `__${ordena}` : ""
                          }=${pesquisa}&limit=100&ordering=codigo&ativo=true`
                        : `/fiscal/ncm?descricao${
                              ordena !== "exact" ? `__${ordena}` : ""
                          }=${pesquisa}&limit=100&ordering=codigo&ativo=true`,
            },
            handlers
        );
    };

    const pesquisarItens = async (ncmId) => {
        const handlers = {
            200: ({ data }) => {
                setItensPesquisa(data.itens);
                setExibirItens(true);
            },
        };

        await httpGet(
            {
                url: `/fiscal/buscar-itens-ncm/${ncmId}`,
            },
            handlers
        );
    };

    const esconderDialog = () => {
        setDialog(false);
        if (!valorNcm) {
            setDados([]);
            setPesquisa("");
            setBusca(1);
            setNcm(null);
            setDescricaoNcm("");
        }
    };

    const limparPesquisa = () => {
        setDados([]);
        setPesquisa("");
        setBusca(1);
        if (!valorNcm) {
            setNcm(null);
            setDescricaoNcm("");
        }
    };

    const confirmaNcm = () => {
        fieldValue(fieldName, ncm);
        setDialog(false);
        if (onChange) onChange();
    };

    const verificaHierarquia = (codigo) => {
        if (codigo.length === 2)
            return (
                <h5>
                    <b>{codigo}</b>
                </h5>
            );
        if (codigo.length === 4)
            return (
                <h6>
                    <b>{codigo}</b>
                </h6>
            );
        return codigo;
    };

    const actionBodyTemplate = (rowData) => {
        return (
            <div className="actions">
                <Button
                    type="button"
                    icon="pi pi-search"
                    className="p-button-rounded p-button-help p-mr-2 p-mb-1"
                    tooltip="Visualizar itens vinculados ao NCM"
                    tooltipOptions={{ position: "left" }}
                    onClick={(e) => pesquisarItens(rowData.id)}
                />
            </div>
        );
    };

    const limparSelecao = (e) => {
        setNcm(null);
        setDescricaoNcm("");
        fieldValue(fieldName, ncm);
        if (onChange) onChange(e);
    };

    const colunas = [
        {
            field: "codigo",
            header: "Código",
            action: (e) => verificaHierarquia(e.codigo),
        },
        {
            field: "descricao",
            header: "Descrição",
            style: { minWidth: "60%" },
        },
        {
            field: "vigencia_inicial",
            header: "Vigência Inicial",
            dateFormat: "dd/MM/yyyy",
        },
        {
            field: "action",
            header: "Ações",
            action: (e) => actionBodyTemplate(e),
            style: { minWidth: "10%" },
        },
    ];

    const colunaItens = [
        {
            field: "codigo",
            header: "Código",
        },
        {
            field: "descricao_reduzida",
            header: "Descrição Reduzida",
            style: { minWidth: "60%" },
        },
        {
            field: "unidade_padrao.nome",
            header: "Unidade Padrão",
        },
    ];

    const carregaNcm = useCallback(
        async (valor) => {
            const handlers = {
                200: ({ data }) => {
                    setDescricaoNcm(`${valor.codigo} - ${data.ncm_descricao}`);
                },
            };

            await httpGet(
                {
                    url: `/fiscal/ncm-descricao-cumulativa/${valor.codigo}/`,
                },
                handlers
            );
        },
        [httpGet]
    );

    useEffect(() => {
        if (valorNcm) {
            carregaNcm(valorNcm);
            setNcm(valorNcm);
        }
    }, [valorNcm, carregaNcm]);

    return (
        <>
            <div className="p-inputgroup">
                <InputText
                    id={fieldName}
                    name={fieldName}
                    placeholder="Clique no botão para selecionar um NCM"
                    value={descricaoNcm}
                    tooltip={descricaoNcm}
                    tooltipOptions={{ position: "mouse" }}
                />
                {showClear && (
                    <Button
                        type="button"
                        icon={MAKO_ICONS.LIMPAR_INPUT}
                        onClick={(e) => limparSelecao(e)}
                        className="p-button-secondary"
                        disabled={botaoDesativado}
                    />
                )}
                <Button
                    icon={MAKO_ICONS.FILTRAR}
                    type="button"
                    tooltip="Selecionar NCM"
                    tooltipOptions={{ position: "bottom" }}
                    onClick={() => setDialog(true)}
                    disabled={botaoDesativado}
                    className="p-button-info"
                />
            </div>
            <Dialog
                header="Selecionar NCM"
                visible={dialog}
                breakpoints={{ "960px": "75vw" }}
                style={{ width: "75vw", display: "block" }}
                onHide={() => esconderDialog()}
            >
                <div className="p-col-12">
                    <form>
                        <div className="p-fluid p-formgrid p-grid">
                            <div className="p-field p-col-12 p-md-3">
                                <label htmlFor="busca">Buscar por: </label>
                                <Dropdown
                                    id="busca"
                                    name="busca"
                                    placeholder="Selecione"
                                    options={[
                                        { value: 1, label: "Código" },
                                        { value: 2, label: "Descrição" },
                                    ]}
                                    onChange={(e) => setBusca(e.value)}
                                    value={busca}
                                />
                            </div>
                            <div className="p-field p-col-12 p-md-3">
                                <label htmlFor="ordena">Ordernação </label>
                                <Dropdown
                                    id="ordena"
                                    name="ordena"
                                    placeholder="Selecione"
                                    options={[
                                        { value: "gte", label: "Começa com" },
                                        { value: "unaccent__icontains", label: "Contém" },
                                        { value: "exact", label: "Igual" },
                                    ]}
                                    onChange={(e) => setOrdena(e.value)}
                                    value={ordena}
                                />
                            </div>
                            <div className="p-field p-col-12 p-md-4">
                                <label htmlFor="busca">Digite seu critério de pesquisa</label>
                                <InputText
                                    id="pesquisa"
                                    name="pesquisa"
                                    value={pesquisa}
                                    onChange={(e) => setPesquisa(e.target.value)}
                                    disabled={!busca}
                                />
                            </div>
                            <Button
                                icon={MAKO_ICONS.PESQUISAR}
                                type="button"
                                label="Pesquisar"
                                onClick={() => pesquisarNcm()}
                                className="p-button-info p-mt-5 p-ml-2"
                                style={{ width: "110px", height: "35px" }}
                                disabled={!pesquisa}
                            />
                        </div>
                    </form>
                    <MakoListagem
                        ref={listagemRef}
                        colunas={colunas}
                        dadosLocal={dados}
                        configTabela={{
                            scrollable: true,
                            scrollHeight: "400px",
                            selectionMode: "single",
                            selection: ncm,
                            onSelectionChange: (e) => setNcm(e.value),
                        }}
                    />
                    <div className="p-grid p-justify-end p-mr-2 p-mt-4">
                        <Button
                            type="button"
                            onClick={() => confirmaNcm()}
                            icon={MAKO_ICONS.CONFIRMAR}
                            label="Confirmar"
                            disabled={!ncm}
                            className="p-mr-2"
                        />
                        <Button
                            type="reset"
                            onClick={() => limparPesquisa()}
                            icon={MAKO_ICONS.LIMPAR_FORM}
                            label="Limpar"
                            className="p-button-warning p-mr-2"
                        />
                        <Button
                            className="p-button-danger p-mr-2"
                            type="button"
                            icon={MAKO_ICONS.CANCEL}
                            label="Fechar"
                            onClick={() => {
                                !valorNcm ? esconderDialog() : setDialog(false);
                            }}
                        />
                    </div>
                </div>
            </Dialog>
            <Dialog
                header="Itens relacionados ao NCM:"
                visible={exibirItens}
                breakpoints={{ "960px": "75vw" }}
                style={{ width: "60vw", display: "block" }}
                onHide={() => setExibirItens(false)}
            >
                <MakoListagem
                    ref={listagemRef}
                    colunas={colunaItens}
                    dadosLocal={itensPesquisa}
                    configTabela={{ scrollable: true, scrollHeight: "500px" }}
                />
            </Dialog>
        </>
    );
};
