import permissoes from "./permissoes";

const menu = [
    {
        label: "Principal",
        icon: "pi pi-fw pi-home",
        items: [{ label: "Dashboard", icon: "pi pi-fw pi-home", to: "/", permissions: [permissoes.DASHBOARD] }],
    },
    { separator: true },
    {
        label: "Gestão",
        icon: "pi pi-fw pi-user",
        items: [
            {
                label: "Pessoas",
                icon: "pi pi-fw pi-users",
                to: "/gestao/pessoas/pessoas",
                permissions: [permissoes.GESTAO_PESSOAS_PESSOAS_CONSULTAR],
            },
            {
                label: "Usuários",
                icon: "pi pi-fw pi-user",
                items: [
                    {
                        label: "Papéis Perfis",
                        icon: "pi pi-fw pi-user-edit",
                        to: "/gestao/usuarios/papeis-perfis",
                        permissions: [permissoes.GESTAO_USUARIOS_PAPEISPERFIS_CONSULTAR],
                    },
                    {
                        label: "Gerenciar Usuários",
                        icon: "pi pi-fw pi-user-plus",
                        to: "/gestao/usuarios/gerenciar-usuarios",
                        permissions: [permissoes.GESTAO_USUARIOS_GERENCIARUSUARIO_CONSULTAR],
                    },
                    {
                        label: "Tokens",
                        icon: "fas pi-fw fa-key",
                        to: "/gestao/usuarios/tokens",
                        permissions: [
                            permissoes.GESTAO_USUARIOS_TOKENINTEGRACAO_CONSULTAR,
                            permissoes.GESTAO_USUARIOS_TOKENINTEGRACAO_CONSULTAR_TERCEIRO,
                        ],
                    },
                ],
            },
            {
                label: "Entidades Gerenciadas",
                icon: "pi pi-fw pi-building",
                to: "/gestao/empresas/vinculacao-pessoas",
                permissions: [permissoes.GESTAO_EMPRESAS_VINCULACAO_CONSULTAR],
            },
            {
                label: "Cadastros",
                icon: "pi pi-fw pi-align-justify",
                items: [
                    {
                        label: "Cargos",
                        icon: "pi pi-fw pi-sitemap",
                        to: "/gestao/cadastros/cargos",
                        permissions: [permissoes.GESTAO_CADASTRO_CARGOS_CONSULTAR],
                    },
                    {
                        label: "Hierarquia Funcional",
                        icon: "pi pi-fw pi-home",
                        to: "/gestao/cadastros/hierarquia-funcional",
                        permissions: [permissoes.GESTAO_CADASTRO_HIERARQUIAFUNCIONAL_CONSULTAR],
                    },
                    {
                        label: "Tags de Endereço",
                        icon: "pi pi-fw pi-home",
                        to: "/gestao/cadastros/tags-enderecos",
                        permissions: [permissoes.GESTAO_CADASTRO_TAGSENDERECO_CONSULTAR],
                    },
                    {
                        label: "Tags de Perfil",
                        icon: "pi pi-fw pi-tags",
                        to: "/gestao/cadastros/tags-perfis",
                        permissions: [permissoes.GESTAO_CADASTRO_TAGSPERFIL_CONSULTAR],
                    },
                    {
                        label: "Redes Sociais",
                        icon: "pi pi-fw pi-at",
                        to: "/gestao/cadastros/redes-sociais",
                        permissions: [permissoes.GESTAO_CADASTRO_REDESOCIAL_CONSULTAR],
                    },
                    {
                        label: "Vinculo Representante",
                        icon: "pi pi-fw pi-arrows-h",
                        to: "/gestao/cadastros/vinculo-representante",
                        permissions: [permissoes.GESTAO_CADASTRO_REPRESENTANTEFORNECEDOR_VINCULO_CONSULTAR],
                    },
                    {
                        label: "Veículo de Entrega",
                        icon: "pi pi-fw pi-car",
                        to: "/gestao/cadastros/veiculo-entrega",
                        permissions: [permissoes.GESTAO_CADASTRO_VEICULOENTREGA_CONSULTAR],
                    },
                    {
                        label: "Vínculo Motorista Veículo",
                        icon: "pi pi-fw pi-arrows-v",
                        to: "/gestao/cadastros/vinculo-motorista-veiculo",
                        permissions: [permissoes.GESTAO_CADASTRO_VINCULOMOTORISTAVEICULO_CONSULTAR],
                    },
                ],
            },
            {
                label: "Relatórios",
                icon: "pi pi-fw pi-file-excel",
                items: [
                    {
                        label: "Relação de Pessoas ",
                        icon: "pi pi-fw pi-users",
                        to: "/gestao/relatorios/relacoes-pessoas",
                        permissions: [permissoes.RELATORIOS_EMITIR_PESSOAS_RELACAOPESSOAS],
                    },
                ],
            },
            {
                label: "Importar Pessoas",
                icon: "pi pi-fw pi-upload",
                to: "/gestao/pessoas/importar-pessoas",
                permissions: [permissoes.GESTAO_PESSOAS_IMPORTARPESSOAS_CONSULTAR],
            },
        ],
    },
    { separator: true },
    {
        label: "Produtos",
        icon: "pi pi-fw pi-box",
        items: [
            {
                label: "Produtos",
                icon: "fas pi-fw fa-boxes",
                items: [
                    {
                        label: "Produtos",
                        icon: "pi pi-fw pi-box",
                        to: "/produto/produto/produtos",
                        permissions: [permissoes.PRODUTO_PRODUTO_PRODUTOS_CONSULTAR],
                    },
                    {
                        label: "Grupos de Categorias",
                        icon: "pi pi-fw pi-th-large",
                        to: "/produto/produto/grupos-categorias",
                        permissions: [permissoes.PRODUTO_PRODUTO_GRUPOSCATEGORIAS_CONSULTAR],
                    },
                    {
                        label: "Categorias",
                        icon: "pi pi-fw pi-th-large",
                        to: "/produto/produto/categorias",
                        permissions: [permissoes.PRODUTO_PRODUTO_CATEGORIAS_CONSULTAR],
                    },
                    {
                        label: "Consultar Saldos",
                        icon: "pi pi-fw pi-chart-line",
                        to: "/produto/produto/consultar-saldos",
                        permissions: [permissoes.PRODUTO_PRODUTO_CONSULTARSALDOS],
                    },
                    {
                        label: "Lotes",
                        icon: "pi pi-fw pi-box",
                        to: "/produto/produto/lotes",
                        permissions: [permissoes.PRODUTO_PRODUTO_LOTES_CONSULTAR],
                    },
                    {
                        label: "Atualizar custo/preço",
                        icon: "pi pi-fw pi-dollar",
                        to: "/produto/produto/custo",
                        permissions: [permissoes.PRODUTO_PRODUTO_ATUALIZAR_CUSTO],
                    },
                ],
            },
            {
                label: "Cadastros",
                icon: "pi pi-fw pi-align-justify",
                items: [
                    {
                        label: "Atributos",
                        icon: "pi pi-fw pi-bookmark",
                        to: "/produto/cadastros/atributos",
                        permissions: [permissoes.PRODUTO_CADASTRO_ATRIBUTO_CONSULTAR],
                    },
                    {
                        label: "Descrição Dinâmica",
                        icon: "pi pi-fw pi-tags",
                        to: "/produto/cadastros/padrao-nome",
                        permissions: [permissoes.PRODUTO_CADASTRO_TEMPLATEDESCRICAOSKU_CONSULTAR],
                    },
                    {
                        label: "Grades Atributos",
                        icon: "pi pi-fw pi-th-large",
                        to: "/produto/cadastros/grade-atributos",
                        permissions: [permissoes.PRODUTO_CADASTRO_GRADEATRIBUTO_CONSULTAR],
                    },
                    {
                        label: "Galeria Img. de Produtos",
                        icon: "pi pi-fw pi-image",
                        to: "/produto/cadastros/galerias-imagens",
                        permissions: [permissoes.PRODUTO_CADASTRO_GALERIAIMAGEMSKU_CONSULTAR],
                    },
                    {
                        label: "Modelos",
                        icon: "pi pi-fw pi-table",
                        to: "/produto/cadastros/modelos",
                        permissions: [permissoes.PRODUTO_CADASTRO_MODELO_CONSULTAR],
                    },
                    {
                        label: "Marcas",
                        icon: "pi pi-fw pi-clone",
                        to: "/produto/cadastros/marcas",
                        permissions: [permissoes.PRODUTO_CADASTRO_MARCA_CONSULTAR],
                    },
                    {
                        label: "Marcas Fabricante",
                        icon: "pi pi-fw pi-clone",
                        to: "/produto/cadastros/marcas-fabricante",
                        permissions: [permissoes.PRODUTO_CADASTRO_MARCAFABRICANTE_CONSULTAR],
                    },
                    {
                        label: "Tags Img. Produto",
                        icon: "pi pi-fw pi-tag",
                        to: "/produto/cadastros/tags-imagens",
                        permissions: [permissoes.PRODUTO_CADASTRO_TAGSIMAGEMSKU_CONSULTAR],
                    },
                    {
                        label: "Tipos de Códigos do Produto",
                        icon: "pi pi-fw pi-tags",
                        to: "/produto/cadastros/tipos-codigos-sku",
                        permissions: [permissoes.PRODUTO_CADASTRO_TIPOCODIGOSKU_CONSULTAR],
                    },
                    {
                        label: "Unidade de Medida",
                        icon: "pi pi-fw pi-pause",
                        to: "/produto/produto/unidade-medida",
                        permissions: [permissoes.PRODUTO_PRODUTO_UNIDADEMEDIDA_CONSULTAR],
                    },
                    {
                        label: "Descrição Padronizada",
                        icon: "pi pi-fw pi-minus",
                        to: "/produto/cadastros/descricao-padrao",
                        permissions: [permissoes.PRODUTO_CADASTRO_DESCRICAOPADRAO_CONSULTAR],
                    },
                    {
                        label: "Vinculo Fornecedor",
                        icon: "pi pi-fw pi-arrows-h",
                        to: "/produto/cadastros/vinculo-fornecedor",
                        permissions: [permissoes.PRODUTO_CADASTRO_PRODUTOFORNECEDOR_VINCULARFORNECEDOR_CONSULTAR],
                    },
                    {
                        label: "Vinculo Centros Estocagem e Resultado",
                        icon: "pi pi-fw pi-arrows-h",
                        to: "/produto/cadastros/vinculo-estocagem-resultado",
                        permissions: [permissoes.PRODUTO_CADASTRO_CENTROESTOCAGEM_CENTRORESULTADO_CONSULTAR],
                    },
                ],
            },
            {
                label: "Relatórios",
                icon: "pi pi-fw pi-file-excel",
                items: [
                    {
                        label: "Inventário Produtos",
                        icon: "pi pi-fw pi-box",
                        to: "/produto/relatorios/inventario-produtos",
                        permissions: [permissoes.RELATORIOS_EMITIR_PRODUTOS_INVENTARIOPRODUTOS],
                    },
                    {
                        label: "Relação de Produtos",
                        icon: "pi pi-fw pi-box",
                        to: "/produto/relatorios/relacao-produtos",
                        permissions: [permissoes.RELATORIOS_EMITIR_PRODUTOS_RELACAOPRODUTOS],
                    },
                    {
                        label: "Movimentacao de Produtos",
                        icon: "pi pi-fw pi-box",
                        to: "/produto/relatorios/movimentacao-produtos",
                        permissions: [permissoes.RELATORIOS_EMITIR_PRODUTOS_MOVIMENTACAOPRODUTOS],
                    },
                    {
                        label: "Relação Pis Cofins",
                        icon: "pi pi-fw pi-arrows-h",
                        to: "/produto/relatorios/relacao-pis-cofins",
                        permissions: [permissoes.RELATORIOS_EMITIR_PRODUTOS_RELACAOPISCOFINS],
                    },
                    {
                        label: "Preços por fornecedor",
                        icon: "pi pi-fw pi-money-bill",
                        to: "/produto/relatorios/preco-por-fornecedor",
                        permissions: [permissoes.RELATORIOS_EMITIR_PRECO_POR_FORNECEDOR],
                    },
                    {
                        label: "Lotes",
                        icon: "pi pi-fw pi-box",
                        to: "/produto/relatorios/lotes",
                        permissions: [permissoes.RELATORIOS_EMITIR_PRODUTOS_LOTES],
                    },
                ],
            },
            {
                label: "Importar Produtos",
                icon: "pi pi-fw pi-upload",
                to: "/produto/produto/importar-produtos",
                permissions: [permissoes.PRODUTO_IMPORTARPRODUTOS_IMPORTAR],
            },
        ],
    },
    { separator: true },
    {
        label: "Compras",
        icon: "pi pi-fw pi-money-bill",
        items: [
            {
                label: "Ordem de Compra",
                icon: "fas pi-fw fa-clipboard-list",
                items: [
                    {
                        label: "Ordem de Compra",
                        icon: "fas pi-fw fa-clipboard-list",
                        permissions: [permissoes.COMPRAS_ORDEMCOMPRA_ORDEMCOMPRA_CONSULTAR],
                        to: "/compras/ordens-compra/ordens-compra",
                    },
                    {
                        label: "Categorias da OC",
                        icon: "pi pi-fw pi-tags",
                        permissions: [permissoes.COMPRAS_ORDEMCOMPRA_CATEGORIAS_CONSULTAR],
                        to: "/compras/ordens-compra/categorias-ordens-compra",
                    },
                ],
            },
            {
                label: "Entrada",
                icon: "pi pi-fw pi-upload",
                items: [
                    {
                        label: "Entrada",
                        icon: "pi pi-fw pi-upload",
                        to: "/compras/entrada/entrada-produtos",
                        permissions: [permissoes.PRODUTO_ENTRADA_ENTRADA_CONSULTAR],
                    },
                    {
                        label: "Devolução ao fornecedor",
                        icon: "pi pi-fw pi-undo",
                        to: "/compras/entrada/devolucao-fornecedor",
                        permissions: [permissoes.COMPRAS_ENTRADA_DEVOLUCAOFORNECEDOR_CONSULTAR],
                    },
                ],
            },
            {
                label: "Cadastros",
                icon: "pi pi-fw pi-align-justify",
                items: [
                    {
                        label: "Destinações de entrada",
                        icon: "pi pi-fw pi-bookmark",
                        to: "/compras/cadastros/destinacoes",
                        permissions: [permissoes.COMPRAS_CADASTRO_DESTINACAO_CONSULTAR],
                    },
                    {
                        label: "Tipos de entrada",
                        icon: "pi pi-fw pi-bookmark",
                        to: "/compras/cadastros/tipos-entrada",
                        permissions: [permissoes.COMPRAS_CADASTRO_TIPOENTRADA_CONSULTAR],
                    },
                    {
                        label: "Tipos de devolução",
                        icon: "pi pi-fw pi-arrow-left",
                        to: "/compras/cadastros/tipo-devolucao",
                        permissions: [permissoes.COMPRAS_CADASTRO_TIPODEVOLUCAO_CONSULTAR],
                    },
                    {
                        label: "Necessidade Compra",
                        icon: "fa pi-fw fa-boxes",
                        to: "/compras/cadastros/registro-necessidade-compra",
                        permissions: [permissoes.VENDAS_CADASTRO_REGISTRONECESSIDADECOMPRA_CONSULTAR],
                    },
                ],
            },
        ],
    },
    { separator: true },
    {
        label: "Vendas",
        icon: "pi pi-fw pi-shopping-cart",
        items: [
            {
                label: "Venda",
                icon: "pi pi-fw pi-shopping-cart",
                items: [
                    {
                        label: "Consultar/Realizar Venda",
                        icon: "pi pi-fw pi-shopping-cart",
                        to: "/vendas/venda",
                        permissions: [permissoes.VENDAS_VENDA_REALIZARVENDA_CONSULTAR],
                    },
                    {
                        label: "Controle de entregas",
                        icon: "pi pi-fw pi-truck",
                        to: "/vendas/venda/entregas",
                        permissions: [permissoes.VENDAS_VENDA_ENTREGAS_CONSULTAR],
                    },
                    {
                        label: "Entregas Realizadas",
                        icon: "pi pi-fw pi-truck",
                        to: "/vendas/venda/entregas-realizadas",
                        permissions: [permissoes.VENDAS_VENDA_ENTREGASREALIZADAS_CONSULTAR],
                    },
                    {
                        label: "Consultar Preços",
                        icon: "pi pi-fw pi-dollar",
                        to: "/vendas/cadastros/consulta-precos",
                        permissions: [permissoes.VENDAS_VENDA_CONSULTAPRECO_CONSULTAR],
                    },
                    {
                        label: "Devolução",
                        icon: "pi pi-fw pi-undo",
                        to: "/vendas/venda/devolucao",
                        permissions: [permissoes.VENDAS_VENDA_DEVOLUCAO_CONSULTAR],
                    },
                    {
                        label: "Trocas",
                        icon: "pi pi-fw pi-sync",
                        to: "/vendas/venda/trocas",
                        permissions: [permissoes.VENDAS_VENDA_TROCA_CONSULTAR],
                    },
                    {
                        label: "Orçamento Rápido",
                        icon: "pi pi-fw pi-shopping-bag",
                        to: "/vendas/venda/orcamento-rapido",
                        permissions: [permissoes.VENDAS_VENDA_ORCAMENTORAPIDO_CONSULTAR],
                    },
                    {
                        label: "Assistência técnica",
                        icon: "pi pi-fw pi-wrench",
                        to: "/vendas/assistencias-tecnicas",
                        permissions: [permissoes.VENDAS_VENDA_ASSISTENCIATECNICA_CONSULTAR],
                    },
                ],
            },
            {
                label: "Crediário",
                icon: "fa pi-fw fa-newspaper",
                items: [
                    {
                        label: "Cadastros",
                        icon: "pi pi-fw pi-list",
                        items: [
                            {
                                label: "Nome das relações",
                                icon: "fa pi-fw fa-heart",
                                to: "/vendas/venda/crediario/nomes-relacao",
                                permissions: [permissoes.VENDAS_CREDIARIO_NOMERELACAO_CONSULTAR],
                            },
                            {
                                label: "Ocupação",
                                icon: "pi pi-fw pi-wrench",
                                to: "/vendas/venda/crediario/ocupacao",
                                permissions: [permissoes.VENDAS_VENDA_CREDIARIOOCUPACAO_CONSULTAR],
                            },
                            {
                                label: "Regra de crediário",
                                icon: "pi pi-fw pi-align-justify",
                                to: "/vendas/venda/crediario/regra-crediario",
                                permissions: [permissoes.VENDAS_VENDA_CREDIARIO_CONSULTAR],
                            },
                            {
                                label: "Tipos de documentos",
                                icon: "pi pi-fw pi-book",
                                to: "/vendas/venda/crediario/tipos-documento-renda",
                                permissions: [permissoes.VENDAS_VENDA_TIPODOCUMENTORENDA_CONSULTAR],
                            },
                            {
                                label: "Tipo de renda",
                                icon: "pi pi-fw pi-dollar",
                                to: "/vendas/venda/crediario/tipos-renda",
                                permissions: [permissoes.VENDAS_CREDIARIO_TIPORENDA_CONSULTAR],
                            },
                        ],
                    },
                    {
                        label: "Crediário",
                        icon: "fa pi-fw fa-credit-card",
                        to: "/vendas/venda/crediario",
                        permissions: [permissoes.VENDAS_VENDA_CREDIARIO_CONSULTAR],
                    },
                ],
            },
            {
                label: "Comissão",
                icon: "pi pi-fw pi-dollar",
                items: [
                    {
                        label: "Apuração de comissão",
                        icon: "pi pi-fw pi-compass",
                        to: "/vendas/comissao/apuracao",
                        permissions: [permissoes.VENDAS_COMISSAO_APURACAOCOMISSAO_CONSULTAR],
                    },
                    {
                        label: "Ajuste de comissão",
                        icon: "pi pi-fw pi-pencil",
                        to: "/vendas/comissao/ajustes",
                        permissions: [permissoes.VENDAS_COMISSAO_AJUSTE_CONSULTA],
                    },
                ],
            },
            {
                label: "Cadastros",
                icon: "pi pi-fw pi-align-justify",
                items: [
                    {
                        label: "Fluxos de venda",
                        icon: "pi pi-fw pi-tags",
                        items: [
                            {
                                label: "Tipos de Estágios",
                                icon: "pi pi-fw pi-tag",
                                to: "/vendas/cadastros/tipos-estagios",
                                permissions: [permissoes.VENDAS_CADASTRO_TIPOESTAGIO_CONSULTAR],
                            },
                            {
                                label: "Gerenciar fluxo de venda",
                                icon: "pi pi-fw pi-link",
                                to: "/vendas/cadastros/estagios-vendas",
                                permissions: [permissoes.VENDAS_CADASTRO_ESTAGIOVENDA_CONSULTAR],
                            },
                        ],
                    },
                    {
                        label: "Assistência técnica",
                        icon: "pi pi-fw pi-wrench",
                        items: [
                            {
                                label: "Tipos de Ocorrência",
                                icon: "pi pi-fw pi-tag",
                                to: "/vendas/cadastros/assistencia-tecnica/tipos-ocorrencia",
                                permissions: [permissoes.VENDAS_CADASTRO_TIPOOCORRENCIAASSITTECNICA_CONSULTAR],
                            },
                        ],
                    },
                    {
                        label: "Comissão de Vendas",
                        icon: "pi pi-fw pi-money-bill",
                        to: "/vendas/cadastros/comissao-vendas",
                        permissions: [permissoes.VENDAS_CADASTRO_COMISSAOVENDAS_CONSULTAR],
                    },
                    {
                        label: "Formação de Preços",
                        icon: "pi pi-fw pi-money-bill",
                        to: "/vendas/cadastros/formacao-precos",
                        permissions: [permissoes.VENDAS_CADASTRO_FORMACAOPRECO_CONSULTAR],
                    },
                    {
                        label: "Arredondamento de Preços",
                        icon: "pi pi-fw pi-money-bill",
                        to: "/vendas/cadastros/arredondamento-valor",
                        permissions: [permissoes.VENDAS_CADASTRO_ARREDONDAMENTOVALOR_CONSULTAR],
                    },
                    {
                        label: "Faixa de Comissão",
                        icon: "pi pi-fw pi-sliders-h",
                        to: "/vendas/cadastros/faixa-comissao",
                        permissions: [permissoes.VENDAS_CADASTRO_FAIXACOMISSAO_CONSULTAR],
                    },
                    {
                        label: "Serviços",
                        icon: "pi pi-fw pi-calendar",
                        to: "/vendas/cadastros/servicos",
                        permissions: [permissoes.VENDAS_CADASTRO_SERVICO_CONSULTAR],
                    },
                    {
                        label: "Carteira de Clientes",
                        icon: "pi pi-fw pi-wallet",
                        to: "/vendas/cadastros/carteira-clientes",
                        permissions: [permissoes.VENDAS_CADASTRO_CARTEIRACLIENTE_CONSULTAR],
                    },
                    {
                        label: "Motivos de Devolução",
                        icon: "pi pi-fw pi-sync",
                        to: "/vendas/cadastros/motivo-devolucao",
                        permissions: [permissoes.VENDAS_CADASTRO_ROTAENTREGA_CONSULTAR],
                    },
                    {
                        label: "Rotas de Entrega",
                        icon: "pi pi-fw pi-car",
                        to: "/vendas/cadastros/rota-entrega",
                        permissions: [permissoes.VENDAS_CADASTRO_ROTAENTREGA_CONSULTAR],
                    },
                    {
                        label: "Regras de Apuração",
                        icon: "pi pi-fw pi-stop",
                        to: "/vendas/cadastros/regra-apuracao-comissao",
                        permissions: [permissoes.VENDAS_CADASTRO_REGRAAPURACAO_CONSULTAR],
                    },
                    {
                        label: "Tabela de Preços",
                        icon: "pi pi-fw pi-dollar",
                        to: "/vendas/cadastros/tabela-precos",
                        permissions: [permissoes.VENDAS_CADASTRO_TABELAPRECO_CONSULTAR],
                    },
                    {
                        label: "Vigência de Preço",
                        icon: "pi pi-fw pi-calendar-times",
                        to: "/vendas/cadastros/vigencia-preco",
                        permissions: [permissoes.PRODUTO_CADASTRO_VIGENCIAPRECO_CONSULTAR],
                    },
                    {
                        label: "Voucher",
                        icon: "pi pi-fw pi-ticket",
                        to: "/vendas/cadastros/voucher",
                        permissions: [permissoes.VENDAS_CADASTRO_VOUCHER_CONSULTAR],
                    },
                ],
            },
            {
                label: "Consultas SPC",
                icon: "pi pi-fw pi-search",
                to: "/vendas/venda/consultas-spc",
                permissions: [permissoes.VENDAS_VENDA_CONSULTASPC_CONSULTAR],
            },
            {
                label: "Relatórios",
                icon: "pi pi-fw pi-file-excel",
                items: [
                    {
                        label: "Vendas",
                        icon: "fas pi-fw fa-boxes",
                        to: "/vendas/relatorios/vendas-faturadas",
                        permissions: [permissoes.RELATORIOS_EMITIR_VENDAS_TABELAPRECOSVENDAS],
                    },
                    {
                        label: "Curva ABC",
                        icon: "pi pi-fw pi-chart-line",
                        to: "/vendas/relatorios/curva-abc",
                        visible: false,
                        permissions: [permissoes.RELATORIOS_EMITIR_VENDAS_TABELAPRECOSVENDAS],
                    },
                    {
                        label: "Comissão analítica",
                        icon: "pi pi-fw pi-money-bill",
                        to: "/vendas/relatorios/extrato-comissao-analitco",
                        permissions: [permissoes.RELATORIOS_EMITIR_VENDAS_EXTRATOCOMISSAOANALITCO],
                    },
                    {
                        label: "Comissão sintético",
                        icon: "pi pi-fw pi-money-bill",
                        to: "/vendas/relatorios/extrato-comissao-sintetico",
                        permissions: [permissoes.RELATORIOS_EMITIR_VENDAS_EXTRATOCOMISSAOSINTETICO],
                    },
                    {
                        label: "Histórico simples cliente",
                        icon: "pi pi-fw pi-money-bill",
                        to: "/vendas/relatorios/historico-simples-cliente",
                        permissions: [permissoes.RELATORIOS_EMITIR_VENDAS_HISTORICOSIMPLESCLIENTE],
                    },
                    {
                        label: "Listagem simples",
                        icon: "fas pi-fw fa-boxes",
                        to: "/vendas/relatorios/listagem-simples",
                        permissions: [permissoes.RELATORIOS_EMITIR_VENDAS_LISTAGEMSIMPLES],
                    },
                    {
                        label: "Tabela de Preços",
                        icon: "pi pi-fw pi-dollar",
                        to: "/vendas/relatorios/tabela-precos",
                        permissions: [permissoes.RELATORIOS_EMITIR_TABELA_PRECOS],
                    },
                    {
                        label: "Para entregar",
                        icon: "pi pi-fw pi-car",
                        to: "/vendas/relatorios/entrega",
                        permissions: [permissoes.RELATORIOS_EMITIR_VENDAS_ENTREGAS],
                    },
                    {
                        label: "Entregas realizadas",
                        icon: "pi pi-fw pi-car",
                        to: "/vendas/relatorios/entregas-realizadas",
                        permissions: [permissoes.RELATORIOS_EMITIR_VENDAS_ENTREGAS_REALIZADAS],
                    },
                    {
                        label: "Produtos vendidos",
                        icon: "fas pi-fw fa-boxes",
                        to: "/vendas/relatorios/tabela-produtos",
                        permissions: [permissoes.RELATORIOS_EMITIR_VENDAS_PRODUTOSVENDIDOS],
                    },
                    {
                        label: "Itens faturados",
                        icon: "pi pi-fw pi-dollar",
                        to: "/vendas/relatorios/itens-faturados-vendas",
                        permissions: [permissoes.RELATORIOS_EMITIR_VENDAS_ITENSFATURADOSVENDA],
                    },
                ],
            },
        ],
    },
    { separator: true },
    {
        label: "Estoque",
        icon: "fas pi-fw fa-boxes",
        items: [
            {
                label: "Cadastros",
                icon: "pi pi-fw pi-align-justify",
                items: [
                    {
                        label: "Tipo de Movimentação",
                        icon: "pi pi-fw pi-ticket",
                        to: "/estoque/cadastros/tipos-movimentacoes",
                        permissions: [permissoes.ESTOQUE_MOVIMENTACOES_TIPOMOVIMENTACAO_CONSULTAR],
                    },
                    {
                        label: "Estoques",
                        icon: "pi pi-fw pi-table",
                        to: "/estoque/cadastros/estoques-empresas",
                        permissions: [permissoes.ESTOQUE_CADASTRO_ESTOQUES_CONSULTAR],
                    },
                    {
                        label: "Centro de Estocagem",
                        icon: "pi pi-fw pi-table",
                        to: "/estoque/cadastros/centro-estocagem",
                        permissions: [permissoes.ESTOQUE_CADASTRO_CENTROESTOCAGEM_CONSULTAR],
                    },
                    {
                        label: "Competência de Estoque",
                        icon: "pi pi-fw pi-sort-alt",
                        to: "/estoque/cadastros/competencias-movimentacoes-estoque",
                        className: "rotated-icon",
                        permissions: [permissoes.ESTOQUE_CADASTRO_COMPMOVESTOQUE_CONSULTAR],
                    },
                    {
                        label: "Carga",
                        icon: "pi pi-fw pi-box",
                        to: "/estoque/cadastros/carga",
                        className: "rotated-icon",
                        permissions: [permissoes.ESTOQUE_CADASTRO_CARGA_CONSULTAR],
                    },
                    {
                        label: "Tipo de Transferência",
                        icon: "pi pi-fw pi-sync",
                        to: "/estoque/cadastros/tipo-transferencia",
                        className: "rotated-icon",
                        permissions: [permissoes.ESTOQUE_CADASTRO_TIPOTRANSFERENCIA_CONSULTAR],
                    },
                    {
                        label: "Formula estoque saldo",
                        icon: "pi pi-fw pi-th-large",
                        to: "/estoque/cadastros/saldo-estoque-formula",
                        permissions: [permissoes.ESTOQUE_CADASTRO_SALDOESTOQUEFORMULA_CONSULTAR],
                    },
                ],
            },
            {
                label: "Movimentações",
                icon: "pi pi-fw pi-circle-off",
                items: [
                    {
                        label: "Acerto de Estoque",
                        icon: "pi pi-fw pi-tags",
                        to: "/estoque/movimentacoes/acerto-estoque",
                        permissions: [permissoes.ESTOQUE_MOVIMENTACOES_SALDOINICIAL_CONSULTAR],
                    },
                    {
                        label: "Consultar Movimentações",
                        icon: "pi pi-fw pi-search",
                        to: "/estoque/movimentacoes/consultar",
                        permissions: [permissoes.ESTOQUE_MOVIMENTACOES_CONSULTARMOVIMENTACOES_CONSULTAR],
                    },
                    {
                        label: "Mercadorias de Exposição",
                        icon: "pi pi-fw pi-arrows-h",
                        to: "/estoque/movimentacoes/exposicao",
                        permissions: [permissoes.ESTOQUE_MOVIMENTACOES_TRANSFERENCIAEXPOSICAO_CONSULTAR],
                    },
                    {
                        label: "Transferência de Mercadorias",
                        icon: "pi pi-fw pi-reply",
                        to: "/estoque/movimentacoes/transferencia-mercadoria",
                        permissions: [permissoes.ESTOQUE_MOVIMENTACOES_TRANSFERENCIAMERCADORIA_CONSULTAR],
                    },
                    {
                        label: "Requisições de Transferências",
                        icon: "pi pi-fw pi-stop",
                        to: "/estoque/movimentacoes/requisicoes-transferencia",
                        permissions: [permissoes.ESTOQUE_MOVIMENTACOES_REQUISICAOTRANSFERENCIA_CONSULTAR],
                    },
                    {
                        label: "Saldo Inicial",
                        icon: "pi pi-fw pi-tags",
                        to: "/estoque/movimentacoes/saldo-inicial-sku",
                        permissions: [permissoes.ESTOQUE_MOVIMENTACOES_SALDOINICIAL_CONSULTAR],
                    },
                ],
            },
            {
                label: "Inventário",
                icon: "fas pi-fw fa-box-open",
                items: [
                    {
                        label: "Lotes de inventário",
                        icon: "fas pi-fw fa-clipboard-list",
                        to: "/estoque/inventario/lotes-inventario",
                        permissions: [permissoes.ESTOQUE_MOVIMENTACOES_SALDOINICIAL_CONSULTAR],
                    },
                ],
            },
            {
                label: "Relatórios",
                icon: "pi pi-fw pi-file-excel",
                items: [
                    {
                        label: "Necessidade de compra",
                        icon: "pi pi-fw pi-shopping-cart",
                        to: "/estoque/relatorios/necessidade-compra",
                        permissions: [permissoes.RELATORIOS_EMITIR_ESTOQUE_NECESSIDADECOMPRA],
                    },
                    {
                        label: "Saldo OC",
                        icon: "fas pi-fw fa-boxes",
                        to: "/estoque/relatorios/saldo-ordem-compra",
                        permissions: [permissoes.RELATORIOS_EMITIR_ESTOQUE_SALDOOC],
                    },
                    {
                        label: "Saldo Inicial",
                        icon: "fas pi-fw fa-boxes",
                        to: "/estoque/relatorios/saldo-inicial-produtos",
                        permissions: [permissoes.RELATORIOS_EMITIR_ESTOQUE_SALDOINICIAL],
                    },
                    {
                        label: "Saldo Produtos",
                        icon: "fas pi-fw fa-boxes",
                        to: "/estoque/relatorios/saldo-produtos",
                        permissions: [permissoes.RELATORIOS_EMITIR_ESTOQUE_SALDOPRODUTOS],
                    },
                    {
                        label: "Custo Saldo Estoques",
                        icon: "fas pi-fw fa-boxes",
                        to: "/estoque/relatorios/custo-saldo-estoques",
                        permissions: [permissoes.RELATORIOS_EMITIR_ESTOQUE_CUSTOSALDOESTOQUES],
                    },
                    {
                        label: "Saldo Geral",
                        icon: "fas pi-fw fa-boxes",
                        to: "/estoque/relatorios/saldo-geral/",
                        permissions: [permissoes.RELATORIOS_EMITIR_ESTOQUE_SALDOGERAL],
                    },
                    {
                        label: "Saldos Estoque",
                        icon: "pi pi-fw pi-box",
                        to: "/produto/relatorios/saldos-estoque",
                        permissions: [permissoes.RELATORIOS_EMITIR_ESTOQUE_SALDOESTOQUES],
                    },
                ],
            },
        ],
    },
    { separator: true },
    {
        label: "Financeiro",
        icon: "pi pi-fw pi-dollar",
        items: [
            {
                label: "Financeiro",
                icon: "pi pi-fw pi-dollar",
                items: [
                    {
                        label: "Lançamentos",
                        icon: "pi pi-fw pi-sign-in",
                        to: "/financeiro/lancamentos",
                        permissions: [permissoes.FINANCEIRO_FINANCEIRO_LANCAMENTOS_CONSULTAR],
                    },
                    {
                        label: "Gerenciar caixa",
                        icon: "fas pi-fw fa-cash-register",
                        to: "/financeiro/financeiro/gerenciar-caixa",
                        permissions: [permissoes.FINANCEIRO_FINANCEIRO_CAIXA_MOVIMENTAR],
                    },

                    {
                        label: "Plano de Contas",
                        icon: "pi pi-fw pi-list",
                        to: "/financeiro/plano-contas",
                        permissions: [permissoes.FINANCEIRO_FINANCEIRO_PLANOCONTAS_CONSULTAR],
                    },
                    {
                        label: "Planos Orçamentários",
                        icon: "pi pi-fw pi-list",
                        to: "/financeiro/financeiro/planos-orcamentarios",
                        permissions: [permissoes.FINANCEIRO_FINANCEIRO_PLANOORCAMENTARIO_CONSULTAR],
                    },
                    {
                        label: "Plano de Recebimentos",
                        icon: "pi pi-fw pi-money-bill",
                        to: "/financeiro/plano-recebimentos",
                        permissions: [permissoes.FINANCEIRO_FINANCEIRO_PLANORECEBIMENTO_CONSULTAR],
                    },
                    {
                        label: "Pagamentos",
                        icon: "pi pi-fw pi-money-bill",
                        to: "/financeiro/financeiro/pagamentos",
                        permissions: [permissoes.FINANCEIRO_FINANCEIRO_PAGAMENTOS_CONSULTAR],
                    },
                    {
                        label: "Recebimentos",
                        icon: "pi pi-fw pi-download",
                        to: "/financeiro/financeiro/recebimentos",
                        permissions: [permissoes.FINANCEIRO_FINANCEIRO_RECEBIMENTOS_CONSULTAR],
                    },
                    {
                        label: "Regra de rateio",
                        icon: "pi pi-fw pi-chart-pie",
                        to: "/financeiro/financeiro/templates-rateios",
                        permissions: [permissoes.FINANCEIRO_FINANCEIRO_TEMPLATERATEIO_CONSULTAR],
                    },
                    {
                        label: "Versão Plano de Contas",
                        icon: "pi pi-share-alt",
                        to: "/financeiro/versao-plano-contas",
                        permissions: [permissoes.FINANCEIRO_FINANCEIRO_VERSAOPLANOCONTAS_CONSULTAR],
                    },
                    {
                        label: "Boletos",
                        icon: "pi pi-fw pi-file",
                        items: [
                            {
                                label: "Lotes de boletos",
                                icon: "pi pi-fw pi-copy",
                                to: "/financeiro/boletos/lotes",
                                permissions: [permissoes.FINANCEIRO_FINANCEIRO_VERSAOPLANOCONTAS_CONSULTAR],
                            },
                            {
                                label: "Lançamento manual",
                                icon: "pi pi-fw pi-file",
                                to: "/financeiro/boletos/lancamento-manual",
                                permissions: [permissoes.FINANCEIRO_FINANCEIRO_VERSAOPLANOCONTAS_CONSULTAR],
                            },
                        ],
                    },
                ],
            },
            {
                label: "Cadastros",
                icon: "pi pi-fw pi-align-justify",
                items: [
                    {
                        label: "Banco",
                        icon: "fas pi-fw fa-university",
                        to: "/financeiro/cadastros/bancos",
                        permissions: [permissoes.FINANCEIRO_CADASTRO_BANCO_CONSULTAR],
                    },
                    {
                        label: "Caixas",
                        icon: "fas pi-fw fa-cash-register",
                        to: "/financeiro/cadastros/caixas",
                        permissions: [permissoes.FINANCEIRO_FINANCEIRO_CAIXA_CONSULTAR],
                    },
                    {
                        label: "Carteira Conta Financeira",
                        icon: "pi pi-fw pi-wallet",
                        to: "/financeiro/cadastros/carteira-conta-financeira",
                        permissions: [permissoes.FINANCEIRO_CADASTRO_BANCO_CONSULTAR],
                    },
                    {
                        label: "Conta Financeira",
                        icon: "pi pi-fw pi-dollar",
                        to: "/financeiro/cadastros/contas-financeiras",
                        permissions: [permissoes.FINANCEIRO_CADASTRO_BANCO_CONSULTAR],
                    },
                    {
                        label: "Conta Bancária",
                        icon: "pi pi-fw pi-id-card",
                        to: "/financeiro/cadastros/contas-bancarias",
                        permissions: [permissoes.FINANCEIRO_CADASTRO_BANCO_CONSULTAR],
                    },
                    {
                        label: "Competência",
                        icon: "pi pi-fw pi-clone",
                        to: "/financeiro/cadastros/competencia",
                        permissions: [permissoes.FINANCEIRO_CADASTRO_COMPETENCIA_CONSULTAR],
                    },
                    {
                        label: "Formas Recebto/Pgto",
                        icon: "pi pi-fw pi-reply",
                        to: "/financeiro/cadastros/formas-recebimentos",
                        permissions: [permissoes.FINANCEIRO_CADASTRO_FORMARECEBIMENTOS_CONSULTAR],
                    },
                    {
                        label: "Chave Pix",
                        icon: "pi pi-fw pi-key",
                        to: "/financeiro/cadastros/chaves-pix",
                        permissions: [permissoes.FINANCEIRO_CADASTRO_CHAVEPIX_CONSULTAR],
                    },
                    {
                        label: "Templates de importação de extratos",
                        icon: "pi pi-fw pi-copy",
                        to: "/financeiro/cadastros/templates-importacao-extratos",
                        permissions: [permissoes.FINANCEIRO_CADASTRO_TEMPLATE_IMP_EXTRATO_CONSULTAR],
                    },
                    {
                        label: "Mapeador de importação de extratos",
                        icon: "pi pi-fw pi-copy",
                        to: "/financeiro/cadastros/mapeador-templates-importacao-extratos",
                        permissions: [permissoes.FINANCEIRO_CADASTRO_TEMPLATE_IMP_EXTRATO_CONSULTAR],
                    },
                    {
                        label: "Histórico Padrão",
                        icon: "pi pi-fw pi-book",
                        to: "/financeiro/cadastros/historico-padrao",
                        permissions: [permissoes.FINANCEIRO_CADASTROS_HISTORICOPADRAO_CONSULTAR],
                    },
                    {
                        label: "Administradoras",
                        icon: "pi pi-fw pi-credit-card",
                        to: "/financeiro/cadastros/administradora-cartao",
                        permissions: [permissoes.FINANCEIRO_CADASTRO_ADMINISTRADORACARTAO_EXCLUIR],
                    },
                ],
            },
            {
                label: "Relatórios",
                icon: "pi pi-fw pi-file-excel",
                items: [
                    {
                        label: "A Pagar",
                        icon: "pi pi-fw pi-dollar",
                        to: "/financeiro/relatorios/a-pagar",
                        permissions: [permissoes.RELATORIOS_EMITIR_CONTAS_APAGAR],
                    },
                    {
                        label: "A Receber",
                        icon: "pi pi-fw pi-dollar",
                        to: "/financeiro/relatorios/a-receber",
                        permissions: [permissoes.RELATORIOS_EMITIR_CONTAS_RECEBER],
                    },
                    {
                        label: "Contas Recebidas",
                        icon: "pi pi-fw pi-dollar",
                        to: "/financeiro/relatorios/contas-recebidas",
                        permissions: [permissoes.RELATORIOS_EMITIR_CONTAS_RECEBIDAS],
                    },
                    {
                        label: "Contas Pagas",
                        icon: "pi pi-fw pi-dollar",
                        to: "/financeiro/relatorios/contas-pagas",
                        permissions: [permissoes.RELATORIOS_EMITIR_CONTAS_PAGAS],
                    },
                    {
                        label: "Extrato conta",
                        icon: "pi pi-fw pi-file",
                        to: "/financeiro/relatorios/extrato-conta",
                        permissions: [permissoes.RELATORIOS_EMITIR_FINANCEIRO_EXTRATOCONTA],
                    },
                    {
                        label: "Movimentação Financeira",
                        icon: "pi pi-fw pi-dollar",
                        to: "/financeiro/relatorios/movimentacao-conta-financeira",
                        permissions: [permissoes.RELATORIOS_EMITIR_FINANCEIRO_MOVIMENTACAOCONTAFINANCEIRA],
                    },
                    {
                        label: "Resumo caixa",
                        icon: "fa fa-fw fa-cash-register",
                        to: "/financeiro/relatorios/resumo-movimentacao-caixa",
                        permissions: [permissoes.RELATORIOS_EMITIR_CAIXA_MOVIMENTO_RESUMO],
                    },
                ],
            },
        ],
    },
    { separator: true },
    {
        label: "Fiscal",
        icon: "pi pi-fw pi-tags",
        items: [
            {
                label: "Fiscal",
                icon: "pi pi-fw pi-tags",
                items: [
                    {
                        label: "Impostos",
                        icon: "fas pi-fw fa-table",
                        items: [
                            {
                                label: "ICMS",
                                to: "/fiscal/cadastros/outros/icms",
                                permissions: [permissoes.FISCAL_FISCAL_ICMS_CONSULTAR],
                            },
                            {
                                label: "IPI",
                                to: "/fiscal/cadastros/outros/ipi",
                                permissions: [permissoes.FISCAL_FISCAL_IPI_CONSULTAR],
                            },
                            {
                                label: "PIS",
                                to: "/fiscal/cadastros/outros/pis",
                                permissions: [permissoes.FISCAL_FISCAL_PIS_CONSULTAR],
                            },
                            {
                                label: "COFINS",
                                to: "/fiscal/cadastros/outros/cofins",
                                permissions: [permissoes.FISCAL_FISCAL_COFINS_CONSULTAR],
                            },
                        ],
                    },
                    {
                        label: "Cadastros",
                        icon: "fas pi-fw fa-book",
                        items: [
                            {
                                label: "NCM",
                                to: "/fiscal/cadastros/ncm",
                                permissions: [permissoes.FISCAL_FISCAL_NCM_CONSULTAR],
                            },
                            {
                                label: "CFOP",
                                to: "/fiscal/cadastros/cfop",
                                permissions: [permissoes.FISCAL_FISCAL_CFOP_CONSULTAR],
                            },
                        ],
                    },
                ],
            },
            {
                label: "Operações Fiscais",
                icon: "pi pi-fw pi-bars",
                items: [
                    {
                        label: "Produtos",
                        icon: "pi pi-fw pi-ticket",
                        to: "/fiscal/cadastros/operacoes-fiscais",
                        permissions: [permissoes.FISCAL_CADASTRO_OPERACAOFISCAL_CONSULTAR],
                    },
                    {
                        label: "Serviços",
                        icon: "pi pi-fw pi-ticket",
                        to: "/fiscal/cadastros/operacoes-fiscais-servicos",
                        permissions: [permissoes.FISCAL_CADASTRO_OPERACAOFISCALSERVICO_CONSULTAR],
                    },
                ],
            },
            {
                label: "NF's Próprias",
                icon: "pi pi-fw pi-book",
                items: [
                    {
                        label: "Listar NF-e|NFC-e",
                        icon: "pi pi-fw pi-align-justify",
                        to: "/fiscal/nota-saida/lista-notas",
                        permissions: [permissoes.FISCAL_NOTASAIDA_CONSULTAR],
                    },
                    {
                        label: "Emitir Nota",
                        icon: "pi pi-fw pi-envelope",
                        to: "/fiscal/nota-saida/emissao-nota",
                        permissions: [permissoes.FISCAL_NOTASAIDA_INCLUIR],
                    },
                    {
                        label: "Inutilização",
                        icon: "pi pi-fw pi-file-excel",
                        to: "/fiscal/nota-saida/inutilizacao",
                        permissions: [permissoes.FISCAL_NOTASAIDA_INUTILIZAR],
                    },
                ],
            },
            {
                label: "Notas de Serviço",
                icon: "pi pi-fw pi-wrench",
                items: [
                    {
                        label: "Listar NFS-e",
                        icon: "pi pi-fw pi-align-justify",
                        to: "/fiscal/nota-servico/lista-notas",
                        permissions: [permissoes.FISCAL_NOTASERVICO_CONSULTAR],
                    },
                    {
                        label: "Emitir Nota",
                        icon: "pi pi-fw pi-envelope",
                        to: "/fiscal/nota-servico/incluir-nota",
                        permissions: [permissoes.FISCAL_NOTASERVICO_INCLUIR],
                    },
                ],
            },
            {
                label: "Notas de Transporte",
                icon: "pi pi-fw pi-truck",
                to: "/fiscal/nota-transporte",
                permissions: [permissoes.FISCAL_NOTATRANSPORTE_CONSULTAR],
            },
            {
                label: "Tabela IBPT",
                icon: "pi pi-fw pi-align-justify",
                to: "/fiscal/tabela-ibpt/",
                permissions: [permissoes.FISCAL_NCM_CEST_MANUTENCAO],
            },
            {
                label: "Arquivo Fiscal",
                icon: "pi pi-fw pi-file",
                items: [
                    {
                        label: "Gerar Arquivo",
                        icon: "pi pi-fw pi-file-export",
                        to: "/fiscal/gerar-arquivo",
                        permissions: [permissoes.FISCAL_FISCAL_SPEDFISCAL_CONSULTAR],
                    },
                    {
                        label: "Modelos Sped",
                        icon: "pi pi-fw pi-folder",
                        to: "/fiscal/modelo-sped",
                        permissions: [permissoes.FISCAL_SPEDTIPO_CONSULTAR],
                    },
                ],
            },
            {
                label: "Relatórios",
                icon: "pi pi-fw pi-file-excel",
                items: [
                    {
                        label: "Transferência de Produtos",
                        to: "/fiscal/relatorios/transferencia-produtos/",
                        icon: "pi pi-fw pi-truck",
                        permissions: [permissoes.RELATORIOS_EMITIR_FISCAL_TRANSFERENCIAPRODUTO],
                    },
                ],
            },
        ],
    },
    { separator: true },
    {
        label: "Contábil",
        icon: "pi pi-fw pi-percentage",
        items: [
            {
                label: "Contábil",
                icon: "pi pi-fw pi-percentage",
                items: [
                    {
                        label: "Plano de Contas",
                        icon: "pi pi-fw pi-list",
                        to: "/contabil/plano-contas",
                        permissions: [permissoes.CONTABIL_CONTABIL_PLANOCONTAS_CONSULTAR],
                    },
                    {
                        label: "Versão Plano de Contas",
                        icon: "pi pi-fw pi-share-alt",
                        to: "/contabil/versao-plano-contas",
                        permissions: [permissoes.CONTABIL_CONTABIL_VERSAOPLANOCONTAS_CONSULTAR],
                    },
                ],
            },
            {
                label: "Vincular Planos",
                icon: "pi pi-fw pi-clone",
                to: "/contabil/vincular-planos",
                permissions: [permissoes.CONTABIL_CONTABIL_VINCULARPLANOS_CONSULTAR],
            },
        ],
    },
    { separator: true },
    {
        label: "Materiais",
        icon: "pi pi-fw pi-box",
        items: [
            {
                label: "Requisições",
                icon: "pi pi-fw pi-box",
                to: "/materiais/requisicao",
                permissions: [permissoes.MATERIAIS_REQUISICAO_VISUALIZAR],
            },
        ],
    },
    { separator: true },
    {
        label: "Serviços",
        icon: "pi pi-fw pi-share-alt",
        items: [
            {
                label: "Contrato de Serviço",
                icon: "pi pi-fw pi-book",
                to: "/servicos/servicos/contrato-servico",
                permissions: [permissoes.SERVICOS_SERVICOS_CONTRATOSERVICO_CONSULTAR],
            },

            {
                label: "Cadastros",
                icon: "pi pi-fw pi-box",
                items: [
                    {
                        label: "Cláusulas de Contrato",
                        icon: "pi pi-fw pi-clipboard",
                        to: "/servicos/cadastros/clausulas-contrato",
                        permissions: [permissoes.SERVICOS_CADASTROS_CLAUSULAS_CONSULTAR],
                    },
                    {
                        label: "Modelo de Contratos",
                        icon: "pi pi-fw pi-pen-to-square",
                        to: "/servicos/cadastros/modelo-contrato",
                        permissions: [permissoes.SERVICOS_CADASTROS_MODELOCONTRATO_CONSULTAR],
                    },
                    {
                        label: "Partes de Contratos",
                        icon: "pi pi-fw pi-tag",
                        to: "/servicos/cadastros/partes-contrato",
                        permissions: [permissoes.SERVICOS_CADASTROS_PARTES_CONSULTAR],
                    },
                    {
                        label: "Serviços",
                        icon: "pi pi-fw pi-cog",
                        to: "/servicos/servicos/servico",
                        permissions: [permissoes.SERVICOS_SERVICOS_SERVICO_CONSULTAR],
                    },
                    {
                        label: "Serviços do Município",
                        icon: "pi pi-fw pi-list",
                        to: "/servicos/servicos/servico-municipio",
                        permissions: [permissoes.OPERACIONAL_PLANOOPERACIONAL_FASESPROJETOS_CONSULTAR],
                    },
                ],
            },
        ],
    },
    { separator: true },
    {
        label: "Planejamento",
        icon: "pi pi-fw pi-share-alt",
        items: [
            {
                label: "Cadastros",
                icon: "pi pi-fw pi-chart-bar",
                items: [
                    {
                        label: "Centro de Resultados",
                        icon: "pi pi-fw pi-chart-line",
                        to: "/operacional/plano-operacional/centros-resultados",
                        permissions: [permissoes.OPERACIONAL_PLANOOPERACIONAL_CENTRORESULTADOS_CONSULTAR],
                    },
                    {
                        label: "Projetos",
                        icon: "pi pi-fw pi-github",
                        to: "/operacional/plano-operacional/projetos",
                        permissions: [permissoes.OPERACIONAL_PLANOOPERACIONAL_PROJETOS_CONSULTAR],
                    },
                    {
                        label: "Fases de Projetos",
                        icon: "pi pi-fw pi-list",
                        to: "/operacional/plano-operacional/fases-projetos",
                        permissions: [permissoes.OPERACIONAL_PLANOOPERACIONAL_FASESPROJETOS_CONSULTAR],
                    },
                    {
                        label: "Setores",
                        icon: "pi pi-fw pi-th-large",
                        to: "/operacional/plano-operacional/setores",
                        permissions: [permissoes.OPERACIONAL_PLANOOPERACIONAL_SETORES_CONSULTAR],
                    },
                ],
            },
        ],
    },
    { separator: true },
    {
        label: "Configurações",
        icon: "pi pi-fw pi-plus",
        items: [
            {
                label: "API Serviços",
                icon: "pi pi-fw pi-wifi",
                to: "/configuracoes/api-servicos",
                permissions: [permissoes.ROOT],
            },
            {
                label: "Feriados",
                icon: "pi pi-fw pi-briefcase",
                to: "/configuracoes/feriados",
                permissions: [permissoes.CONFIGURACOES_FERIADOS_CONSULTAR],
            },
            {
                label: "Parâmetros",
                icon: "pi pi-fw pi-cog",
                to: "/configuracoes/parametros",
                permissions: [permissoes.OUTROS_PARAMETROS_CONSULTAR],
            },
            {
                label: "Permissões Perfis",
                icon: "pi pi-fw pi-lock",
                to: "/configuracoes/permissoes-perfis",
                permissions: [permissoes.OUTROS_PARAMETROS_PERMISSOES_PERFIL_EDITAR],
            },
            {
                label: "Campos Personalizados",
                icon: "pi pi-fw pi-file",
                to: "/configuracoes/campos-personalizados",
                permissions: [permissoes.OUTROS_CAMPOSPERSONALIZADOS_CONSULTAR],
            },
            {
                label: "Impressoras térmicas",
                icon: "pi pi-fw pi-print",
                to: "/configuracoes/dispositivos/impressoras-termicas",
                permissions: [permissoes.OUTROS_IMPRESSORATERMICA_CONSULTAR],
            },
            {
                label: "Relatórios",
                icon: "pi pi-fw pi-file-excel",
                items: [
                    {
                        label: "Permissões",
                        icon: "pi pi-fw pi-id-card",
                        to: "/configuracoes/relatorios/permissoes-usuarios",
                        permissions: [permissoes.RELATORIOS_EMITIR_CONFIGURACOES_PERMISSOESUSUARIOS],
                    },
                ],
            },
        ],
    },
    { separator: true },
    {
        label: "Logs",
        icon: "pi pi-fw pi-plus",
        items: [
            {
                label: "Relatórios",
                icon: "pi pi-fw pi-file-excel",
                items: [
                    {
                        label: "Logs emitidos",
                        icon: "pi pi-fw pi-exclamation-circle",
                        to: "/logs/relatorios/logs-emitidos",
                        permissions: [permissoes.RELATORIOS_EMITIR_LOGS_LOGS],
                    },
                ],
            },
        ],
    },
    {
        label: "Relatórios",
        icon: "pi pi-fw pi-file-pdf",
        items: [
            {
                label: "Relatórios emitidos",
                icon: "pi pi-fw pi-file-pdf",
                to: "/relatorios/relatorios-emitidos",
                permissions: [permissoes.RELATORIOS_RELATORIOSEMITIDOS_CONSULTAR],
            },
        ],
    },
];

export default menu;
