import React, { useCallback, useEffect, useMemo, useState } from "react";
import classNames from "classnames";
import { ConfirmDialog, confirmDialog } from "primereact/confirmdialog";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { useFormik } from "formik";
import * as Yup from "yup";

import { MakoControleAcesso } from "@/components/MakoControleAcesso";
import { MakoDropdownCompetenciaEstoque } from "@/components/MakoInputs/MakoDropdownCompetenciaEstoque";
import { MakoInputCliente } from "@/components/MakoInputs/MakoInputCliente";
import { Dropdown } from "@/components/Dropdown";
import { MakoInputMoeda } from "@/components/MakoInputMoeda";
import { MakoCalendar } from "@/components/MakoCalendar";
import { MakoTime } from "@/components/MakoTime";
import { FormikAutoSave } from "@/components/FormikAutoSave";
import { LoadingComSpinner } from "@/components/LoadingComSpinner";
import { CamposObrigatorios } from "@/components/CamposObrigatorios";
import permissoes from "@/assets/constants/permissoes";
import useVenda from "@/hooks/useVenda";
import useAuth from "@/hooks/useAuth";
import useToast from "@/hooks/useToast";
import {
    VENDA_TAG_NAOVENDER,
    VENDA_MESMADATA_CAIXA,
    VENDA_COMPETENCIA_ESTOQUE_CORRENTE,
} from "@/assets/constants/parametros";
import useFormatCNPJCPF from "@/hooks/useFomatCNPJCPF";
import useParam from "@/hooks/useParam";
import useEmpresa from "@/hooks/useEmpresa";
import useCaixaMovimento from "@/hooks/useCaixaMovimento";
import useHttp from "@/hooks/useHttp";
import useLoadingLocal from "@/hooks/useLoadingLocal";
import { MakoCadastroPessoas } from "@/components/MakoCadastroPessoas";

export const DadosBasicosVendaForm = () => {
    const [supervisores, setSupervisores] = useState([]);
    const [vendedores, setVendedores] = useState([]);
    const { dadosBasicos, vendaFinalizada, loadingSalvar, handleDadosBasicos, handleLimparParcelas } = useVenda();
    const { verifyPermission, user } = useAuth();
    const { showWarning, showError } = useToast();
    const { httpGet } = useHttp();
    const [loadingVendSup, showLoadingVendSup, hideLoadingVendSup] = useLoadingLocal();
    const { getParam } = useParam();
    const { empresaSelecionadaId } = useEmpresa();
    const [formatarDocumento] = useFormatCNPJCPF();
    const { obterCaixaAbertoMaisRecentePorEmpresa } = useCaixaMovimento();

    const parametroCompetenciaPadrao = useMemo(() => {
        const parametro = getParam(VENDA_COMPETENCIA_ESTOQUE_CORRENTE);
        if (parametro) return parametro.valor;
        return null;
    }, [getParam]);

    const { setFieldValue, setValues, ...formik } = useFormik({
        initialValues: {
            id: 0,
            cliente: null,
            vendedor: null,
            supervisor: null,
            competencia: parametroCompetenciaPadrao,
            data: new Date(),
            hora: new Date(),
            plano_recebimento: null,
            valor_entrada: 0,
            valor_desconto: 0,
            valor_frete: 0,
            valor_total_av: 0,
            valor_total_ap: 0,
            observacoes: "",
            obs_cliente: "",
            finalizado_em: null,
            finalizado_por: null,
        },
        validationSchema: Yup.object().shape({
            competencia: Yup.number()
                .typeError("Você precisa escolher uma competência para a venda.")
                .required("O campo 'competência' é obrigatório."),
            vendedor: Yup.number()
                .typeError("Você precisa escolher um vendedor.")
                .required("O campo 'vendedor' é obrigatório."),
            supervisor: Yup.number().nullable().typeError("Você precisa escolher um supervisor."),
            cliente: Yup.lazy((value) => {
                switch (typeof value) {
                    case "object":
                        return Yup.object()
                            .test("bloqueado", "Perfil bloqueado para receber vendas", (val) => !val.disabled)
                            .typeError("O campo 'cliente' é obrigatório.");
                    case "string":
                        return Yup.string().required("O campo 'cliente' é obrigatório.");
                    default:
                        break;
                }
            }),
            data: Yup.date().required("O campo 'data da venda' é obrigatório."),
            plano_recebimento: Yup.number()
                .typeError("Você precisa escolher um plano de pagamento.")
                .required("O campo 'plano de pagamento' é obrigatório."),
        }),
        validateOnChange: false,
        onSubmit: handleSubmit,
    });

    async function handleSubmit(values, formikHelpers) {
        try {
            await formikHelpers.validateForm();
            const { status, data } = await handleDadosBasicos(values);
            if (status === 200 || status === 201) {
                formikHelpers.resetForm({ values: data });
            } else if (status === 500) {
                showError({
                    summary: "Erro :(",
                    detail: "Desculpe, não foi possível processar sua requisição.",
                    life: 3000,
                });
            }
        } catch (error) {
            if (error instanceof Yup.ValidationError) {
                let errorMessages = {};
                error.inner.forEach((err) => {
                    errorMessages[err.path] = err.message;
                });
                formikHelpers.setErrors(errorMessages);
            }
        } finally {
            formikHelpers.setSubmitting(false);
        }
    }

    const buscarVendedoresSupervisores = useCallback(async () => {
        const params = {
            usuario__isnull: false,
            query: "{id,perfil_pf,papeis_vigentes}",
            limit: 200,
        };
        const handlers = {
            200: ({ data }) => {
                let _supervisores = [];
                let _vendedores = [];
                data.results.forEach((perfil) => {
                    perfil.papeis_vigentes.forEach((papel) => {
                        if (papel.chave?.id === "SUP") {
                            _supervisores.push({
                                id: perfil.id,
                                nome: perfil.perfil_pf.nome_completo,
                            });
                        }
                        if (papel.chave?.id === "VND") {
                            _vendedores.push({
                                id: perfil.id,
                                nome: perfil.perfil_pf.nome_completo,
                            });
                        }
                    });
                });
                setSupervisores(_supervisores);
                setVendedores(_vendedores);
            },
        };
        showLoadingVendSup();
        await httpGet({ url: "/pessoas/perfis/", params }, handlers);
        hideLoadingVendSup();
    }, [httpGet, showLoadingVendSup, hideLoadingVendSup]);

    useEffect(() => {
        buscarVendedoresSupervisores();
    }, [buscarVendedoresSupervisores]);

    const setDadosBasicos = useCallback(() => {
        if (dadosBasicos) setValues(dadosBasicos);
    }, [dadosBasicos, setValues]);

    useEffect(() => {
        setDadosBasicos();
    }, [setDadosBasicos]);

    const confirmarTrocaPlanoRecebimento = useCallback(
        (planoRecebimento) => {
            confirmDialog({
                message:
                    "A troca do plano de recebimento levará a exclusão de todas as parcelas, será necessário refazê-las. Confirma?",
                header: "Confirmação",
                icon: "pi pi-exclamation-triangle",
                accept: async () => {
                    await handleLimparParcelas();
                    await setFieldValue("plano_recebimento", planoRecebimento);
                },
                acceptLabel: "Confirmar",
                rejectLabel: "Cancelar",
            });
        },
        [handleLimparParcelas, setFieldValue]
    );

    const handlePlanoRecebimento = useCallback(
        async ({ value }) => {
            if (formik.values.id === 0) {
                setFieldValue("plano_recebimento", value);
            } else if (dadosBasicos && formik.values.plano_recebimento && dadosBasicos.plano_recebimento !== value) {
                confirmarTrocaPlanoRecebimento(value);
            }
        },
        [dadosBasicos, formik.values.id, formik.values.plano_recebimento, confirmarTrocaPlanoRecebimento, setFieldValue]
    );

    const tagNaoVender = useMemo(() => {
        const tagPerfil = getParam(VENDA_TAG_NAOVENDER);
        if (tagPerfil) {
            return tagPerfil.valor;
        }
        return null;
    }, [getParam]);

    const obrigaCaixa = useMemo(() => {
        if (formik.values.id) return false;
        const param = getParam(VENDA_MESMADATA_CAIXA, empresaSelecionadaId);
        if (param && param.valor === "1") {
            const caixa = obterCaixaAbertoMaisRecentePorEmpresa(empresaSelecionadaId);
            if (!caixa) {
                showWarning({
                    summary: "Aviso!",
                    detail: "Você precisa abrir o caixa para efetuar vendas.",
                    life: 3000,
                });
            } else {
                setFieldValue("data", caixa.data_abertura);
            }
            return true;
        }
        return false;
    }, [
        getParam,
        empresaSelecionadaId,
        formik.values.id,
        setFieldValue,
        obterCaixaAbertoMaisRecentePorEmpresa,
        showWarning,
    ]);

    const aposPesquisar = useMemo(() => {
        return (data) => {
            return data.map((item) => {
                const label = `${item.nome} - ${
                    ["PF", "PJ"].includes(item.tipo_pessoa) ? formatarDocumento(item.identificacao) : item.identificacao
                }`;
                return {
                    ...item,
                    label,
                    disabled: tagNaoVender ? item.vinculoperfiltag_set.some((tag) => tag.id === tagNaoVender) : false,
                };
            });
        };
    }, [tagNaoVender, formatarDocumento]);

    const autoCompleteClienteTemplate = useCallback((item) => {
        const { disabled, label } = item;
        if (disabled) {
            return (
                <div className="p-error">
                    <span>{label}</span>
                    <br />
                    <small>Perfil bloqueado de receber vendas</small>
                </div>
            );
        }
        return <span>{label}</span>;
    }, []);

    const onChangeCliente = async (e) => {
        if (e instanceof Object && !formik.values.vendedor) setFieldValue("vendedor", user.id);
        await setFieldValue("cliente", e.value);
        if (e.value instanceof Object) {
            formik.validateField("cliente");
            setFieldValue("obs_cliente", e.value?.descricao);
        }
    };

    return (
        <>
            {vendaFinalizada && <h5 className="p-error p-mt-0">A venda está finalizada</h5>}
            <form onSubmit={formik.handleSubmit}>
                <div className="p-fluid p-formgrid p-grid">
                    <div className="p-field p-col-12 p-md-2">
                        <label htmlFor="n-orcamento">Número do orçamento</label>
                        <InputText
                            id="n-orcamento"
                            name="id"
                            disabled
                            value={formik.values.id}
                            onChange={formik.handleChange}
                        />
                    </div>
                    <div className="p-field p-col-12 p-md-5">
                        <label htmlFor="cliente">Cliente *</label>
                        <div className="p-inputgroup">
                            <MakoInputCliente
                                id="cliente"
                                name="cliente"
                                disabled={
                                    !!formik.values.id
                                        ? !verifyPermission([permissoes.VENDAS_VENDA_REALIZARVENDA_ALTERARCLIENTE])
                                        : false
                                }
                                somenteAtivo
                                trazerTags
                                field="label"
                                aposPesquisar={aposPesquisar}
                                itemTemplate={autoCompleteClienteTemplate}
                                value={formik.values.cliente}
                                onChange={onChangeCliente}
                                className={classNames({ "p-invalid": formik.errors.cliente })}
                            />
                            <MakoCadastroPessoas />
                        </div>
                        {formik.errors.cliente && <small className="p-error">{formik.errors.cliente}</small>}
                    </div>
                    <div className="p-field p-col-12 p-md-5">
                        <label htmlFor="obs-cliente">Observações do cliente</label>
                        <InputText
                            id="obs-cliente"
                            name="obs_cliente"
                            disabled
                            value={formik.values.obs_cliente}
                            readOnly
                        />
                    </div>
                </div>
                <div className="p-fluid p-formgrid p-grid">
                    <div className="p-field p-col-12 p-md-4">
                        <label htmlFor="competencia">Competência de estoque *</label>
                        <MakoDropdownCompetenciaEstoque
                            id="competencia"
                            name="competencia"
                            disabled={vendaFinalizada}
                            value={formik.values.competencia}
                            onChange={formik.handleChange}
                            className={classNames({ "p-invalid": formik.errors.competencia })}
                        />
                        {formik.errors.competencia && <small className="p-error">{formik.errors.competencia}</small>}
                    </div>
                    <div className="p-field p-col-12 p-md-4">
                        <label htmlFor="vendedor">Vendedor *</label>
                        <MakoControleAcesso
                            permissao={[permissoes.VENDAS_VENDA_REALIZARVENDA_ALTERARVENDEDOR]}
                            componente={Dropdown}
                            exibirDesabilitadoSemPermissao
                            id="vendedor"
                            name="vendedor"
                            placeholder={!loadingVendSup ? "Selecione" : "Carregando vendedores..."}
                            disabled={loadingVendSup || vendaFinalizada}
                            options={vendedores}
                            optionValue="id"
                            optionLabel="nome"
                            filter
                            filterBy="nome"
                            value={formik.values.vendedor}
                            onChange={formik.handleChange}
                            className={classNames({ "p-invalid": formik.errors.vendedor })}
                        />
                        {formik.errors.vendedor && <small className="p-error">{formik.errors.vendedor}</small>}
                    </div>
                    <div className="p-field p-col-12 p-md-4">
                        <label htmlFor="supervisor">Supervisor</label>
                        <MakoControleAcesso
                            permissao={[permissoes.VENDAS_VENDA_REALIZARVENDA_ALTERARSUPERVISOR]}
                            componente={Dropdown}
                            exibirDesabilitadoSemPermissao
                            id="supervisor"
                            name="supervisor"
                            placeholder={!loadingVendSup ? "Selecione" : "Carregando supervisores..."}
                            disabled={loadingVendSup || vendaFinalizada}
                            options={supervisores}
                            optionValue="id"
                            optionLabel="nome"
                            filter
                            filterBy="nome"
                            value={formik.values.supervisor}
                            onChange={formik.handleChange}
                            className={classNames({ "p-invalid": formik.errors.supervisor })}
                        />
                        {formik.errors.supervisor && <small className="p-error">{formik.errors.supervisor}</small>}
                    </div>
                </div>
                <div className="p-fluid p-formgrid p-grid">
                    <div className="p-field p-col-12 p-md-3">
                        <label htmlFor="data-venda">Data da venda *</label>
                        <MakoCalendar
                            id="data-venda"
                            name="data"
                            disabled={vendaFinalizada || obrigaCaixa}
                            valueCalendar={formik.values.data}
                            minDate={new Date()}
                            onChange={formik.handleChange}
                            className={classNames({ "p-invalid": formik.errors.data })}
                        />
                        {formik.errors.data && <small className="p-error">{formik.errors.data}</small>}
                    </div>
                    <div className="p-field p-col-12 p-md-3">
                        <label htmlFor="hora-venda">Hora da venda</label>
                        <MakoTime
                            id="hora-venda"
                            name="hora"
                            disabled={vendaFinalizada}
                            valueTime={formik.values.hora}
                            onChange={formik.handleChange}
                        />
                    </div>
                    <div className="p-field p-col-12 p-md-3">
                        <label htmlFor="valor-descontos">Valor total dos descontos</label>
                        <MakoInputMoeda
                            id="valor-descontos"
                            name="valor_desconto"
                            readOnly
                            disabled
                            valueMoeda={formik.values.valor_desconto}
                        />
                    </div>
                    <div className="p-field p-col-12 p-md-3">
                        <label htmlFor="valor-a-vista">Valor à vista</label>
                        <MakoInputMoeda
                            id="valor-a-vista"
                            name="valor_total_av"
                            readOnly
                            disabled
                            valueMoeda={formik.values.valor_total_av}
                        />
                    </div>
                </div>
                <div className="p-fluid p-formgrid p-grid">
                    <div className="p-field p-col-12 p-md-3">
                        <label htmlFor="plano-pagamento">Plano de recebimento *</label>
                        <Dropdown
                            id="plano-pagamento"
                            name="plano_recebimento"
                            url={
                                !formik.values.id
                                    ? "/vendas/planos_recebimentos/?limit=100"
                                    : `/vendas/planos_recebimentos/?venda=${formik.values.id}&limit=100`
                            }
                            disabled={vendaFinalizada}
                            optionLabel="descricao"
                            optionValue="id"
                            value={formik.values.plano_recebimento}
                            onChange={handlePlanoRecebimento}
                            className={classNames({ "p-invalid": formik.errors.plano_recebimento })}
                        />
                        {formik.errors.plano_recebimento && (
                            <small className="p-error">{formik.errors.plano_recebimento}</small>
                        )}
                    </div>
                    <div className="p-field p-col-12 p-md-3">
                        <label htmlFor="valor-a-prazo">Valor a prazo</label>
                        <MakoInputMoeda
                            id="valor-a-prazo"
                            name="valor_total_ap"
                            disabled
                            readOnly
                            valueMoeda={formik.values.valor_total_ap}
                        />
                    </div>
                    <div className="p-field p-col-12 p-md-3">
                        <label htmlFor="valor-frete">Valor do frete</label>
                        <MakoInputMoeda
                            id="valor-frete"
                            name="valor_frete"
                            valueMoeda={formik.values.valor_frete}
                            readOnly
                            disabled
                        />
                    </div>
                    <div className="p-field p-col-12 p-md-3">
                        <label htmlFor="valor-entrada">Valor recebido / entrada</label>
                        <MakoInputMoeda
                            id="valor-entrada"
                            name="valor_entrada"
                            disabled
                            readOnly
                            valueMoeda={formik.values.valor_entrada}
                        />
                    </div>
                </div>
                <div className="p-fluid p-formgrid p-grid">
                    <div className="p-field p-col-12 p-md-2">
                        <label htmlFor="usuario-finalizacao">Finalizada por</label>
                        <InputText
                            id="usuario-finalizacao"
                            name="usuario_finalizacao"
                            disabled
                            value={formik.values.finalizado_por?.usuario.username || ""}
                            readOnly
                        />
                    </div>
                    <div className="p-field p-col-12 p-md-2">
                        <label htmlFor="datahora-finalizacao">Finalizada em</label>
                        <MakoCalendar
                            id="datahora-finalizacao"
                            name="datahora_finalizacao"
                            disabled
                            readOnly
                            valueCalendar={formik.values.finalizado_em}
                        />
                    </div>
                    <div className="p-field p-col-12 p-md-8">
                        <label htmlFor="obs">Observações</label>
                        <InputText
                            id="obs"
                            name="observacoes"
                            disabled={vendaFinalizada}
                            value={formik.values.observacoes}
                            onChange={formik.handleChange}
                        />
                    </div>
                </div>
                <LoadingComSpinner loading={loadingSalvar} />
                <CamposObrigatorios />
                {formik.values.id === 0 && (
                    <div className="p-grid">
                        <div className="p-col-12 p-md-6">
                            <Button
                                type="submit"
                                icon="pi pi-save"
                                disabled={obrigaCaixa}
                                label="Incluir dados básicos"
                                className="p-mr-2 p-mb-2"
                            />
                        </div>
                    </div>
                )}
                <FormikAutoSave intervalo={1000} autosave={formik.values.id > 0} formik={formik} />
            </form>
            <ConfirmDialog />
        </>
    );
};
