import React, { forwardRef, useCallback, useImperativeHandle, useMemo, useRef, useState } from "react";

import { classNames } from "primereact/utils";
import { useFormik } from "formik";
import * as Yup from "yup";

import { OverlayPanel } from "primereact/overlaypanel";
import { Dialog } from "primereact/dialog";
import { Editor } from "primereact/editor";

import { MakoActionsButtons } from "@/components/MakoActionsButtons";
import { MAKO_ICONS } from "@/assets/constants/constants_styles";
import { MakoButton } from "@/components/MakoButton";
import { Label } from "@/components/Label";
import MakoListagem from "@/components/MakoListagem";

import useLoadingLocal from "@/hooks/useLoadingLocal";
import useToast from "@/hooks/useToast";
import useHttp from "@/hooks/useHttp";

const BASE_URL = "/servicos/editar-clausulas-modelo/{id}/";

export const Componente = ({ onSuccessCallback = () => {} }, ref) => {
    const [loading, showLoading, hideLoading] = useLoadingLocal();
    const [visible, setVisible] = useState(false);

    const { showSuccess, showError } = useToast();
    const { httpPost } = useHttp();

    const overlayRef = useRef();

    const { setValues, setFieldValue, resetForm, ...formik } = useFormik({
        initialValues: {
            id: null,
            clausula: null,
        },
        onSubmit: handleSubmit,
    });

    async function handleSubmit({ id, clausula }, formikHelpers) {
        try {
            const formSchema = Yup.object().shape({
                clausula: Yup.object().shape({
                    texto_clausula: Yup.string()
                        .required("O campo 'cláusula' é obrigatório")
                        .typeError("Informe um informações válidas"),
                }),
            });

            await formSchema.validate({ clausula }, { abortEarly: false });
            const handlers = {
                201: ({ data }) => {
                    showSuccess({
                        summary: "Sucesso",
                        detail: "Cláusula de contrato editada com sucesso!",
                        life: 1500,
                    });
                    onSuccessCallback(data);
                    setVisible(false);
                },
            };
            showLoading();
            await httpPost(
                {
                    url: BASE_URL.replace("{id}", id),
                    body: { texto_clausula: clausula.texto_clausula },
                },
                handlers
            );
            hideLoading();
        } catch (error) {
            if (error instanceof Yup.ValidationError) {
                let errorMessages = {};
                error.inner.forEach((err) => {
                    errorMessages[err.path] = err.message;
                });
                formikHelpers.setErrors(errorMessages);
            } else showError();
        }
    }

    const show = useCallback(
        (value) => {
            setValues({ ...value });
            setVisible(true);
        },
        [setValues]
    );

    const hide = useCallback(() => {
        setVisible(false);
    }, []);

    const header = useMemo(() => {
        const { id, nome } = formik.values.clausula || {};
        if (!id) return "Editando cláusula contratual";
        return `Editando cláusula contratual: ${id} - ${nome}`;
    }, [formik.values.clausula]);

    useImperativeHandle(ref, () => ({ show, hide }), [show, hide]);

    return (
        <Dialog
            visible={visible}
            header={header}
            breakpoints={{ "960px": "75vw" }}
            style={{ width: "90vw" }}
            onHide={hide}
        >
            <form onSubmit={formik.handleSubmit}>
                <div className="p-fluid p-formgrid p-grid">
                    <div className="p-field p-col-12 p-md-12">
                        <Label label="Cláusula / Parágrafo" obrigatorio />
                        <OverlayPanel ref={overlayRef} style={{ width: "400px" }} showCloseIcon>
                            <MakoListagem
                                colunas={[
                                    { field: "campo", header: "Tag" },
                                    { field: "descricao", header: "Campo" },
                                ]}
                                urlPesquisa={`/servicos/variaveis-contrato?limit=1000`}
                                configTabela={{
                                    selectionMode: "single",
                                    responsiveLayout: "scroll",
                                    onSelectionChange: (e) => {
                                        setFieldValue(
                                            "texto_clausula",
                                            `${formik.values.clausula?.texto_clausula.replace("/", "")} ${
                                                e.value.campo
                                            }`
                                        );
                                        overlayRef.current.toggle(e);
                                    },
                                }}
                            />
                        </OverlayPanel>
                        <Editor
                            id="texto_clausula"
                            name="texto_clausula"
                            value={formik.values.clausula?.texto_clausula}
                            onTextChange={(e) => setFieldValue("clausula.texto_clausula", e.htmlValue)}
                            onInput={(e) => {
                                if (e.nativeEvent.data === "/") overlayRef.current.toggle(e);
                            }}
                            style={{ height: "200px" }}
                            className={classNames({ "p-invalid": formik.errors.clausula })}
                        />
                        {formik.errors.clausula && <small className="p-error">{formik.errors.clausula}</small>}
                    </div>
                </div>
                <MakoActionsButtons className="p-jc-end">
                    <MakoButton
                        label="Cancelar"
                        icon={MAKO_ICONS.CANCEL}
                        type="button"
                        className="p-button-danger"
                        loading={loading}
                        onClick={() => setVisible(false)}
                    />
                    <MakoButton
                        label="Gravar"
                        icon={MAKO_ICONS.GRAVAR}
                        type="submit"
                        className="p-button-success"
                        loading={loading}
                    />
                </MakoActionsButtons>
            </form>
        </Dialog>
    );
};

export const ModalEditarClausula = forwardRef(Componente);
