import React, { useRef } from "react";

import { MakoControleAcesso } from "@/components/MakoControleAcesso";
import { MakoButton as Button } from "@/components/MakoButton";
import { PageBase } from "@/components/PageBase";
import MakoListagem from "@/components/MakoListagem";
import { MakoActionsButtonsColumn } from "@/components/MakoActionsButtonsColumn";
import { ButtonListagemOpcoes } from "@/components/ButtonListagemOpcoes";

import { TIPO_CARGO_CHOICE } from "@/assets/constants/constants";
import permissoes from "@/assets/constants/permissoes";
import useClearRefs from "@/hooks/useClearRefs";
import {
    AtivoFiltroTemplate,
    CodigoFiltroTemplate,
    DropdownFiltroTemplate,
    TextoFiltroTemplate,
} from "@/components/MakoFiltrosCabecalho";
import { gerarStatusBooleanTemplate } from "@/assets/util/util";
import { BUTTON_LISTAGEM_EDITAR } from "@/assets/constants/presets";

const url = "/pessoas/cargos/";

export const CargosPage = () => {
    const listagemRef = useRef(null);

    useClearRefs(listagemRef);

    const actionBodyTemplate = (rowData) => {
        return (
            <MakoActionsButtonsColumn>
                <MakoControleAcesso
                    permissao={[permissoes.GESTAO_CADASTRO_CARGOS_EDITAR]}
                    componente={Button}
                    {...BUTTON_LISTAGEM_EDITAR}
                    to={{
                        pathname: "/gestao/cadastros/cargos/form",
                        state: { tag: rowData },
                    }}
                />
                <ButtonListagemOpcoes
                    url={url}
                    objetoId={rowData.id}
                    objetoLabel={rowData.nome}
                    prefixo="o"
                    entidade="cargo"
                    onAction={() => listagemRef.current?.buscarDados()}
                    deleteOptions={{
                        exigeConfirmacao: true,
                        msgConfirmacao: (
                            <span>
                                Deseja realmente excluir o cargo <b>{rowData.nome}</b>?
                            </span>
                        ),
                        msgToastErroExclusao: "O cargo não pôde ser excluído.",
                    }}
                    visualizarOptions={{ disabled: true }}
                />
            </MakoActionsButtonsColumn>
        );
    };

    const cabecalhoTabela = (
        <>
            <MakoControleAcesso
                permissao={[permissoes.GESTAO_CADASTRO_CARGOS_INCLUIR]}
                componente={Button}
                label="Novo"
                icon="pi pi-plus"
                className="p-button-success"
                to={"/gestao/cadastros/cargos/form"}
            />
        </>
    );

    const NivelFiltroTemplate = (options) => {
        return (
            <DropdownFiltroTemplate
                dropdownProps={{
                    optionValue: "value",
                    optionLabel: "label",
                    options: TIPO_CARGO_CHOICE,
                }}
                options={options}
            />
        );
    };

    const colunas = [
        {
            field: "id",
            header: "Código",
            style: { width: "8%" },
            filter: true,
            filterElement: CodigoFiltroTemplate,
        },
        {
            field: "nome",
            header: "Nome",
            filter: true,
            filterElement: TextoFiltroTemplate,
        },
        {
            field: "nivel",
            header: "Nível",
            action: ({ nivel }) => {
                const _nivel = TIPO_CARGO_CHOICE.find((item) => item.value === nivel);
                if (_nivel) return _nivel.label;
                return nivel;
            },
            filter: true,
            filterElement: NivelFiltroTemplate,
        },
        {
            field: "ativo",
            header: "Ativo",
            style: { width: "8%" },
            action: ({ ativo }) => gerarStatusBooleanTemplate(ativo, "ATIVO", "DESATIVADO"),
            filter: true,
            align: "center",
            filterElement: AtivoFiltroTemplate,
        },
        {
            field: "action",
            header: "Ações",
            style: { width: "10%" },
            action: (e) => actionBodyTemplate(e),
        },
    ];

    return (
        <PageBase>
            <MakoListagem
                ref={listagemRef}
                titulo="Cargos"
                colunas={colunas}
                painelEsquerdo={cabecalhoTabela}
                urlPesquisa={url}
                configTabela={{
                    paginator: true,
                    lazy: true,
                }}
                filtros={{
                    id: { value: null, matchMode: "equals" },
                    nome: { operator: "and", constraints: [{ value: "", matchMode: "unaccent_icontains" }] },
                    nivel: { value: null, matchMode: "equals" },
                    ativo: { value: null, matchMode: "equals" },
                }}
            />
        </PageBase>
    );
};
