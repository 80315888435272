import React, { useRef, useState } from "react";
import { Dialog } from "primereact/dialog";
import { PessoaProvider } from "@/contexts/pessoaContext";
import { TabPessoas } from "@/pages/Gestao/Pessoas/form/tabs";
import { Button } from "primereact/button";
import { Menu } from "primereact/menu";
import { MAKO_ICONS } from "@/assets/constants/constants_styles";

export const MakoCadastroPessoas = (aposSalvar) => {
    const [visivel, setVisivel] = useState(false);
    const [tipo, setTipo] = useState("PF");
    const menuRef = useRef(null);

    const itens = [
        {
            label: "Escolha o tipo de pessoa para cadastrar",
            items: [
                {
                    label: "Pessoa Física",
                    command: () => {
                        setTipo("Pessoa Física");
                        setVisivel(true);
                    },
                },
                {
                    label: "Pessoa Jurídica",
                    command: () => {
                        setTipo("Pessoa Jurídica");
                        setVisivel(true);
                    },
                },
                {
                    label: "Pessoa Estrangeira",
                    command: () => {
                        setTipo("Pessoa Estrangeira");
                        setVisivel(true);
                    },
                },
            ],
        },
    ];

    return (
        <>
            <Menu model={itens} popup ref={menuRef} id="popup_menu" />
            <Button
                type="button"
                icon={MAKO_ICONS.NOVO}
                className="p-button-success p-mr-2"
                aria-controls="popup_menu"
                aria-haspopup
                onClick={(e) => menuRef.current.toggle(e)}
                tooltip="Cadastrar pessoa"
            />
            <Dialog
                visible={visivel}
                style={{ width: "70vw", display: "block" }}
                breakpoints={{ "960px": "90vw" }}
                onHide={() => setVisivel(false)}
            >
                <PessoaProvider>
                    <TabPessoas
                        tipoForm={tipo}
                        fecharModal={() => setVisivel(false)}
                        aposSalvar={(dados) => {
                            if (typeof aposSalvar === "function") aposSalvar(dados);
                            setVisivel(false);
                        }}
                    />
                </PessoaProvider>
            </Dialog>
        </>
    );
};
