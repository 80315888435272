import React, { forwardRef, useCallback, useImperativeHandle, useMemo, useRef, useState } from "react";

import { InputText } from "primereact/inputtext";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";

import { MakoDropdownClausulaContrato } from "@/components/MakoInputs";
import { Label } from "@/components/Label";
import MakoListagem from "@/components/MakoListagem";

import { gerarClausulaTemplate, gerarFiltrosUrl } from "@/assets/util/util";
import { MAKO_ICONS } from "@/assets/constants/constants_styles";

const TIPOS_POSICAO = {
    ACIMA: "acima",
    ABAIXO: "abaixo",
};

const BASE_URL = "/servicos/clausulas-contrato";

export const Componente = ({ onClose = () => {} }, ref) => {
    const [selecionados, setSelecionados] = useState([]);
    const [referencia, setReferencia] = useState({ posicao: null, referencia: null });
    const [visible, setVisible] = useState(false);
    const [search, setSearch] = useState("");

    const inputRef = useRef(null);

    const show = useCallback((referencia = null, posicao = null) => {
        setReferencia({ posicao, referencia });
        setVisible(true);
    }, []);

    const hide = useCallback(() => {
        setVisible(false);
        onClose({ selecionados, referencia });
    }, [selecionados, referencia, onClose]);

    useImperativeHandle(ref, () => ({ show, hide, TIPOS_POSICAO }), [show, hide]);

    const url = useMemo(() => {
        return `${BASE_URL}?${gerarFiltrosUrl({ situacao: "V", search })}`;
    }, [search]);

    const colunas = [
        { selectionMode: "multiple", headerStyle: { width: "3em" } },
        {
            field: "parte.identificador",
            header: "Secção",
        },
        {
            field: "nome",
            header: "Nome",
        },
        {
            field: "clausula.texto_clausula",
            header: "Início do texto...",
            action: (e) => gerarClausulaTemplate({ ...e, showHeader: false }),
        },
    ];

    return (
        <Dialog
            visible={visible}
            header="Adicionando cláusula ao contrato"
            breakpoints={{ "960px": "75vw" }}
            style={{ width: "90vw" }}
            onHide={hide}
        >
            <div className="p-fluid p-formgrid p-grid">
                <div className="p-field p-col-12 p-md-2">
                    <Label htmlFor="secao" label="Secção" />
                    <MakoDropdownClausulaContrato
                        id="secao"
                        name="secao"
                        value={referencia.referencia}
                        disabled
                        placeholder=""
                    />
                </div>
                <div className="p-field p-col-12 p-md-10">
                    <Label htmlFor="clausula" label="Cláusula" />
                    <div className="p-inputgroup">
                        <InputText id="clausula" name="clausula" ref={inputRef} />
                        <Button
                            icon={MAKO_ICONS.FILTRAR}
                            label="Filtrar"
                            type="button"
                            className="p-button p-button-success"
                            onClick={() => setSearch(() => inputRef.current?.value)}
                        />
                    </div>
                </div>
            </div>
            <div className="p-mt-2">
                <MakoListagem
                    colunas={colunas}
                    urlPesquisa={url}
                    fazerBusca={!!search}
                    configTabela={{
                        lazy: true,
                        scrollable: true,
                        paginator: true,
                        editMode: "cell",
                        selectionMode: "multiple",
                        selection: selecionados,
                        onSelectionChange: (e) => setSelecionados(e.value),
                    }}
                />
            </div>
        </Dialog>
    );
};

export const ModalAdicionarClausula = forwardRef(Componente);
