import React from "react";

import { useFormik } from "formik";
import classNames from "classnames";
import * as Yup from "yup";

import { InputText } from "primereact/inputtext";

import { MakoButton as Button } from "@/components/MakoButton";
import { CamposObrigatorios } from "@/components/CamposObrigatorios";
import { MakoActionsButtons } from "@/components/MakoActionsButtons";
import { Dropdown } from "@/components/Dropdown";
import { Label } from "@/components/Label";

import { SelectButton } from "primereact/selectbutton";

import { SIM_NAO_BOOLEAN, TIPO_CHOICE_OPERACAO_ADMINISTRADORACARTAO } from "@/assets/constants/constants";
import { MAKO_ICONS } from "@/assets/constants/constants_styles";

import useLoadingLocal from "@/hooks/useLoadingLocal";
import useToast from "@/hooks/useToast";
import useHttp from "@/hooks/useHttp";

const BASE_URL = "/financeiro/administradora-cartao/";
const INITIAL_VALUES = {
    id: null,
    nome: "",
    operacao: null,
    ativo: true,
};

export const Form = ({ administradora, onSuccessCallback = () => {}, onCancelCallback = () => {} }) => {
    const [loading, showLoading, hideLoading] = useLoadingLocal();
    const { httpPost, httpPut } = useHttp();
    const { showSuccess } = useToast();

    const { setValues, resetForm, ...formik } = useFormik({
        initialValues: !administradora ? INITIAL_VALUES : { ...INITIAL_VALUES, ...administradora },
        onSubmit: handleSubmit,
    });

    async function handleSubmit(values) {
        try {
            const formSchema = Yup.object().shape({
                nome: Yup.string().required("O campo 'nome' é obrigatório.").typeError("Informe um 'nome' válido."),
                operacao: Yup.string().required("O campo 'operacao' é obrigatório."),
            });
            await formSchema.validate(values, { abortEarly: false });
            if (!values.id) {
                const handlers = {
                    201: () => {
                        resetForm();
                        onSuccessCallback();
                        showSuccess({
                            summary: "Sucesso",
                            detail: "Administradora de cartão cadastrada com sucesso!",
                            life: 1500,
                        });
                    },
                };
                showLoading();
                await httpPost(
                    {
                        url: BASE_URL,
                        body: values,
                    },
                    handlers
                );
                hideLoading();
            } else {
                const handlers = {
                    200: () => {
                        resetForm();
                        onSuccessCallback();
                        showSuccess({
                            summary: "Sucesso",
                            detail: "Administradora de cartão alterada com sucesso!",
                            life: 1500,
                        });
                    },
                };
                showLoading();
                await httpPut({ url: `${BASE_URL}${values.id}/`, body: { ...values } }, handlers);
                hideLoading();
            }
        } catch (error) {
            if (error instanceof Yup.ValidationError) {
                let errorMessages = {};
                error.inner.forEach((err) => {
                    errorMessages[err.path] = err.message;
                });
                formik.setErrors(errorMessages);
            }
        }
    }

    return (
        <form onSubmit={formik.handleSubmit}>
            <div className="p-fluid p-formgrid p-grid">
                <div className="p-field p-col-12 p-md-4">
                    <Label htmlFor="nome" label="Nome" obrigatorio />
                    <InputText
                        id="nome"
                        name="nome"
                        value={formik.values.nome}
                        onInput={formik.handleChange}
                        className={classNames({ "p-invalid": formik.errors.nome })}
                    />
                    {formik.errors.nome && <small className="p-error">{formik.errors.nome}</small>}
                </div>
                <div className="p-field p-col-12 p-md-4">
                    <Label htmlFor="operacao" label="Operação" obrigatorio />
                    <Dropdown
                        id="operacao"
                        name="operacao"
                        options={TIPO_CHOICE_OPERACAO_ADMINISTRADORACARTAO}
                        optionValue="value"
                        optionLabel="label"
                        value={formik.values.operacao}
                        showClear={false}
                        onChange={formik.handleChange}
                        className={classNames({ "p-invalid": formik.errors.operacao })}
                    />
                    {formik.errors.operacao && <small className="p-error">{formik.errors.operacao}</small>}
                </div>
                <div className="p-field p-col-12 p-md-2">
                    <Label htmlFor="ativo" label="Ativo" />
                    <SelectButton
                        id="ativo"
                        name="ativo"
                        value={formik.values.ativo}
                        onChange={formik.handleChange}
                        options={SIM_NAO_BOOLEAN}
                        optionLabel="label"
                        optionValue="id"
                    />
                </div>
            </div>
            <CamposObrigatorios />
            <MakoActionsButtons>
                <Button
                    label="Gravar"
                    icon={MAKO_ICONS.GRAVAR}
                    type="submit"
                    loading={loading}
                    className="p-button-info"
                />
                <Button
                    label="Cancelar"
                    icon={MAKO_ICONS.CANCEL}
                    type="button"
                    loading={loading}
                    className="p-button-danger"
                    onClick={onCancelCallback}
                />
            </MakoActionsButtons>
        </form>
    );
};
