import { useRef, useState } from "react";
import { ConfirmDialog } from "primereact/confirmdialog";

import { ButtonMenuPopup } from "../ButtonMenuPopup";
import { Delete } from "../Delete";
import { BUTTON_LISTAGEM_OPCOES } from "@/assets/constants/presets";
import { MAKO_ICONS } from "@/assets/constants/constants_styles";
import useHttp from "@/hooks/useHttp";
import useToast from "@/hooks/useToast";

export const ButtonListagemOpcoes = ({
    ativarOptions = true,
    desativarOptions = true,
    deleteOptions = true,
    visualizarOptions = true,
    onAction,
    attr = "ativo",
    url,
    entidade,
    prefixo,
    objetoId,
    objetoLabel,
    buttonProps,
}) => {
    const [confirmar, setConfirmar] = useState(false);
    const [ativar, setAtivar] = useState(false);
    const deleteRef = useRef(null);
    const { httpPatch } = useHttp();
    const { showSuccess, showCustom } = useToast();

    const update = async () => {
        const handlers = {
            200: async () => {
                const acao = `${ativar ? "ativad" : "desativad"}${prefixo?.toLowerCase()}`;
                showSuccess({
                    summary: "Sucesso",
                    detail: `${prefixo?.toUpperCase()} ${entidade?.toLowerCase()} foi ${acao} com sucesso!`,
                    life: 1500,
                });
                if (typeof onAction === "function") await onAction();
            },
            error: ({ statusCode }) => {
                const acao = ativar ? "ativar" : "desativar";
                showCustom({
                    severity: statusCode <= 499 ? "warn" : "error",
                    summary: statusCode <= 499 ? "Opsss" : "Erro :(",
                    detail: `Desculpe, não foi possível ${acao} ${prefixo?.toLowerCase()} ${entidade?.toLowerCase()}.`,
                    life: 2000,
                });
            },
        };
        const body = { [attr]: ativar };
        await httpPatch({ url: `${url}/${objetoId}/`, body }, handlers);
        setConfirmar(false);
    };

    const items = [
        {
            label: "Opções",
            items: [],
        },
    ];

    if (ativarOptions === true || (ativarOptions && typeof ativarOptions !== "boolean")) {
        const options = typeof ativarOptions && typeof ativarOptions !== "boolean" ? ativarOptions : {};
        const { label = "Ativar", icon = MAKO_ICONS.ATIVO, disabled = false, onClick } = options;
        items[0].items.push({
            label,
            icon,
            disabled,
            command: () => {
                if (typeof onClick === "function") onClick();
                else {
                    setConfirmar(true);
                    setAtivar(true);
                }
            },
        });
    }

    if (desativarOptions === true || (desativarOptions && typeof desativarOptions !== "boolean")) {
        const options = typeof desativarOptions && typeof desativarOptions !== "boolean" ? desativarOptions : {};
        const { label = "Desativar", icon = MAKO_ICONS.INATIVO, disabled = false, onClick } = options;
        items[0].items.push({
            label,
            icon,
            disabled,
            command: () => {
                if (typeof onClick === "function") onClick();
                else {
                    setConfirmar(true);
                    setAtivar(false);
                }
            },
        });
    }

    if (deleteOptions === true || (deleteOptions && typeof deleteOptions !== "boolean")) {
        const options = typeof deleteOptions && typeof deleteOptions !== "boolean" ? deleteOptions : {};
        const { label = "Excluir", icon = MAKO_ICONS.DELETE, disabled = false, onClick } = options;
        items[0].items.push({
            label,
            icon,
            disabled,
            command: () => (typeof onClick === "function" ? onClick() : deleteRef.current?.handleClick()),
        });
    }

    if (visualizarOptions === true || (visualizarOptions && typeof visualizarOptions !== "boolean")) {
        const options = typeof visualizarOptions && typeof visualizarOptions !== "boolean" ? visualizarOptions : {};
        const { label = "Visualizar", icon = MAKO_ICONS.VISUALIZAR, disabled = false, onClick } = options;
        items[0].items.push({
            label,
            icon,
            disabled,
            command: () => {
                if (typeof onClick === "function") onClick();
            },
        });
    }

    return (
        <>
            <ButtonMenuPopup menu={{ model: items }} {...BUTTON_LISTAGEM_OPCOES} {...buttonProps} />
            <Delete ref={deleteRef} {...deleteOptions} url={url} objetoId={objetoId} onDelete={onAction} />
            <ConfirmDialog
                visible={confirmar}
                message={
                    <span>
                        {`Confirma a ${
                            !ativar ? "des" : ""
                        }ativação d${prefixo?.toLowerCase()} ${entidade?.toLowerCase()} `}
                        <b>{objetoLabel}</b>?
                    </span>
                }
                header="Confirmação"
                icon="pi pi-exclamation-triangle"
                accept={async () => await update(ativar)}
                acceptLabel="Sim"
                rejectLabel="Não"
            />
        </>
    );
};
