import React, { useRef, useState } from "react";

import { Dialog } from "primereact/dialog";
import { Menu } from "primereact/menu";
import { MakoActionsButtonsColumn } from "@/components/MakoActionsButtonsColumn";
import { MakoButton as Button } from "@/components/MakoButton";
import { MakoControleAcesso } from "@/components/MakoControleAcesso";
import { MakoConfirmDialog } from "@/components/MakoConfirmDialog";
import { PageBase } from "@/components/PageBase";
import MakoListagem from "@/components/MakoListagem";
import { gerarFiltrosUrl } from "@/assets/util/util";
import { MAKO_ICONS } from "@/assets/constants/constants_styles";
import permissoes from "@/assets/constants/permissoes";
import useClearRefs from "@/hooks/useClearRefs";
import useEmpresa from "@/hooks/useEmpresa";
import useToast from "@/hooks/useToast";
import useHttp from "@/hooks/useHttp";
import useAuth from "@/hooks/useAuth";
import { FiltroAvancadoModeloContrato } from "./filtroAvancado";

const BASE_URL = "/servicos/modelos-contrato/";

export const ModeloContratoPage = () => {
    const [filtroAvancado, setFiltroAvancado] = useState(false);
    const [baixarDialog, setBaixarDialog] = useState(false);
    const [contrato, setContrato] = useState(null);
    const [url, setUrl] = useState(BASE_URL);
    const [filtrosAplicados, setFiltrosAplicados] = useState(null);

    const { showSuccess } = useToast();
    const { empresaSelecionadaId } = useEmpresa();
    const { httpPatch } = useHttp();
    const { verifyPermission, user } = useAuth();
    const menuAcoesRef = useRef(null);
    const listagemRef = useRef();

    useClearRefs(menuAcoesRef, listagemRef);

    async function handleSituacao(values) {
        const handlers = {
            200: () => {
                showSuccess({
                    summary: "Sucesso",
                    detail: `A cláusula de contrato foi ${
                        values.situacao === "B" ? "desativada" : "validada"
                    } com sucesso.`,
                    life: 3000,
                });
                listagemRef.current?.buscarDados();
            },
        };

        await httpPatch({ url: `/servicos/modelos-contrato/${values.id}/`, body: values }, handlers);
    }

    const botoesMenu = [
        {
            label: "Ações",
            items: [
                {
                    label: "Validar",
                    icon: "pi pi-check",
                    disabled: !verifyPermission([permissoes.SERVICOS_CADASTROS_MODELOCONTRATO_VALIDAR]),
                    command: () =>
                        handleSituacao(
                            {
                                ...contrato,
                                situacao: "V",
                                usuario_validou: user.id,
                                data_validacao: new Date(),
                            },
                            "B"
                        ),
                },
                {
                    label: "Baixar",
                    icon: "pi pi-times",
                    disabled: !verifyPermission([permissoes.SERVICOS_CADASTROS_MODELOCONTRATO_BAIXAR]),
                    command: () => setBaixarDialog(true),
                },
            ],
        },
    ];

    const actionBodyTemplate = (rowData) => {
        return (
            <MakoActionsButtonsColumn>
                <MakoControleAcesso
                    permissao={[permissoes.SERVICOS_SERVICOS_CONTRATOSERVICO_EDITAR]}
                    componente={Button}
                    icon="pi pi-pencil"
                    className="p-button-rounded p-button-warning"
                    to={{
                        pathname: "/servicos/cadastros/modelo-contrato/form",
                        state: { contrato: rowData },
                    }}
                />
                <MakoControleAcesso
                    permissao={[permissoes.SERVICOS_SERVICOS_CONTRATOSERVICO_EDITAR]}
                    componente={Button}
                    icon="pi pi-cog"
                    className="p-button-rounded p-button-info"
                    model={botoesMenu}
                    onClick={(event) => {
                        menuAcoesRef.current?.toggle(event);
                        setContrato(rowData);
                    }}
                />
            </MakoActionsButtonsColumn>
        );
    };

    const limparFiltros = () => {
        setUrl(BASE_URL);
        setFiltrosAplicados(null);
    };

    const cabecalhoTabela = (
        <>
            <MakoControleAcesso
                permissao={[permissoes.SERVICOS_SERVICOS_SERVICO_INCLUIR]}
                componente={Button}
                label="Novo"
                disabled={!empresaSelecionadaId}
                icon={MAKO_ICONS.NOVO}
                className="p-button-success p-mr-2"
                to={"/servicos/cadastros/modelo-contrato/form"}
            />
            <Button
                label="Filtro Avançado"
                icon={MAKO_ICONS.FILTRAR}
                className="p-button-help p-mr-2"
                onClick={() => setFiltroAvancado(true)}
            />
            <Button
                label="Limpar filtros"
                icon={MAKO_ICONS.LIMPAR_FILTROS}
                disabled={url === BASE_URL}
                className="p-button-warning p-mr-2"
                onClick={limparFiltros}
            />
        </>
    );

    const colunas = [
        { field: "id", header: "Código", style: { minWidth: "50px" } },
        { field: "grupo", header: "Grupo" },
        { field: "titulo", header: "Título" },
        {
            field: "situacao.descricao",
            header: "Situação",
            align: "center",
            style: { minWidth: "80px" },
        },
        {
            field: "action",
            header: "Ações",
            style: { minWidth: "50px" },
            action: (e) => actionBodyTemplate(e),
        },
    ];

    const fecharFiltro = () => {
        setFiltroAvancado(() => false);
    };

    const onConfirmFiltro = ({ filtros, values }) => {
        setUrl(`${BASE_URL}?${gerarFiltrosUrl(filtros)}`);
        setFiltrosAplicados(values);
        setFiltroAvancado(false);
    };

    return (
        <>
            <PageBase>
                <Menu model={botoesMenu} popup ref={menuAcoesRef} id="popup_menu_acoes" />
                <MakoListagem
                    ref={listagemRef}
                    titulo="Modelos de Contratos Jurídicos"
                    colunas={colunas}
                    painelEsquerdo={cabecalhoTabela}
                    urlPesquisa={url}
                    filtarPorEmpresa
                    fieldFiltroEmpresa="empresa"
                    configTabela={{
                        paginator: true,
                        lazy: true,
                    }}
                />
                <Dialog
                    header="Filtro avançado"
                    visible={filtroAvancado}
                    breakpoints={{ "960px": "75vw" }}
                    style={{ width: "75vw" }}
                    onHide={fecharFiltro}
                >
                    <FiltroAvancadoModeloContrato
                        onCancel={fecharFiltro}
                        onConfirm={onConfirmFiltro}
                        initialValues={filtrosAplicados}
                    />
                </Dialog>
                <MakoConfirmDialog
                    visible={baixarDialog}
                    setVisible={setBaixarDialog}
                    message={
                        <span>
                            {"Deseja realmente baixar o modelo de contrato de código "}
                            <b>{contrato?.id}</b>?
                        </span>
                    }
                    accept={() =>
                        handleSituacao({
                            ...contrato,
                            situacao: "B",
                            usuario_alterou: user.id,
                            data_alteracao: new Date(),
                        })
                    }
                    showErrorToast={false}
                />
            </PageBase>
        </>
    );
};
