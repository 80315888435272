import React, { useCallback, useEffect, useState } from "react";
import { TabView, TabPanel } from "primereact/tabview";
import { Button } from "primereact/button";
import { useHistory } from "react-router-dom";
import { ModeloContratoProvider } from "@/contexts/modeloContrato";
import { PageBase } from "@/components/PageBase";
import useModeloContrato from "@/hooks/useModeloContrato";
import DadosBasicosForm from "./dadosBasicos";
import ClausulasForm from "./clausulas";

const TabModeloContrato = ({ id }) => {
    const { handlePreencherModelo, dadosBasicos } = useModeloContrato();
    const [activeTab, setActiveTab] = useState(0);
    const history = useHistory();

    const carregarModelo = useCallback(async () => {
        if (id) await handlePreencherModelo(id);
    }, [id, handlePreencherModelo]);

    useEffect(() => {
        carregarModelo();
    }, [carregarModelo]);

    const alterarTab = (aba) => {
        if (aba === -1) {
            if (activeTab > 0) {
                setActiveTab(activeTab - 1);
            }
        } else {
            if (activeTab < 6) {
                setActiveTab(activeTab + 1);
            }
        }
    };

    const voltar = () => {
        history.push("/servicos/cadastros/modelo-contrato");
    };

    return (
        <PageBase>
            <h5>Modelo de Contrato Jurídico: {dadosBasicos?.id && <b>{dadosBasicos.titulo}</b>}</h5>
            <TabView activeIndex={activeTab} onTabChange={(e) => setActiveTab(e.index)}>
                <TabPanel header="Dados básicos" leftIcon="pi pi-file">
                    <DadosBasicosForm />
                </TabPanel>
                <TabPanel header="Cláusulas" leftIcon="pi pi-sliders-h" disabled={!dadosBasicos?.id}>
                    <ClausulasForm />
                </TabPanel>
            </TabView>
            <div className="p-grid p-fluid p-justify-start" style={{ paddingTop: "3%" }}>
                {activeTab > 0 ? (
                    <div className="p-col-6 p-md-3">
                        <Button
                            label="Anterior"
                            icon="pi pi-angle-left"
                            className="p-button-info"
                            disabled={!dadosBasicos?.id}
                            onClick={() => alterarTab(-1)}
                        />
                    </div>
                ) : null}
                {activeTab < 6 ? (
                    <div className="p-col-6 p-md-3">
                        <Button
                            label="Próximo"
                            disabled={!dadosBasicos?.id}
                            icon="pi pi-angle-right"
                            iconPos="right"
                            className="p-button-info"
                            onClick={() => alterarTab(1)}
                        />
                    </div>
                ) : null}
                <div className="p-col-4 p-md-3">
                    <Button label="Voltar" className="p-button-danger" onClick={() => voltar()} />
                </div>
            </div>
        </PageBase>
    );
};

export const TabModeloContratoContext = (props) => {
    const { contrato = {} } = props?.location?.state || {};
    return (
        <ModeloContratoProvider>
            <TabModeloContrato id={contrato?.id} />
        </ModeloContratoProvider>
    );
};
