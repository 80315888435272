import React, { useRef } from "react";
import { MakoControleAcesso } from "@/components/MakoControleAcesso";

import MakoListagem from "@/components/MakoListagem";
import { PageBase } from "@/components/PageBase";
import { MakoButton as Button } from "@/components/MakoButton";
import permissoes from "@/assets/constants/permissoes";
import { CodigoFiltroTemplate, TextoFiltroTemplate } from "@/components/MakoFiltrosCabecalho";
import { MakoActionsButtonsColumn } from "@/components/MakoActionsButtonsColumn";
import { MAKO_ICONS } from "@/assets/constants/constants_styles";
import { BUTTON_LISTAGEM_EDITAR } from "@/assets/constants/presets";
import { ButtonListagemOpcoes } from "@/components/ButtonListagemOpcoes";

const url = "/pessoas/tags-perfis/";

export const PessoasTagsPerfisPage = () => {
    const listagemRef = useRef(null);

    const actionBodyTemplate = (rowData) => {
        return (
            <MakoActionsButtonsColumn>
                <MakoControleAcesso
                    permissao={[permissoes.GESTAO_CADASTRO_TAGSPERFIL_EDITAR]}
                    componente={Button}
                    {...BUTTON_LISTAGEM_EDITAR}
                    to={{
                        pathname: "/gestao/cadastros/tags-perfis/form",
                        state: { tag: rowData },
                    }}
                />
                <ButtonListagemOpcoes
                    url={url}
                    objetoId={rowData.id}
                    objetoLabel={rowData.descricao}
                    prefixo="a"
                    entidade="tag perfil"
                    onAction={() => listagemRef.current?.buscarDados()}
                    deleteOptions={{
                        exigeConfirmacao: true,
                        msgConfirmacao: (
                            <span>
                                Deseja realmente excluir a tag <b>{rowData.descricao}</b>?
                            </span>
                        ),
                        msgToastErroExclusao: "A tag perfil não pôde ser excluída.",
                    }}
                    visualizarOptions={{ disabled: true }}
                />
            </MakoActionsButtonsColumn>
        );
    };

    const cabecalhoTabela = (
        <>
            <MakoControleAcesso
                permissao={[permissoes.GESTAO_CADASTRO_TAGSPERFIL_INCLUIR]}
                componente={Button}
                label="Novo"
                icon={MAKO_ICONS.NOVO}
                className="p-button-success"
                to={"/gestao/cadastros/tags-perfis/form"}
            />
        </>
    );

    const colunas = [
        {
            field: "id",
            header: "Código",
            style: { width: "6%" },
            filter: true,
            filterField: "id",
            filterElement: CodigoFiltroTemplate,
        },
        {
            field: "descricao",
            header: "Descrição",
            filter: true,
            filterField: "descricao",
            filterElement: TextoFiltroTemplate,
        },

        {
            field: "action",
            header: "Ações",
            style: { width: "10%" },
            action: (e) => actionBodyTemplate(e),
        },
    ];

    return (
        <PageBase>
            <MakoListagem
                ref={listagemRef}
                titulo="Tags de Perfil"
                colunas={colunas}
                painelEsquerdo={cabecalhoTabela}
                urlPesquisa={url}
                configTabela={{
                    paginator: true,
                    lazy: true,
                }}
                filtros={{
                    id: { value: "", matchMode: "equals" },
                    descricao: {
                        operator: "and",
                        constraints: [{ value: "", matchMode: "icontains" }],
                    },
                }}
            />
        </PageBase>
    );
};
