import React, { useMemo } from "react";

import MakoListagem from "@/components/MakoListagem";

import { gerarFiltrosUrl, gerarStatusBooleanTemplate } from "@/assets/util/util";
import { TIPO_CHOICE_OPERACAO_ADMINISTRADORACARTAO } from "@/assets/constants/constants";

const URL_BACKEND = "/financeiro/administradora-cartao/";

const BASE_COLUMN_ACTIONS = {
    field: "actions",
    header: "Ações",
    style: { width: "10%" },
};

const BASE_COLUMNS = [
    { field: "nome", header: "Nome" },
    {
        field: "operacao",
        header: "Operação",
        action: ({ operacao }) => {
            return TIPO_CHOICE_OPERACAO_ADMINISTRADORACARTAO.find(({ value }) => value === operacao)?.label;
        },
    },
    {
        field: "ativo",
        header: "Ativo",
        style: { width: "6%" },
        align: "center",
        action: ({ ativo }) => gerarStatusBooleanTemplate(ativo, "ATIVO", "INATIVO"),
    },
];

export const Listagem = ({
    actions,
    titulo = "Administradoras de cartão",
    listagemProps = {},
    configTabela = {},
    filtros = {},
    listagemRef = null,
}) => {
    const url = useMemo(() => {
        return `${URL_BACKEND}?${gerarFiltrosUrl(filtros)}`;
    }, [filtros]);

    const colunas = useMemo(() => {
        if (actions) return [...BASE_COLUMNS, { ...BASE_COLUMN_ACTIONS, action: actions }];
        return BASE_COLUMNS;
    }, [actions]);

    return (
        <MakoListagem
            ref={listagemRef}
            titulo={titulo}
            colunas={colunas}
            urlPesquisa={url}
            {...listagemProps}
            configTabela={{
                scrollable: true,
                lazy: true,
                ...configTabela,
            }}
        />
    );
};
