import React, { useEffect, memo, forwardRef, useRef, useState, useCallback } from "react";
import { Button } from "primereact/button";
import { useFormik } from "formik";
import * as Yup from "yup";
import useToast from "@/hooks/useToast";
import useModeloContrato from "@/hooks/useModeloContrato";
import MakoListagem from "@/components/MakoListagem";
import useHttp from "@/hooks/useHttp";
import { Editor } from "primereact/editor";
import { Menu } from "primereact/menu";
import useAuth from "@/hooks/useAuth";
import { MAKO_ICONS } from "@/assets/constants/constants_styles";
import { ModalAdicionarClausula } from "./modalAdicionarClausula";
import { ModalEditarClausula } from "./modalEditarClausula";

const ClausulasForm = () => {
    const [clausulas, setClausulas] = useState([]);
    const [clausula, setClausula] = useState(null);
    const { handleClausula, dadosBasicos } = useModeloContrato();
    const { showError, showSuccess } = useToast();
    const { httpGet, httpPut, httpPatch } = useHttp();
    const { user } = useAuth();

    const modalAdicionarRef = useRef();
    const modalEditarRef = useRef();
    const listagemRef = useRef();
    const menu = useRef();

    const { setFieldValue, setValues, resetForm, ...formik } = useFormik({
        enableReinitialize: true,
        initialValues: {
            id: null,
            modelo: null,
            clausula: null,
            ordem: null,
            ativo: true,
            data_desativacao: null,
            usuario_desativou: null,
        },
        onSubmit: handleSubmit,
    });

    async function handleSubmit(values) {
        try {
            const formSchema = Yup.object().shape({
                clausula: Yup.object().required("O campo 'cláusula' é obrigatório."),
            });

            await formSchema.validate(values, {
                abortEarly: false,
            });

            handleClausula({
                ...values,
                clausula: values.clausula?.id,
                modelo: dadosBasicos.id,
                ordem: clausulas.length + 1,
            });
            resetForm();
            carregaClausulas();
        } catch (error) {
            if (error instanceof Yup.ValidationError) {
                let errorMessages = {};
                error.inner.forEach((err) => {
                    errorMessages[err.path] = err.message;
                });
                formik.setErrors(errorMessages);
            } else showError();
        }
    }

    const colunas = [
        { rowReorder: true, rowReorderIcon: "pi pi-arrows-v", style: { minWidth: "8px" } },
        {
            field: "clausula.parte.identificador",
            header: "Secção",
            action: ({ clausula, ...row }) => {
                return (
                    <Button
                        text
                        label={`${clausula.id}) ${clausula.nome}`}
                        className="p-button-rounded p-button-info"
                        model={opcoes}
                        style={{ color: "black" }}
                        onClick={(event) => {
                            menu.current?.toggle(event);
                            setClausula({ ...row, clausula });
                        }}
                    />
                );
            },
        },
        { field: "ordem_formatada", header: "Nº", style: { minWidth: "50px" } },
        {
            field: "clausula.texto_clausula",
            header: "Início do texto...",
            style: { minWidth: "300px" },
            action: ({ clausula }) => (
                <Editor showHeader={false} value={clausula?.texto_clausula} style={{ height: "80px" }} readOnly />
            ),
        },
    ];

    const carregaClausulas = useCallback(async () => {
        const handlers = {
            200: ({ data }) => setClausulas(data.results),
        };

        await httpGet(
            {
                url: `/servicos/clausulas-modelo-contrato/${dadosBasicos?.id}/ordenar`,
            },
            handlers
        );
    }, [httpGet, dadosBasicos?.id]);

    const atualizaOrdem = useCallback(
        async (clausulas, pula_numeracao = false) => {
            let _CLAUSULAS = clausulas;
            if (!pula_numeracao)
                _CLAUSULAS = clausulas.map((e, index) => {
                    return { ...e, ordem: index + 1 };
                });

            setClausulas(_CLAUSULAS);
            await httpPut(
                {
                    url: `/servicos/atualizar-ordem-clausulas-modelo/`,
                    body: _CLAUSULAS.map(({ clausula, ...e }) => {
                        return { ...e, clausula: clausula.id };
                    }),
                },
                {}
            );
        },
        [httpPut]
    );

    async function handleDesativar(values) {
        const handlers = {
            200: async () => {
                showSuccess({
                    summary: "Sucesso",
                    detail: `A cláusula do modelo de contrato foi desativada com sucesso.`,
                    life: 3000,
                });
                await carregaClausulas();
                setTimeout(() => {
                    atualizaOrdem(clausulas.filter(({ id }) => id !== values.id));
                }, [300]);
            },
        };

        await httpPatch(
            {
                url: `/servicos/clausulas-modelo-contrato/${values.id}/`,
                body: {
                    data_desativacao: new Date(),
                    usuario_desativou: user?.id,
                },
            },
            handlers
        );
    }

    async function handleInsereClausula(clausula, posicao = "cima") {
        // REPENSAR PROCESSAMENTO
        let inicio = [],
            fim = [];

        clausulas.forEach(({ id }, index) => {
            if (id === clausula?.id) {
                if (posicao === "baixo") {
                    inicio = clausulas.slice(0, index + 1);
                    fim = clausulas.slice(index + 1, clausulas.length);
                } else {
                    inicio = clausulas.slice(0, index);
                    fim = clausulas.slice(index, clausulas.length);
                }
            }
        });

        const _CLAUSULAS = [
            ...inicio,
            ...[
                {
                    ...formik.values,
                },
            ],
            ...fim,
        ].map((e, index) => {
            return { ...e, ordem: index + 1 };
        });

        handleClausula({
            ...formik.values,
            clausula: formik.values.clausula?.id,
            modelo: dadosBasicos.id,
            ordem: _CLAUSULAS.find(({ id }) => !id).ordem,
        });
        resetForm();
        setClausulas(_CLAUSULAS);
        setTimeout(() => {
            atualizaOrdem(_CLAUSULAS, true);
        }, [400]);
    }

    const opcoes = [
        {
            items: [
                {
                    label: "Adicionar em cima",
                    disabled: !!!formik.values.clausula,
                    command: () => {
                        modalAdicionarRef.current?.show(
                            clausula?.clausula?.id,
                            modalAdicionarRef.current?.TIPOS_POSICAO.ACIMA
                        );
                    },
                },
                {
                    label: "Adicionar em baixo",
                    disabled: !!!formik.values.clausula,
                    command: () => {
                        modalAdicionarRef.current?.show(
                            clausula?.clausula?.id,
                            modalAdicionarRef.current?.TIPOS_POSICAO.ABAIXO
                        );
                    },
                },
                {
                    label: "Editar",
                    command: () => modalEditarRef.current?.show(clausula),
                },
                {
                    label: "Remover",
                    command: () => handleDesativar(clausula),
                },
            ],
        },
    ];

    const onSuccessEdicao = useCallback(() => {
        carregaClausulas();
    }, [carregaClausulas]);

    useEffect(() => {
        carregaClausulas();
        modalEditarRef.current?.hide();
    }, [carregaClausulas]);

    return (
        <>
            <Menu model={opcoes} popup ref={menu} id="popup_menu" />
            <div className="p-mb-2">
                <MakoListagem
                    ref={listagemRef}
                    colunas={colunas}
                    dadosLocal={clausulas}
                    filtarPorEmpresa
                    fieldFiltroEmpresa="empresa"
                    configTabela={{
                        className: "mako-table",
                        reorderableColumns: true,
                        reorderableRows: true,
                        onRowReorder: ({ value }) => atualizaOrdem(value),
                    }}
                />
            </div>
            {!!clausulas.length && <span>OBS: É possível arrastar as cláusulas para alterar suas ordens.</span>}
            {!clausulas.length && (
                <div className="p-d-flex p-ai-center p-jc-center p-mt-4">
                    <Button
                        icon={MAKO_ICONS.NOVO}
                        label="Adicionar 1° cláusula"
                        severity="success"
                        type="button"
                        onClick={() => modalAdicionarRef.current?.show()}
                    />
                </div>
            )}
            <ModalAdicionarClausula ref={modalAdicionarRef} />
            <ModalEditarClausula ref={modalEditarRef} onSuccessCallback={onSuccessEdicao} />
        </>
    );
};

export default memo(forwardRef(ClausulasForm));
