import React, { useMemo } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { MakoMultiTabs } from "@/components/MakoMultiTabs";
import { FormFiscal } from "./formDocFiscal";
import FormApontamentos from "./formApontamentos";
import FormDadosBasicos from "./formDadosBasicos";
import FormFinanceiro from "./formFinanceiro";
import FormServicos from "./formServicos";
import FormFaturamentos from "./formFaturamentos";
import useContratoServico from "@/hooks/useContratoServico";

export const TabContratoServico = () => {
    const { contrato } = useContratoServico();
    const { state } = useLocation();
    const history = useHistory();

    const voltarParaListagem = () => {
        history.push("/servicos/servicos/contrato-servico");
    };

    const titleTemplate = useMemo(() => {
        return !contrato?.id
            ? "Cadastro de contrato de prestação de serviço"
            : `Edição do contrato: ${contrato?.numero} - ${contrato?.nome_curto}`;
    }, [contrato]);

    const tabs = [
        {
            header: "Dados Básicos",
            icon: "pi pi-fw pi-bookmark",
            component: <FormDadosBasicos />,
        },
        {
            header: "Serviços",
            icon: "pi pi-fw pi-cog",
            component: <FormServicos />,
            visible: !!contrato,
        },
        {
            header: "Apontamentos",
            icon: "pi pi-fw pi-book",
            component: <FormApontamentos />,
            visible: contrato?.status === "F",
        },
        {
            header: "Faturamento",
            icon: "fas pi-fw fa-file",
            component: <FormFaturamentos />,
            visible: contrato?.status === "F",
        },
        {
            header: "Financeiro",
            icon: "pi pi-fw pi-dollar",
            component: <FormFinanceiro />,
            visible: contrato?.status === "F",
        },
    ];

    return (
        <div className="p-grid">
            <div className="p-col-12">
                <div>
                    <MakoMultiTabs
                        tabs={tabs}
                        title={titleTemplate}
                        propsButtonProx={{ disabled: !contrato }}
                        ocultarButtonFinalizar
                        // propsButtonFinalizar={{ type: "button", onClick: handleFinalizar }}
                        propsButtonCancelar={{ onClick: voltarParaListagem, label: "Fechar" }}
                        tabAtiva={state?.tabAtiva ? state?.tabAtiva : 0}
                    />
                </div>
            </div>
        </div>
    );
};
