import React, { useEffect, useMemo, useRef } from "react";

import * as Yup from "yup";
import { classNames } from "primereact/utils";
import { useFormik } from "formik";

import { Button } from "primereact/button";

import { DropdownContasFinanceiras, DropdownFormasRecebimentos } from "../../../../../../components";
import { MakoInputMoeda } from "@/components/MakoInputMoeda";
import { Label } from "@/components/Label";

import { MAKO_ICONS } from "@/assets/constants/constants_styles";

import useReceberCrediario from "../../../../hooks/useReceberCrediario";
import useClearRefs from "@/hooks/useClearRefs";

const INITIAL_VALUES = {
    conta_financeira: null,
    forma_recebimento: null,
    valor: 0,
};

export const Form = ({ successCallback = () => {} }) => {
    const { info, pendencia } = useReceberCrediario();
    const dropFormasRef = useRef(null);

    useClearRefs(dropFormasRef);

    const { setValues, setFieldValue, ...formik } = useFormik({
        initialValues: INITIAL_VALUES,
        onSubmit: handleSubmit,
    });

    const { grupo, tipo_chave } = pendencia || {};

    async function handleSubmit(values, formikHelpers) {
        try {
            const formSchema = Yup.object().shape({
                conta_financeira: Yup.number()
                    .required("O campo 'conta financeira' é obrigatório")
                    .typeError("Informe um 'conta financeira' válido"),
                forma_recebimento: Yup.number()
                    .required("O campo 'forma recebimento' é obrigatório")
                    .typeError("Informe um 'forma recebimento' válido"),
                valor: Yup.number()
                    .min(0.01, "Informe um 'valor' maior que 0")
                    .required("O campo 'valor' é obrigatório")
                    .typeError("Informe um 'valor' válido"),
            });

            await formSchema.validate(values, { abortEarly: false });

            const { forma_recebimento, conta_financeira, valor } = values;
            const data = { forma_recebimento, conta_financeira, tipo_chave, valor_receber: valor, id: pendencia?.id };

            dropFormasRef.current?.show(data);
        } catch (error) {
            if (error instanceof Yup.ValidationError) {
                let errorMessages = {};
                error.inner.forEach((err) => {
                    errorMessages[err.path] = err.message;
                });
                formikHelpers.setErrors(errorMessages);
            }
        }
    }

    const formsProps = useMemo(() => {
        return {
            0: {
                bodySerializer: ({ conta_financeira, forma_recebimento, tipo_chave, valor_original }) => {
                    return {
                        conta_financeira,
                        forma_recebimento,
                        tipo_chave,
                        valor_original,
                        grupo: grupo?.id,
                    };
                },
            },
            1: {
                bodySerializer: ({
                    conta_financeira,
                    forma_recebimento,
                    tipo_chave,
                    administradora,
                    bandeira,
                    data_autorizacao,
                    maquineta,
                    nsu,
                    numero_autorizacao,
                    percentual_taxa,
                    valor_calculado,
                    valor_liquido,
                    valor_original,
                    valor_taxa,
                    quantidade_parcelas,
                }) => {
                    return {
                        conta_financeira,
                        forma_recebimento,
                        tipo_chave,
                        administradora,
                        bandeira,
                        data_autorizacao,
                        maquineta,
                        nsu,
                        numero_autorizacao,
                        percentual_taxa,
                        valor_calculado,
                        valor_liquido,
                        valor_original,
                        valor_taxa,
                        quantidade_parcelas,
                        grupo: grupo?.id,
                    };
                },
            },
            2: {
                bodySerializer: ({
                    conta_financeira,
                    forma_recebimento,
                    tipo_chave,
                    administradora,
                    bandeira,
                    data_autorizacao,
                    maquineta,
                    nsu,
                    numero_autorizacao,
                    percentual_taxa,
                    valor_calculado,
                    valor_liquido,
                    valor_original,
                    valor_taxa,
                    quantidade_parcelas,
                }) => {
                    return {
                        conta_financeira,
                        forma_recebimento,
                        tipo_chave,
                        administradora,
                        bandeira,
                        data_autorizacao,
                        maquineta,
                        nsu,
                        numero_autorizacao,
                        percentual_taxa,
                        valor_calculado,
                        valor_liquido,
                        valor_original,
                        valor_taxa,
                        quantidade_parcelas,
                        grupo: grupo?.id,
                    };
                },
            },
            3: {
                bodySerializer: ({
                    conta_financeira,
                    forma_recebimento,
                    tipo_chave,
                    natureza,
                    chave,
                    comprovante,
                    valor_pix,
                    valor_taxa,
                    datahora_requisicao,
                    datahora_transacao,
                }) => {
                    return {
                        conta_financeira,
                        forma_recebimento,
                        tipo_chave,
                        natureza,
                        chave,
                        comprovante,
                        valor_pix,
                        valor_taxa,
                        datahora_requisicao,
                        datahora_transacao,
                        grupo: grupo?.id,
                    };
                },
            },
        };
    }, [grupo]);

    const disabledForm = useMemo(() => {
        return parseFloat(info?.total_receber) === 0;
    }, [info?.total_receber]);

    useEffect(() => {
        setFieldValue("valor", info?.total_receber || 0);
    }, [info?.total_receber, setFieldValue]);

    return (
        <>
            <form onSubmit={formik.handleSubmit}>
                <div className="p-fluid p-formgrid p-grid">
                    <div className="p-field p-col-12 p-md-5">
                        <Label htmlFor="conta_financeira" label="Conta financeira" obrigatorio />
                        <DropdownContasFinanceiras
                            id="conta_financeira"
                            name="conta_financeira"
                            filtros={{
                                ativa: true,
                            }}
                            apenasCaixa
                            value={formik.values.conta_financeira}
                            onChange={formik.handleChange}
                            disabled={disabledForm}
                            className={classNames({ "p-invalid": formik.errors.conta_financeira })}
                        />
                        {formik.errors.conta_financeira && (
                            <small className="p-error">{formik.errors.conta_financeira}</small>
                        )}
                    </div>
                    <div className="p-field p-col-12 p-md-5">
                        <Label htmlFor="forma_recebimento" label="Forma de recebimento" obrigatorio />
                        <DropdownFormasRecebimentos
                            ref={dropFormasRef}
                            id="forma_recebimento"
                            name="forma_recebimento"
                            showClear={false}
                            conta_financeira={formik.values.conta_financeira}
                            value={formik.values.forma_recebimento}
                            onChange={formik.handleChange}
                            successCallback={successCallback}
                            formsProps={formsProps}
                            disabled={disabledForm}
                            className={classNames({ "p-invalid": formik.errors.forma_recebimento })}
                        />
                        {formik.errors.forma_recebimento && (
                            <small className="p-error">{formik.errors.forma_recebimento}</small>
                        )}
                    </div>
                    <div className="p-field p-col-12 p-md-2">
                        <Label htmlFor="valor" label="Valor" />
                        <div className="p-inputgroup">
                            <MakoInputMoeda
                                id="valor"
                                name="valor"
                                valueMoeda={formik.values.valor}
                                onChangeMoeda={formik.handleChange}
                                max={info.total_receber}
                                disabled={disabledForm}
                                className={classNames({ "p-invalid": formik.errors.valor })}
                            />
                            <Button
                                icon={MAKO_ICONS.NOVO}
                                type="button"
                                onClick={formik.handleSubmit}
                                disabled={disabledForm}
                                className="p-button-success"
                            />
                        </div>
                        {formik.errors.valor && <small className="p-error">{formik.errors.valor}</small>}
                    </div>
                </div>
            </form>
        </>
    );
};
