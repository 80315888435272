import React, { useMemo } from "react";

import classNames from "classnames";

import { useFormik } from "formik";
import * as Yup from "yup";

import { Button } from "primereact/button";

import { MakoActionsButtons } from "@/components/MakoActionsButtons";
import { MakoCalendar } from "@/components/MakoCalendar";
import { Dropdown } from "@/components/Dropdown";
import { Label } from "@/components/Label";

import { MAKO_ICONS } from "@/assets/constants/constants_styles";
import { dataToStr, parseData } from "@/assets/util/datas";

import useContratoServico from "@/hooks/useContratoServico";

export const ModalFaturamento = ({ onFinish, onClose }) => {
    const { contrato } = useContratoServico();

    const { setValues, setFieldValue, ...formik } = useFormik({
        initialValues: {
            data_inicial: parseData(contrato.data_iniciacao),
            data_final: parseData(contrato.data_finalizacao),
            endereco_prestacao_id: null,
            template_rateio: contrato.template_rateio,
        },
        onSubmit: handleSubmit,
    });

    async function handleSubmit(values) {
        try {
            const formSchema = Yup.object().shape({
                data_inicial: Yup.date()
                    .required("O campo 'data inicial' é obrigatório")
                    .typeError("Informe uma 'data inicial' válida"),
                data_final: Yup.date().when("data_inicial", {
                    is: (val) => !!val,
                    then: Yup.date()
                        .min(values.data_inicial || new Date(), "O campo 'data final' não pode ser anterior a inicial")
                        .required("O campo 'data final' é obrigatório")
                        .typeError("Informe uma 'data final' válida"),
                }),
                template_rateio: Yup.number()
                    .required("O campo 'template de ratio' é obrigatório.")
                    .typeError("Informe uma 'template de ratio' válida."),
            });
            await formSchema.validate(values, { abortEarly: false });
            onFinish({
                ...values,
                data_inicial: dataToStr(values.data_inicial, "yyyy-MM-dd"),
                data_final: dataToStr(values.data_final, "yyyy-MM-dd"),
            });
        } catch (error) {
            if (error instanceof Yup.ValidationError) {
                let errorMessages = {};

                error.inner.forEach((err) => {
                    errorMessages[err.path] = err.message;
                });

                formik.setErrors(errorMessages);
            }
        }
    }

    const aposBuscarEnderecos = useMemo(() => {
        return (dados) => {
            return dados.map((endereco) => {
                endereco.label = `${endereco.identificacao} - ${endereco.cidade.nome}, ${endereco.bairro}, ${endereco.logradouro} Nº ${endereco.numero}`;
                return endereco;
            });
        };
    }, []);

    return (
        <>
            <form onSubmit={formik.handleSubmit}>
                <div className="p-fluid p-formgrid p-grid">
                    <div className="p-field p-col-12 p-md-3">
                        <Label htmlFor="data_inicial" label="Data Inicial" obrigatorio />
                        <MakoCalendar
                            id="data_inicial"
                            name="data_inicial"
                            valueCalendar={formik.values.data_inicial}
                            onChange={formik.handleChange}
                            className={classNames({ "p-invalid": formik.errors.data_inicial })}
                        />
                        {formik.errors.data_inicial && <small className="p-error">{formik.errors.data_inicial}</small>}
                    </div>
                    <div className="p-field p-col-12 p-md-3">
                        <Label htmlFor="data_final" label="Data final" obrigatorio />
                        <MakoCalendar
                            id="data_final"
                            name="data_final"
                            valueCalendar={formik.values.data_final}
                            onChange={formik.handleChange}
                            className={classNames({ "p-invalid": formik.errors.data_final })}
                        />
                        {formik.errors.data_final && <small className="p-error">{formik.errors.data_final}</small>}
                    </div>
                    <div className="p-field p-col-12 p-md-6">
                        <Label htmlFor="template_rateio" label="Regra de rateio" obrigatorio />
                        <Dropdown
                            id="template_rateio"
                            name="template_rateio"
                            url={`/financeiro/templates-rateios/?query={id,descricao}`}
                            optionValue="id"
                            optionLabel="descricao"
                            emptyMessage="Não existem registros cadastrados"
                            value={formik.values.template_rateio}
                            onChange={formik.handleChange}
                            className={classNames({ "p-invalid": formik.errors.template_rateio })}
                        />
                        {formik.errors.template_rateio && (
                            <small className="p-error">{formik.errors.template_rateio}</small>
                        )}
                    </div>
                    <div className="p-field p-col-12 p-md-12">
                        <Label
                            htmlFor="endereco_prestacao_id"
                            label="Endereço prestação serviço (cliente)"
                            obrigatorio
                        />
                        <Dropdown
                            id="endereco_prestacao_id"
                            name="endereco_prestacao_id"
                            url={`/pessoas/enderecos-perfis/?perfil__id=${contrato.cliente.id}`}
                            aposBuscar={aposBuscarEnderecos}
                            optionValue="id"
                            optionLabel="label"
                            emptyMessage="Não existem registros cadastrados"
                            value={formik.values.endereco_prestacao_id}
                            onChange={formik.handleChange}
                            className={classNames({ "p-invalid": formik.errors.endereco_prestacao_id })}
                        />
                        {formik.errors.endereco_prestacao_id && (
                            <small className="p-error">{formik.errors.endereco_prestacao_id}</small>
                        )}
                    </div>
                </div>
                <MakoActionsButtons className="p-mt-2">
                    <Button icon={MAKO_ICONS.GRAVAR} label="Gravar" type="submit" className="p-button-info" />
                    <Button
                        icon={MAKO_ICONS.LIMPAR_FORM}
                        label="Limpar"
                        type="button"
                        className="p-button-warning"
                        onClick={formik.resetForm}
                    />
                    <Button
                        label="Cancelar"
                        icon={MAKO_ICONS.CANCEL}
                        type="button"
                        className="p-button-danger"
                        onClick={onClose}
                    />
                </MakoActionsButtons>
            </form>
        </>
    );
};
