import React, { useCallback, useRef, useState } from "react";

import { useHistory, useLocation } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { PageBase } from "@/components/PageBase";
import { Dropdown } from "@/components/Dropdown";
import { Label } from "@/components/Label";
import { SIM_NAO_BOOLEAN } from "@/assets/constants/constants";
import useLoading from "@/hooks/useLoading";
import useToast from "@/hooks/useToast";
import classNames from "classnames";
import useHttp from "@/hooks/useHttp";
import { SelectButton } from "primereact/selectbutton";
import { MakoCalendar } from "@/components/MakoCalendar";
import useAuth from "@/hooks/useAuth";
import { Editor } from "primereact/editor";
import { OverlayPanel } from "primereact/overlaypanel";
import MakoListagem from "@/components/MakoListagem";
import { ConfirmDialog } from "primereact/confirmdialog";
import { MakoDropdownClausulaContrato } from "@/components/MakoInputs";

export const ClausulaContratoForm = () => {
    const [confirmacao, setConfirmacao] = useState(false);
    const { showLoading, hideLoading } = useLoading(); // DEPRECIADO PRIMO, AJUSTAR PF 👍 useLocalLoading nos botoes
    const { showSuccess, showError } = useToast();
    const { state } = useLocation();
    const { user } = useAuth();
    const history = useHistory();
    const { httpPost, httpPatch } = useHttp();
    const overlayRef = useRef();

    const { setValues, setFieldValue, resetForm, ...formik } = useFormik({
        initialValues: state
            ? { ...state, vinculada_clausula: !!state.clausula_mae, parte: state.parte.id }
            : {
                  nome: "",
                  texto_clausula: "",
                  clausula_mae: null,
                  parte: null,
                  vinculada_clausula: false,
                  paragrafo: false,
                  data_criacao: new Date(),
                  usuario_criou: user,
                  situacao: { id: "E", descricao: "Elaboração" },
              },
        onSubmit: handleSubmit,
    });

    async function handleSubmit({ situacao, ...values }) {
        try {
            const formSchema = Yup.object().shape({
                nome: Yup.string().required("O campo 'nome' é obrigatório").typeError("Informe um nome válido"),
                clausula_mae: values.vinculada_clausula
                    ? Yup.number().required("O campo é obrigatório").typeError("Informe uma cláusula válida")
                    : null,
                texto_clausula: Yup.string()
                    .required("O campo 'cláusula' é obrigatório")
                    .typeError("Informe um informações válidas"),
                parte: Yup.number()
                    .required("O campo 'secção' é obrigatório.")
                    .typeError("Informe uma 'secção' válida"),
            });

            await formSchema.validate(values, { abortEarly: false });

            if (!values.id) {
                const handlers = {
                    201: () => {
                        showSuccess({
                            summary: "Sucesso",
                            detail: "Cláusula de contrato cadastrada com sucesso!",
                            life: 1500,
                        });
                        history.push("/servicos/cadastros/clausulas-contrato");
                    },
                };

                showLoading();
                await httpPost(
                    {
                        url: "/servicos/clausulas-contrato/",
                        body: { ...values, usuario_criou: user.id, situacao: situacao.id },
                    },
                    handlers
                );
                hideLoading();
            } else {
                const handlers = {
                    200: () => {
                        showSuccess({
                            summary: "Sucesso",
                            detail: "Cláusula de contrato alterada com sucesso!",
                            life: 1500,
                        });
                        history.push("/servicos/cadastros/clausulas-contrato");
                    },
                };

                showLoading();
                await httpPatch(
                    {
                        url: `/servicos/clausulas-contrato/${values.id}/`,
                        body: {
                            ...values,
                            usuario_criou: values.usuario_criou.id,
                            situacao: "E",
                            data_alteracao: new Date(),
                            usuario_alterou: user.id,
                        },
                    },
                    handlers
                );
                hideLoading();
            }
        } catch (error) {
            if (error instanceof Yup.ValidationError) {
                let errorMessages = {};
                error.inner.forEach((err) => {
                    errorMessages[err.path] = err.message;
                });
                formik.setErrors(errorMessages);
            } else showError();
        }
    }

    const onChangeVinculadaClausula = useCallback(
        (e) => {
            if (!e.value) setFieldValue("paragrafo", false);
            setFieldValue("clausula_mae", null);
            formik.handleChange(e);
        },
        [formik, setFieldValue]
    );

    return (
        <PageBase>
            <h5>{formik.values.id ? "Editando Cláusula Contratual" : "Incluindo Cláusula Contratual"}</h5>
            <form onSubmit={formik.handleSubmit}>
                <div className="p-fluid p-formgrid p-grid">
                    <div className="p-field p-col-12 p-md-3">
                        <Label htmlFor="nome" label="Descrição" obrigatorio />
                        <InputText
                            id="nome"
                            name="nome"
                            value={formik.values.nome}
                            onChange={formik.handleChange}
                            className={classNames({ "p-invalid": formik.errors.nome })}
                        />
                        {formik.errors.nome && <small className="p-error">{formik.errors.nome}</small>}
                    </div>
                    <div className="p-field p-col-12 p-md-3">
                        <Label htmlFor="parte" label="Seção" obrigatorio />
                        <Dropdown
                            id="parte"
                            name="parte"
                            url={`/servicos/partes-contrato?limit=100`}
                            placeholder="Selecione uma secção..."
                            optionValue="id"
                            optionLabel="identificador"
                            filter
                            filterBy="identificador"
                            showClear={false}
                            onChange={formik.handleChange}
                            value={formik.values.parte}
                            className={classNames({ "p-invalid": formik.errors.parte })}
                        />
                        {formik.errors.parte && <small className="p-error">{formik.errors.parte}</small>}
                    </div>
                    <div className="p-field p-col-12 p-md-3">
                        <Label htmlFor="vinculada_clausula" label="Vinculada a cláusula" />
                        <SelectButton
                            id="vinculada_clausula"
                            name="vinculada_clausula"
                            value={formik.values.vinculada_clausula}
                            onChange={onChangeVinculadaClausula}
                            optionValue="id"
                            optionLabel="label"
                            options={SIM_NAO_BOOLEAN}
                        />
                    </div>
                    <div className="p-field p-col-12 p-md-3">
                        <Label htmlFor="clausula_mae" label="Cláusula mãe" />
                        <MakoDropdownClausulaContrato
                            id="clausula_mae"
                            name="clausula_mae"
                            placeholder="Selecione uma cláusula mãe"
                            disabled={!!!formik.values.vinculada_clausula}
                            onChange={formik.handleChange}
                            value={formik.values.clausula_mae}
                            className={classNames({ "p-invalid": formik.errors.clausula_mae })}
                        />
                        {formik.errors.clausula_mae && <small className="p-error">{formik.errors.clausula_mae}</small>}
                    </div>
                </div>
                <div className="p-fluid p-formgrid p-grid">
                    <div className="p-field p-col-12 p-md-3">
                        <Label htmlFor="paragrafo" label="Parágrafo" />
                        <SelectButton
                            id="paragrafo"
                            name="paragrafo"
                            value={formik.values.paragrafo}
                            disabled={!formik.values.vinculada_clausula}
                            onChange={formik.handleChange}
                            optionValue="id"
                            optionLabel="label"
                            options={SIM_NAO_BOOLEAN}
                        />
                    </div>
                </div>
                <div className="p-fluid p-formgrid p-grid">
                    <div className="p-field p-col-12 p-md-12">
                        <Label label="Cláusula/Parágrafo" obrigatorio />
                        <OverlayPanel ref={overlayRef} style={{ width: "400px" }} showCloseIcon>
                            <MakoListagem
                                colunas={[
                                    { field: "campo", header: "Tag" },
                                    { field: "descricao", header: "Campo" },
                                ]}
                                urlPesquisa={`/servicos/variaveis-contrato?limit=1000`}
                                configTabela={{
                                    selectionMode: "single",
                                    responsiveLayout: "scroll",
                                    onSelectionChange: (e) => {
                                        setFieldValue(
                                            "texto_clausula",
                                            `${formik.values.texto_clausula.replace("/", "")} ${e.value.campo}`
                                        );
                                        overlayRef.current.toggle(e);
                                    },
                                }}
                            />
                        </OverlayPanel>
                        <Editor
                            id="texto_clausula"
                            name="texto_clausula"
                            value={formik.values.texto_clausula}
                            onTextChange={(e) => setFieldValue("texto_clausula", e.htmlValue)}
                            onInput={(e) => {
                                if (e.nativeEvent.data === "/") overlayRef.current.toggle(e);
                            }}
                            style={{ height: "200px" }}
                            className={classNames({ "p-invalid": formik.errors.texto_clausula })}
                        />
                        {formik.errors.texto_clausula && (
                            <small className="p-error">{formik.errors.texto_clausula}</small>
                        )}
                    </div>
                </div>
                <div className="p-fluid p-formgrid p-grid">
                    <div className="p-field p-col-12 p-md-3">
                        <Label htmlFor="data_criacao" label="Data do cadastro" />
                        <MakoCalendar
                            id="data_criacao"
                            name="data_criacao"
                            valueCalendar={formik.values.data_criacao}
                            readOnly
                            disabled
                        />
                    </div>
                    <div className="p-field p-col-12 p-md-4">
                        <Label htmlFor="usuario_criou" label="Cadastrado por:" />
                        <InputText
                            id="usuario_criou"
                            name="usuario_criou"
                            value={formik.values.usuario_criou?.nome}
                            readOnly
                            disabled
                        />
                    </div>
                    <div className="p-field p-col-12 p-md-3">
                        <Label htmlFor="situacao" label="Situação" />
                        <InputText
                            id="situacao"
                            name="situacao"
                            value={formik.values.situacao?.descricao}
                            readOnly
                            disabled
                        />
                    </div>
                </div>
                <div className="p-grid p-col-12 p-md-6">
                    <Button
                        icon="pi pi-save"
                        label="Gravar"
                        type="button"
                        className="p-button-info p-mr-2"
                        onClick={() => {
                            if (formik.values.id) setConfirmacao(true);
                            else formik.handleSubmit(formik.values);
                        }}
                    />
                    <Button
                        label="Cancelar"
                        type="button"
                        className="p-button-danger"
                        onClick={() => history.push("/servicos/cadastros/clausulas-contrato")}
                    />
                </div>
            </form>
            <ConfirmDialog
                visible={confirmacao}
                onHide={() => setConfirmacao(false)}
                message={
                    <label>
                        Deseja realmente registrar a alteração? A situação da cláusula será retornada para{" "}
                        <b>'Elaboração'</b>.
                    </label>
                }
                header="Confirmação"
                accept={() => handleSubmit(formik.values)}
                reject={() => setConfirmacao(false)}
                acceptLabel="Confirmar"
                rejectClassName="p-button-danger p-button-text"
                rejectLabel="Cancelar"
            />
        </PageBase>
    );
};
