import React, { useRef } from "react";

import MakoListagem from "@/components/MakoListagem";
import { MakoControleAcesso } from "@/components/MakoControleAcesso";
import { MakoButton as Button } from "@/components/MakoButton";
import { PageBase } from "@/components/PageBase";
import permissoes from "@/assets/constants/permissoes";
import { MAKO_ICONS } from "@/assets/constants/constants_styles";
import { BUTTON_LISTAGEM_EDITAR } from "@/assets/constants/presets";
import { MakoActionsButtonsColumn } from "@/components/MakoActionsButtonsColumn";
import { gerarStatusBooleanTemplate } from "@/assets/util/util";
import { ButtonListagemOpcoes } from "@/components/ButtonListagemOpcoes";

export const RedesSociaisPage = () => {
    const listagemRef = useRef(null);

    const cabecalhoTabela = (
        <>
            <MakoControleAcesso
                permissao={[permissoes.GESTAO_CADASTRO_REDESOCIAL_INCLUIR]}
                componente={Button}
                label="Nova"
                icon={MAKO_ICONS.NOVO}
                className="p-button-success p-mr-2"
                to={"/gestao/cadastros/redes-sociais/form"}
            />
        </>
    );

    const actionBodyTemplate = (rowData) => {
        return (
            <MakoActionsButtonsColumn>
                <MakoControleAcesso
                    permissao={[permissoes.GESTAO_CADASTRO_REDESOCIAL_EDITAR]}
                    componente={Button}
                    {...BUTTON_LISTAGEM_EDITAR}
                    to={{
                        pathname: "/gestao/cadastros/redes-sociais/form",
                        state: rowData,
                    }}
                />
                <ButtonListagemOpcoes
                    url="/pessoas/redes-sociais"
                    objetoId={rowData.id}
                    objetoLabel={rowData.nome}
                    prefixo="a"
                    entidade="rede social"
                    onAction={() => listagemRef.current?.buscarDados()}
                    deleteOptions={{
                        exigeConfirmacao: true,
                        msgConfirmacao: (
                            <span>
                                Deseja realmente excluir a rede social <b>{rowData.nome}</b>?
                            </span>
                        ),
                        msgToastErroExclusao: "A rede social não pode ser excluída.",
                    }}
                    visualizarOptions={{ disabled: true }}
                />
            </MakoActionsButtonsColumn>
        );
    };

    const colunas = [
        {
            field: "nome",
            header: "Rede social",
        },
        {
            field: "url_base",
            header: "Url",
            style: { width: "25%" },
        },
        {
            field: "ativo",
            header: "Status",
            style: { width: "6%" },
            align: "center",
            action: ({ ativo }) => gerarStatusBooleanTemplate(ativo, "ATIVA", "INATIVA"),
        },
        {
            field: "action",
            header: "Ações",
            style: { width: "8%" },
            action: (e) => actionBodyTemplate(e),
        },
    ];

    return (
        <PageBase>
            <MakoListagem
                ref={listagemRef}
                titulo="Redes sociais"
                colunas={colunas}
                painelEsquerdo={cabecalhoTabela}
                urlPesquisa="/pessoas/redes-sociais/"
                configTabela={{
                    paginator: true,
                    lazy: true,
                }}
            />
        </PageBase>
    );
};
