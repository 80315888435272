import React, { createContext, useCallback, useState } from "react";
import useLoading from "@/hooks/useLoading";
import useToast from "@/hooks/useToast";
import useHttp from "@/hooks/useHttp";
import useAuth from "@/hooks/useAuth";

const ModeloContratoContext = createContext({});

export const ModeloContratoProvider = ({ children }) => {
    const [submit, setSubmit] = useState(false);
    const [dadosBasicos, setDadosBasicos] = useState(null);
    const { showLoading, hideLoading } = useLoading();
    const { user } = useAuth();
    const { showError, showWarning } = useToast();
    const { httpGet, httpPatch, httpPost } = useHttp();

    const handleDadosBasicos = useCallback(
        async (values) => {
            if (!values.id) {
                const handlers = {
                    201: () => setDadosBasicos({ ...values, data_criacao: new Date(), usuario_criou: user }),
                };

                await httpPost(
                    {
                        url: "/servicos/modelos-contrato/",
                        body: {
                            ...values,
                            data_criacao: new Date(),
                            usuario_criou: user?.id,
                            situacao: "E",
                        },
                    },
                    handlers
                );
            } else {
                let diffProd = {};
                Object.entries(values).forEach(([k, v]) => {
                    if (v !== dadosBasicos[k]) {
                        diffProd[k] = v;
                    }
                });

                if (Object.keys(diffProd).length > 0) {
                    const handlers = {
                        200: () => {
                            setDadosBasicos({
                                ...values,
                                usuario_alterou: user,
                                data_alteracao: new Date(),
                                situacao: "E",
                            });
                        },
                    };

                    await httpPatch(
                        {
                            url: `/servicos/modelos-contrato/${values.id}/`,
                            body: { ...diffProd, usuario_alterou: user?.id, data_alteracao: new Date(), situacao: "E" },
                        },
                        handlers
                    );
                }
                return { status: 200, data: values };
            }
        },
        [httpPatch, httpPost, user, dadosBasicos]
    );

    /* const handleValidar = async () => {
         const handlers = {
            200: ({ data }) => setDadosBasicos({ ...data, marca: data.marca?.id || null, cest: data.cest?.id || null }),
            400: ({ err }) => {
                if (err?.cadastro_validado?.length > 0 && err?.cadastro_validado[0] === "False") {
                    let msgErro = "";
                    err.erros.forEach((erro) => {
                        msgErro = `${msgErro} ${msgErro !== "" ? "-" : ""} ${erro}`;
                    });
                    if (msgErro !== "")
                        msgErro = `O cadastro não pode ser validado, por favor verifique as informações. (${msgErro} )`;
                    else msgErro = "O cadastro não pode ser validado, por favor verifique as informações.";

                    showWarning({
                        summary: "Informações pendentes",
                        detail: msgErro,
                        life: 10000,
                    });
                } else {
                    showError({
                        summary: "Falha ao validar",
                        detail: "Desculpe, não foi possível validar o status do produto.",
                        life: 10000,
                    });
                }
            },
        };

        showLoading();
        await httpPatch(
            {
                url: `/produtos/itens/${dadosBasicos.id}/`,
                body: {
                    status: "F",
                },
            },
            handlers
        );
        hideLoading();
    };
*/
    const handleClausula = useCallback(
        async (values, op) => {
            let status = 400,
                json = {};

            const handlers = {
                201: ({ data }) => {
                    status = 201;
                    json = data;
                },
                400: (err) => (json = err),
            };

            await httpPost(
                {
                    url: "/servicos/clausulas-modelo-contrato/",
                    body: values,
                },
                handlers
            );

            return { status, data: json };
        },
        [httpPost]
    );

    const handlePreencherModelo = useCallback(
        async (id) => {
            const handlers = {
                200: ({ data }) => {
                    setDadosBasicos(data);
                },
            };

            showLoading();
            await httpGet({ url: `/servicos/modelos-contrato/${id}/` }, handlers);
            hideLoading();
        },
        [showLoading, hideLoading, httpGet]
    );

    return (
        <ModeloContratoContext.Provider
            value={{
                submit,
                dadosBasicos,
                setSubmit,
                handleDadosBasicos,
                handleClausula,
                handlePreencherModelo,
            }}
        >
            {children}
        </ModeloContratoContext.Provider>
    );
};

export default ModeloContratoContext;
