import React, { forwardRef, useImperativeHandle, useState } from "react";
import { classNames } from "primereact/utils";
import { Dialog } from "primereact/dialog";
import { Password } from "primereact/password";

import { MakoFileUpload } from "@/components/MakoFileUpload";
import { Label } from "@/components/Label";
import useToast from "@/hooks/useToast";
import useHttp from "@/hooks/useHttp";

const Modal = ({ perfilId, onUpload }, ref) => {
    const [visible, setVisible] = useState(false);
    const [senhaCertificado, setSenhaCertificado] = useState("");
    const [erroSenha, setErroSenha] = useState(false);
    const { showSuccess, showCustom } = useToast();
    const { httpPost } = useHttp();

    const abrirModal = () => {
        setVisible(true);
    };

    const fecharModal = () => {
        setSenhaCertificado("");
        setVisible(false);
    };

    useImperativeHandle(ref, () => ({ abrirModal }));

    const uploadCertificado = async (event) => {
        const [file] = event.files;
        const formData = new FormData();
        formData.append("file", file);
        formData.append("senha", senhaCertificado);
        const handlers = {
            success: ({ data }) => {
                showSuccess({
                    summary: "Sucesso!",
                    detail: "Certificado digital enviado com sucesso.",
                    life: 1500,
                });
                if (typeof onUpload === "function") onUpload(data);
                fecharModal();
            },
            error: ({ status, err }) => {
                showCustom({
                    severity: status <= 499 ? "warn" : "error",
                    summary: status <= 499 ? "Opsss" : "Erro :(",
                    detail: err?.msg || "Desculpe, não foi possível processar a sua requisição.",
                    life: 3000,
                });
            },
        };
        const url = `/pessoas/${perfilId}/certificado-digital/upload/`;
        await httpPost({ url, body: formData }, handlers);
    };

    const onSelectCertificado = () => {
        if (!senhaCertificado) setErroSenha(true);
    };

    return (
        <Dialog header="Upload certificado digital" visible={visible} onHide={fecharModal} style={{ width: "20vw" }}>
            <div className="p-fluid p-formgrid p-grid">
                <div className="p-field p-col-12">
                    <Label htmlFor="senha-certificado" label="Senha do certificado" />
                    <Password
                        id="senha-certificado"
                        name="senha"
                        feedback={false}
                        value={senhaCertificado}
                        onFocus={() => setErroSenha(false)}
                        onChange={(e) => setSenhaCertificado(e.target.value)}
                        className={classNames({ "p-invalid": erroSenha })}
                    />
                    {erroSenha && <small className="p-error">Informe a senha do certificado.</small>}
                </div>
                <div className="p-col-12">
                    <MakoFileUpload
                        mode="basic"
                        name="file"
                        accept=".pfx, .p12"
                        customUpload
                        uploadHandler={uploadCertificado}
                        chooseOptions={{ icon: "pi pi-upload", label: "Selecionar e enviar" }}
                        onSelect={onSelectCertificado}
                    />
                </div>
            </div>
        </Dialog>
    );
};

export const ModalUploadCertificado = forwardRef(Modal);
