import React, { useEffect, useCallback, useRef, useState } from "react";
import { useHistory } from "react-router-dom";

import IdentificacaoPFForm from "./formIdentificacaoPF";
import IdentificacaoPJForm from "./formIdentificacaoPJ";
import IdentificacaoPEForm from "./formIdentificacaoPE";
import EnderecoForm from "./formEndereco";
import TelefoneForm from "./formTelefone";
import EmailForm from "./formEmail";
import RedeSocialForm from "./formRedeSocial";
import TagForm from "./formTag";
import CustomFieldsForm from "./formCustomField";
import { PageBase } from "@/components/PageBase";
import { MakoMultiTabs } from "@/components/MakoMultiTabs";
import { ModalErrosValidacao } from "@/components/ModalErrosValidacao";
import useFormatCNPJCPF from "@/hooks/useFomatCNPJCPF";
import usePessoa from "@/hooks/usePessoa";
import useLoading from "@/hooks/useLoading";
import useToast from "@/hooks/useToast";
import { ConfirmDialog } from "primereact/confirmdialog";

export const TabPessoas = ({ tipoForm, perfilId, fecharModal, aposSalvar }) => {
    const [confirmDialog, setConfirmDialog] = useState(false);
    const modalErrosValidacaoRef = useRef(null);
    const history = useHistory();
    const {
        submit,
        loadingPessoa,
        handlePessoa,
        handlePreencherPessoa,
        pessoa,
        enderecos,
        telefones,
        emails,
        redesSociais,
        tags,
        mostrarConfirm,
    } = usePessoa();
    const { showLoading, hideLoading } = useLoading();
    const { showSuccess, showError } = useToast();
    const [formatarDocumento] = useFormatCNPJCPF();

    const TABS = [
        {
            header: "Identificação",
            icon: "pi pi-fw pi-id-card",
            visible: tipoForm === "Pessoa Física",
            component: <IdentificacaoPFForm />,
        },
        {
            header: "Identificação",
            icon: "pi pi-fw pi-id-card",
            visible: tipoForm === "Pessoa Jurídica",
            component: <IdentificacaoPJForm />,
        },
        {
            header: "Identificação",
            icon: "pi pi-fw pi-id-card",
            visible: tipoForm === "Pessoa Estrangeira",
            component: <IdentificacaoPEForm />,
        },
        {
            header: enderecos.length > 0 ? `Endereços (${enderecos.length})` : "Endereços",
            icon: "pi pi-fw pi-map-marker",
            disabled: !submit,
            component: <EnderecoForm />,
        },
        {
            header: telefones.length > 0 ? `Telefones (${telefones.length})` : "Telefones",
            icon: "pi pi-fw pi-phone",
            disabled: !submit,
            component: <TelefoneForm />,
        },
        {
            header: emails.length > 0 ? `E-mails (${emails.length})` : "E-mails",
            icon: "pi pi-fw pi-envelope",
            disabled: !submit,
            component: <EmailForm />,
        },
        {
            header: redesSociais.length > 0 ? `Redes Sociais (${redesSociais.length})` : "Redes Sociais",
            icon: "pi pi-fw pi-at",
            disabled: !submit,
            component: <RedeSocialForm />,
        },
        {
            header: tags.length ? `Tags (${tags.length})` : "Tags",
            icon: "pi pi-fw pi-tags",
            disabled: !submit,
            component: <TagForm />,
        },
        {
            header: "Campos personalizados",
            icon: "pi pi-fw pi-align-center",
            disabled: !submit,
            visible: tipoForm === "Pessoa Física" || tipoForm === "Pessoa Jurídica",
            component: <CustomFieldsForm />,
        },
    ];

    const voltarParaListagem = useCallback(() => {
        history.push("/gestao/pessoas/pessoas");
    }, [history]);

    const carregarPerfil = useCallback(async () => {
        if (perfilId) {
            await handlePreencherPessoa(perfilId);
        }
    }, [perfilId, handlePreencherPessoa]);

    useEffect(() => {
        carregarPerfil();
    }, [carregarPerfil]);

    const persistirDadosPessoa = async () => {
        showLoading();
        const json = await handlePessoa();
        hideLoading();

        if (json.status === 201) {
            showSuccess({
                summary: "Sucesso!",
                detail: "Perfil cadastrado com sucesso!",
                life: 1500,
            });

            if (typeof aposSalvar === "function") aposSalvar();
            else
                setTimeout(() => {
                    voltarParaListagem();
                }, 2000);
        } else if (json.status === 200) {
            showSuccess({
                summary: "Sucesso!",
                detail: "Perfil alterado com sucesso!",
                life: 1500,
            });

            if (typeof aposSalvar === "function") aposSalvar();
            else
                setTimeout(() => {
                    voltarParaListagem();
                }, 2000);
        } else if (json.status === 400) {
            modalErrosValidacaoRef.current?.abrirModal(json.data);
        } else {
            showError({
                summary: "Erro :(",
                detail: "A sua requisição não pode ser concluída.",
                life: 3000,
            });
        }
    };

    const fecharConfirm = useCallback(() => {
        setConfirmDialog(false);
    }, [setConfirmDialog]);

    const aceitarConfirm = useCallback(() => {
        fecharConfirm();
        voltarParaListagem();
    }, [fecharConfirm, voltarParaListagem]);

    const onClickCancelar = useCallback(() => {
        if (mostrarConfirm) setConfirmDialog(true);
        else {
            if (typeof fecharModal === "function") fecharModal();
            else voltarParaListagem();
        }
    }, [mostrarConfirm, setConfirmDialog, voltarParaListagem, fecharModal]);

    return (
        <PageBase blocked={loadingPessoa}>
            <MakoMultiTabs
                tabs={TABS}
                title={`Cadastro de ${tipoForm}: ${pessoa?.nome || ""} ${
                    pessoa?.identificacao ? `(${formatarDocumento(pessoa?.identificacao)})` : ""
                }`}
                propsButtonProx={{ disabled: !submit }}
                propsButtonFinalizar={{ disabled: !submit, onClick: persistirDadosPessoa }}
                confirmDialogAoFinalizar={!submit}
                propsConfirmDialog={{ accept: persistirDadosPessoa }}
                propsButtonCancelar={{ onClick: onClickCancelar }}
            />
            <ModalErrosValidacao ref={modalErrosValidacaoRef} />
            <ConfirmDialog
                visible={confirmDialog}
                onHide={fecharConfirm}
                header={"Confirmação"}
                message={
                    <span>
                        Deseja sair do formulário sem persistir?
                        <br />
                        <span className="p-error">
                            Houve dados editados, porém ainda não persistidos.
                            <br />
                            Para persistir todas as alterações, clique em finalizar.
                        </span>
                    </span>
                }
                icon="pi pi-info-circle p-mr-3"
                accept={aceitarConfirm}
                acceptLabel="Sim"
                acceptClassName="p-button-danger"
                reject={fecharConfirm}
                rejectLabel="Não"
            />
        </PageBase>
    );
};
