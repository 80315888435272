import React, { useMemo } from "react";

import { Dropdown } from "@/components/Dropdown";

export const DropdownEndereco = ({ perfilId, somenteAtivos = true, ...props }) => {
    const enderecoTemplate = (option) => {
        return (
            <div className="country-item">
                <div>{`${option.identificacao} - ${option.logradouro}, ${option.numero} - ${option.bairro} - ${option.cep}`}</div>
            </div>
        );
    };

    const enderecoSelecionadoTemplate = (option, props) => {
        if (option) return enderecoTemplate(option);
        return <span>{props.placeholder}</span>;
    };

    const url = useMemo(() => {
        const segmentos = [
            `/pessoas/enderecos-perfis/?perfil__id=${perfilId}&query={id,identificacao,logradouro,numero,bairro,cep}`,
        ];
        if (somenteAtivos) segmentos.push("ativo=true");
        return segmentos.join("&");
    }, [perfilId, somenteAtivos]);

    return (
        <Dropdown
            url={url}
            buscar={!!perfilId}
            optionValue="id"
            optionLabel="logradouro"
            valueTemplate={enderecoSelecionadoTemplate}
            itemTemplate={enderecoTemplate}
            {...props}
        />
    );
};
