import { FinanceiroBancoPage } from "@/pages/Financeiro/Cadastros/Bancos";
import { FinanceiroBancoForm } from "@/pages/Financeiro/Cadastros/Bancos/form";
import { FinanceiroCarteiraFinanceiraForm } from "@/pages/Financeiro/Cadastros/CarteiraContaFinanceira/form";
import { FinanceiroCarteiraFinanceiraPage } from "@/pages/Financeiro/Cadastros/CarteiraContaFinanceira";
import { FinanceiroContaFinanceiraForm } from "@/pages/Financeiro/Cadastros/ContaFinanceira/form";
import { FinanceiroContaFinanceiraPage } from "@/pages/Financeiro/Cadastros/ContaFinanceira";
import { FinanceiroFormaRecebimentoPage } from "@/pages/Financeiro/Cadastros/FormasRecebimentos";
import { FinanceiroFormaRecebimentoForm } from "@/pages/Financeiro/Cadastros/FormasRecebimentos/form";
import { FinanceiroCompetenciaPage } from "@/pages/Financeiro/Cadastros/Competencia";
import { FinanceiroCompetenciaForm } from "@/pages/Financeiro/Cadastros/Competencia/form";
import { FinanceiroContaBancariaPage } from "@/pages/Financeiro/Cadastros/ContaBancaria";
import { FinanceiroContaBancariaForm } from "@/pages/Financeiro/Cadastros/ContaBancaria/form/index";
import { FinanceiroTitularContaBancariaForm } from "@/pages/Financeiro/Cadastros/TitularesContasBancarias/form";
import { FinanceiroTitularContaBancariaPage } from "@/pages/Financeiro/Cadastros/TitularesContasBancarias";
import { FinanceiroChavePixPage } from "@/pages/Financeiro/Cadastros/ChavePix";
import { FinanceiroChavePixForm } from "@/pages/Financeiro/Cadastros/ChavePix/form";
import { TemplateImportacaoExtratoPage } from "@/pages/Financeiro/Cadastros/TemplateImportacaoExtrato";
import { TemplateImportacaoExtratoForm } from "@/pages/Financeiro/Cadastros/TemplateImportacaoExtrato/form";
import { MapeadorTemplateImportacaoExtratoPage } from "@/pages/Financeiro/Cadastros/MapeadorTemplateImportacaoExtrato";
import { MapeadorTemplateImportacaoExtratoForm } from "@/pages/Financeiro/Cadastros/MapeadorTemplateImportacaoExtrato/form";
import { FracionamentoRecebimentoPage } from "@/pages/Financeiro/Financeiro/FracionamentoRecebimento";
import { LotesBoletosPage } from "@/pages/Financeiro/Financeiro/Boletos/LotesBoletos";
import { LancamentoManualBoletosPage } from "@/pages/Financeiro/Financeiro/Boletos/LancamentoManual";
import { TabBoletosContext } from "@/pages/Financeiro/Financeiro/Boletos/LancamentoManual/form";
import { FinanceiroPlanoRecebimentoPage } from "@/pages/Financeiro/Financeiro/PlanoRecebimentos";
import { FinanceiroPlanoRecebimentoForm } from "@/pages/Financeiro/Financeiro/PlanoRecebimentos/form";
import { FinanceiroPlanoContasPage } from "@/pages/Financeiro/Financeiro/PlanoContas";
import { FinanceiroPlanoContasForm } from "@/pages/Financeiro/Financeiro/PlanoContas/form";
import { FinVersaoPlanoContasPage } from "@/pages/Financeiro/Financeiro/VersaoPlanoContas";
import { FinVersaoPlanoContasForm } from "@/pages/Financeiro/Financeiro/VersaoPlanoContas/form";
import { FinanceiroTemplateRateioPage } from "@/pages/Financeiro/Financeiro/TemplateRateio";
import { FinanceiroTemplateRateioForm } from "@/pages/Financeiro/Financeiro/TemplateRateio/form";
import { FinanceiroRecebimentosPage } from "@/pages/Financeiro/Financeiro/Recebimentos";
import { TabRecebimentosContext } from "@/pages/Financeiro/Financeiro/Recebimentos/form";
import { TabPagamentosContext } from "@/pages/Financeiro/Financeiro/Pagamentos/tabPagamentos";
import { FinanceiroPagamentosPage } from "@/pages/Financeiro/Financeiro/Pagamentos";
import { FinanceiroPlanosOrcamentariosPage } from "@/pages/Financeiro/Financeiro/PlanoOrcamentario";
import { FinanceiroPlanosOrcamentariosForm } from "@/pages/Financeiro/Financeiro/PlanoOrcamentario/form";
import { FinanceiroLancamentosPage } from "@/pages/Financeiro/Financeiro/Lancamentos";
import { GerenciarCaixa } from "@/pages/Financeiro/Financeiro/GestaoCaixa";
import { CaixaPage } from "@/pages/Financeiro/Cadastros/Caixa";
import { CaixaForm } from "@/pages/Financeiro/Cadastros/Caixa/form";
import { RelatorioAPagar } from "@/pages/Relatorios/Contas/RelatorioAPagar";
import { RelatorioAReceber } from "@/pages/Relatorios/Contas/RelatorioAReceber";
import { RelatorioCaixaMovResumo } from "@/pages/Relatorios/Financeiro/CaixaMovResumo";
import { RelatorioExtratoConta } from "@/pages/Relatorios/Financeiro/ExtraoConta";
import { RelatorioMovimentacaoContaFinanceira } from "@/pages/Relatorios/Financeiro/MovimentacaoContaFinanceira";
import { FinanceiroHistoricoPadraoForm } from "@/pages/Financeiro/Cadastros/HistoricoPadrao/form";
import { FinanceiroHistoricoPadraoPage } from "@/pages/Financeiro/Cadastros/HistoricoPadrao";
import { FracionamentoPagamentoPage } from "@/pages/Financeiro/Financeiro/Pagamentos/FracionamentoPagamento";
import { EfetivacaoPagamentoPage } from "@/pages/Financeiro/Financeiro/Pagamentos/EfetivacaoPagamento";
import { RelatorioContasRecebidas } from "@/pages/Relatorios/Contas/RelatorioContasRecebidas";
import { RelatorioContasPagas } from "@/pages/Relatorios/Contas/RelatorioContasPagas";
import { EfetivarRecebimentoPage } from "@/pages/Financeiro/Financeiro/EfetivarRecebimento";
import { AdministradoraCartaoPage } from "@/pages/Financeiro/Cadastros/AdministradoraCartao";
import { AdministradoraCartaoForm } from "@/pages/Financeiro/Cadastros/AdministradoraCartao/form";

export const financeiroRoutes = [
    { path: "/financeiro/cadastros/bancos", component: FinanceiroBancoPage },
    { path: "/financeiro/cadastros/bancos/form", component: FinanceiroBancoForm },
    { path: "/financeiro/cadastros/carteira-conta-financeira", component: FinanceiroCarteiraFinanceiraPage },
    { path: "/financeiro/cadastros/carteira-conta-financeira/form", component: FinanceiroCarteiraFinanceiraForm },
    { path: "/financeiro/cadastros/contas-financeiras", component: FinanceiroContaFinanceiraPage },
    { path: "/financeiro/cadastros/contas-financeiras/form", component: FinanceiroContaFinanceiraForm },
    { path: "/financeiro/cadastros/contas-bancarias", component: FinanceiroContaBancariaPage },
    { path: "/financeiro/cadastros/contas-bancarias/form", component: FinanceiroContaBancariaForm },
    { path: "/financeiro/cadastros/titulares-contas-bancarias", component: FinanceiroTitularContaBancariaPage },
    { path: "/financeiro/cadastros/titulares-contas-bancarias/form", component: FinanceiroTitularContaBancariaForm },
    { path: "/financeiro/cadastros/competencia", component: FinanceiroCompetenciaPage },
    { path: "/financeiro/cadastros/competencia/form", component: FinanceiroCompetenciaForm },
    { path: "/financeiro/cadastros/formas-recebimentos", component: FinanceiroFormaRecebimentoPage },
    { path: "/financeiro/cadastros/formas-recebimentos/form", component: FinanceiroFormaRecebimentoForm },
    { path: "/financeiro/financeiro/recebimentos/fracionar", component: FracionamentoRecebimentoPage },
    { path: "/financeiro/cadastros/chaves-pix", component: FinanceiroChavePixPage },
    { path: "/financeiro/cadastros/chaves-pix/form", component: FinanceiroChavePixForm },
    { path: "/financeiro/cadastros/historico-padrao", component: FinanceiroHistoricoPadraoPage },
    { path: "/financeiro/cadastros/historico-padrao/form", component: FinanceiroHistoricoPadraoForm },
    { path: "/financeiro/cadastros/templates-importacao-extratos", component: TemplateImportacaoExtratoPage },
    { path: "/financeiro/cadastros/templates-importacao-extratos/form", component: TemplateImportacaoExtratoForm },
    {
        path: "/financeiro/cadastros/mapeador-templates-importacao-extratos",
        component: MapeadorTemplateImportacaoExtratoPage,
    },
    {
        path: "/financeiro/cadastros/mapeador-templates-importacao-extratos/form",
        component: MapeadorTemplateImportacaoExtratoForm,
    },
    { path: "/financeiro/boletos/lotes", component: LotesBoletosPage },
    { path: "/financeiro/boletos/lancamento-manual", component: LancamentoManualBoletosPage },
    { path: "/financeiro/boletos/lancamento-manual/form", component: TabBoletosContext },
    { path: "/financeiro/lancamentos", component: FinanceiroLancamentosPage },
    { path: "/financeiro/plano-contas", component: FinanceiroPlanoContasPage },
    { path: "/financeiro/plano-contas/form", component: FinanceiroPlanoContasForm },
    { path: "/financeiro/plano-recebimentos", component: FinanceiroPlanoRecebimentoPage },
    { path: "/financeiro/plano-recebimentos/form", component: FinanceiroPlanoRecebimentoForm },
    { path: "/financeiro/financeiro/gerenciar-caixa", component: GerenciarCaixa },
    { path: "/financeiro/cadastros/caixas", component: CaixaPage },
    { path: "/financeiro/cadastros/caixas/form", component: CaixaForm },
    { path: "/financeiro/financeiro/pagamentos", component: FinanceiroPagamentosPage },
    { path: "/financeiro/financeiro/pagamentos/form", component: TabPagamentosContext },
    { path: "/financeiro/financeiro/pagamentos/fracionar", component: FracionamentoPagamentoPage },
    { path: "/financeiro/financeiro/pagamentos/efetivados/form", component: EfetivacaoPagamentoPage },
    { path: "/financeiro/financeiro/planos-orcamentarios", component: FinanceiroPlanosOrcamentariosPage },
    { path: "/financeiro/financeiro/planos-orcamentarios/form", component: FinanceiroPlanosOrcamentariosForm },
    { path: "/financeiro/financeiro/templates-rateios", component: FinanceiroTemplateRateioPage },
    { path: "/financeiro/financeiro/templates-rateios/form", component: FinanceiroTemplateRateioForm },
    { path: "/financeiro/financeiro/recebimentos", component: FinanceiroRecebimentosPage },
    { path: "/financeiro/financeiro/recebimentos/form", component: TabRecebimentosContext },
    { path: "/financeiro/financeiro/recebimentos/efetivados/form", component: EfetivarRecebimentoPage },
    { path: "/financeiro/versao-plano-contas", component: FinVersaoPlanoContasPage },
    { path: "/financeiro/versao-plano-contas/form", component: FinVersaoPlanoContasForm },
    { path: "/financeiro/relatorios/a-pagar", component: RelatorioAPagar },
    { path: "/financeiro/relatorios/a-receber", component: RelatorioAReceber },
    { path: "/financeiro/relatorios/resumo-movimentacao-caixa", component: RelatorioCaixaMovResumo },
    { path: "/financeiro/relatorios/extrato-conta", component: RelatorioExtratoConta },
    { path: "/financeiro/relatorios/movimentacao-conta-financeira", component: RelatorioMovimentacaoContaFinanceira },
    { path: "/financeiro/relatorios/contas-recebidas", component: RelatorioContasRecebidas },
    { path: "/financeiro/relatorios/contas-pagas", component: RelatorioContasPagas },
    { path: "/financeiro/cadastros/administradora-cartao", component: AdministradoraCartaoPage },
    { path: "/financeiro/cadastros/administradora-cartao/form", component: AdministradoraCartaoForm },
];
