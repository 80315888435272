import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { ConfirmDialog, confirmDialog } from "primereact/confirmdialog";
import { Tag } from "primereact/tag";

import { PageBase } from "@/components/PageBase";
import { MakoListagem } from "@/components/MakoListagem";
import { MakoButton as Button } from "@/components/MakoButton";
import { BooleanFiltroTemplate, TextoFiltroTemplate } from "@/components/MakoFiltrosCabecalho";
import { MakoActionsButtonsColumn } from "@/components/MakoActionsButtonsColumn";
import { Delete } from "@/components/Delete";
import { ButtonMenuPopup } from "@/components/ButtonMenuPopup";
import { parseNumber } from "@/assets/helpers/number";
import { MAKO_LICENCA_ADICIONAL } from "@/assets/constants/parametros";
import permissoes from "@/assets/constants/permissoes";
import { BUTTON_LISTAGEM_OPCOES } from "@/assets/constants/presets";
import useFormatCNPJCPF from "@/hooks/useFomatCNPJCPF";
import useClearRefs from "@/hooks/useClearRefs";
import useParam from "@/hooks/useParam";
import useToast from "@/hooks/useToast";
import useHttp from "@/hooks/useHttp";
import useAuth from "@/hooks/useAuth";

import { ModalGerenciamentoCertificadoDigital } from "./ModalGerenciamentoCertificadoDigital";
import { ModalVincularPessoas } from "./ModalVincularPessoas";

export const VinculacaoPessoasPage = () => {
    const [loading, setLoading] = useState(false);
    const [empresas, setEmpresas] = useState(0);
    const [vinculoSelecionado, setVinculoSelecionado] = useState(null);
    const modalPessoaSecundariaRef = useRef(null);
    const modalAceiteEnvioCertificadoRef = useRef(null);
    const listagemRef = useRef(null);
    const deleteRef = useRef(null);
    const [formatarDocumento] = useFormatCNPJCPF();
    const { httpPost, httpGet } = useHttp();
    const { showSuccess } = useToast();
    const { getParam } = useParam();
    const { verifyPermission } = useAuth();

    useClearRefs(modalPessoaSecundariaRef, listagemRef, deleteRef);

    const licencasDisponiveis = useMemo(() => {
        const param = getParam(MAKO_LICENCA_ADICIONAL);
        if (param) return parseNumber(param.valor) - empresas;
        return 0;
    }, [getParam, empresas]);

    const buscarPessoasVinculadas = useCallback(
        async (signal) => {
            const handlers = {
                200: ({ data }) => {
                    setEmpresas(data?.count);
                },
            };
            setLoading(true);
            await httpGet({ url: "/pessoas/grupos-perfis/", signal }, handlers);
            setLoading(false);
        },
        [httpGet]
    );

    const alterarEmpresaPrincipal = async (empresaId) => {
        const handlers = {
            200: () => {
                showSuccess({
                    summary: "Sucesso!",
                    detail: "Empresa principal alterada com sucesso.",
                    life: 1500,
                });
                listagemRef.current?.buscarDados();
            },
        };
        setLoading(true);
        await httpPost({ url: `/pessoas/alterar-empresa-principal/${empresaId}/` }, handlers);
        setLoading(false);
    };

    const confirmarAlteracaoEmpresaPrincipal = (novaEmpresaPrincipal) => {
        confirmDialog({
            message: (
                <span>
                    Deseja tornar a empresa <b>{novaEmpresaPrincipal.nome}</b> a principal do sistema?
                </span>
            ),
            header: "Confirmação",
            icon: "pi pi-exclamation-triangle",
            accept: () => alterarEmpresaPrincipal(novaEmpresaPrincipal.id),
            acceptLabel: "Confirmar",
            rejectLabel: "Cancelar",
        });
    };

    const identificacaoBodyTemplate = (rowData) => {
        return <span>{formatarDocumento(rowData.perfil.identificacao)}</span>;
    };

    const hierarquiaBodyTemplate = (rowData) => {
        if (rowData.principal) return <Tag severity="success" value="PRINCIPAL" />;
        return <Tag style={{ background: "#64748B" }} value="ADICIONAL" />;
    };

    const itens = [
        {
            icon: "pi pi-trash",
            label: "Excluir",
            disabled: vinculoSelecionado?.principal || !verifyPermission(permissoes.GESTAO_EMPRESAS_VINCULACAO_EXCLUIR),
            command: () => deleteRef.current?.handleClick(),
        },
        {
            icon: "pi pi-id-card",
            label: "Certificado",
            disabled: !vinculoSelecionado || !verifyPermission(permissoes.GESTAO_EMPRESAS_GERENCIAR_CERTIFICADO),
            command: () => modalAceiteEnvioCertificadoRef.current?.abrirModal(),
        },
        {
            icon: "pi pi-eye",
            label: "Ver certificado",
            disabled: !vinculoSelecionado || !verifyPermission(permissoes.GESTAO_EMPRESAS_GERENCIAR_CERTIFICADO),
            command: () => modalAceiteEnvioCertificadoRef.current?.abrirModal(true),
        },
    ];

    const actionBodyTemplate = (rowData) => {
        return (
            <MakoActionsButtonsColumn>
                <Button
                    icon="pi pi-star"
                    disabled={rowData.principal}
                    tooltip="Tornar principal"
                    onClick={() => confirmarAlteracaoEmpresaPrincipal(rowData)}
                    className="p-button-rounded p-button-help"
                />
                <ButtonMenuPopup
                    {...BUTTON_LISTAGEM_OPCOES}
                    menu={{ model: itens }}
                    onClick={() => setVinculoSelecionado(rowData)}
                />
            </MakoActionsButtonsColumn>
        );
    };

    const colunas = [
        {
            field: "perfil.identificacao",
            header: "CPF / CNPJ",
            style: { width: "25%" },
            action: (e) => identificacaoBodyTemplate(e),
            filter: true,
            filterElemente: TextoFiltroTemplate,
            filterField: "perfil__identificacao",
        },
        {
            field: "perfil.nome",
            header: "Nome / Razão Social",
            filter: true,
            filterElemente: TextoFiltroTemplate,
            filterField: "perfil__perfil_pj__razao_social",
        },
        {
            field: "principal",
            header: "Participação",
            action: (e) => hierarquiaBodyTemplate(e),
            filter: true,
            filterElement: BooleanFiltroTemplate,
        },
        { field: "action", header: "Ações", style: { width: "8%" }, action: (e) => actionBodyTemplate(e) },
    ];

    const painelEsquerdoTabela = (
        <>
            {licencasDisponiveis >= 1 ? (
                <Button
                    icon="pi pi-plus"
                    label={`Vincular pessoas (${licencasDisponiveis} ${
                        licencasDisponiveis === 1 ? "licença disponível" : "licenças disponíveis"
                    })`}
                    disabled={licencasDisponiveis <= 0 || loading}
                    onClick={() => modalPessoaSecundariaRef.current?.abrirModal()}
                    className="p-button-success"
                />
            ) : (
                <span className="p-error">Número máximo de licenças atingido ou não configurado.</span>
            )}
        </>
    );

    useEffect(() => {
        const abortController = new AbortController();
        buscarPessoasVinculadas(abortController.signal);
        return () => {
            abortController.abort();
        };
    }, [buscarPessoasVinculadas]);

    return (
        <PageBase>
            <MakoListagem
                ref={listagemRef}
                titulo="Gerenciamento de entidades gerenciadas pelo SysMako"
                colunas={colunas}
                painelEsquerdo={painelEsquerdoTabela}
                urlPesquisa={"/pessoas/grupos-perfis/"}
                configTabela={{
                    paginator: true,
                    lazy: true,
                }}
                filtros={{
                    perfil__identificacao: {
                        operator: "and",
                        constraints: [{ value: "", matchMode: "unaccent_icontains" }],
                    },
                    perfil__perfil_pj__razao_social: {
                        operator: "and",
                        constraints: [{ value: "", matchMode: "unaccent_icontains" }],
                    },
                    principal: { value: null, matchMode: "equals" },
                }}
            />
            <ModalVincularPessoas
                ref={modalPessoaSecundariaRef}
                principal={empresas.length === 0}
                onGravar={() => listagemRef.current?.buscarDados()}
            />
            <ModalGerenciamentoCertificadoDigital
                ref={modalAceiteEnvioCertificadoRef}
                perfil={{
                    id: vinculoSelecionado?.perfil.id,
                    identificacao: vinculoSelecionado?.perfil.identificacao,
                    nome_razao_social:
                        vinculoSelecionado?.perfil.perfil_pf?.nome_completo ||
                        vinculoSelecionado?.perfil.perfil_pj?.razao_social,
                    inscricao_estadual: vinculoSelecionado?.perfil.perfil_pj?.inscricao_estadual || "",
                }}
            />
            <Delete
                ref={deleteRef}
                url="/pessoas/grupos-perfis/"
                objetoId={vinculoSelecionado?.id}
                exigeConfirmacao
                msgConfirmacao={
                    <span>
                        Deseja realmente deletar o vínculo da empresa <b>{vinculoSelecionado?.perfil.nome}</b>? (Essa
                        ação pode impactar em algumas áreas do sistema)
                    </span>
                }
                msgToastErroExclusao="O vínculo de empresa com o sistema não pode ser removido."
                onDelete={() => listagemRef.current?.buscarDados()}
            />
            <ConfirmDialog />
        </PageBase>
    );
};
