import React, { useMemo } from "react";

import { Dropdown } from "@/components/Dropdown";
import { gerarClausulaTemplate, gerarFiltrosUrl } from "@/assets/util/util";

const BASE_URL = "/servicos/clausulas-contrato";

const BASE_FILTROS = {
    limit: 200,
};

export const MakoDropdownClausulaContrato = ({ filtros = {}, ...props }) => {
    const url = useMemo(() => {
        return `${BASE_URL}?${gerarFiltrosUrl({ ...BASE_FILTROS, ...filtros })}`;
    }, [filtros]);

    return (
        <Dropdown
            url={url}
            placeholder="Selecione uma cláusula"
            optionValue="id"
            optionLabel="nome"
            filter
            filterBy="nome,texto_clausula"
            itemTemplate={gerarClausulaTemplate}
            {...props}
        />
    );
};
